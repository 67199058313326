import React from 'react';
import { useIsMobileView } from '../../components/MobileView';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import { AdvisorSettingsView } from './Advisor/AdvisorSettingsView';
import { CustomerSettingsView } from './Customer/CustomerSettingsView';

export const Settings = () => {
  const { user } = useGetUserInfo();
  const isAdvisor = user?.permissions.includes('APP_ADVISOR');
  const isCustomer = user?.permissions.includes('APP_CUSTOMER');
  const isMobile = useIsMobileView();

  // Advisor View
  if (user && isAdvisor) {
    return <AdvisorSettingsView user={user} />;
  }

  // Customer View
  if (user && isCustomer) {
    return <CustomerSettingsView user={user} />;
  }

  return (
    <div
      style={{
        padding: isMobile ? '0 16px 16px 16px' : '12px',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        height: '100%',
      }}
    >
      Error Page
    </div>
  );
};
