import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { Modal } from '../../components/Dialog';
import { FormTextInputFilled } from '../../components/FormTextInput';
import { useIsMobileView } from '../../components/MobileView';
import { Typography } from '../../components/Typography';
import { PageContentBlock, Section, SectionHeader } from '../Customer/PageStyleComponents';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { ApiService, CustomError } from '../../services/api';
import { GroupButton } from '../../components/GroupButton';
import { ResetPasswordRequest } from '../../types/auth';

const changePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'must be at least 8 characters')
    .notOneOf([Yup.ref('currentPassword'), null], 'same as the old one')
    .matches(/^(?=.*[a-z])/, 'must contain one lowercase')
    .matches(/^(?=.*[A-Z])/, 'must contain one uppercase')
    .matches(/^(?=.*[0-9])/, 'must contain one number')
    // eslint-disable-next-line no-useless-escape
    .required('required field'),
  currentPassword: Yup.string().min(8, 'must be at least 8 characters').required('required field'),
  rnewPassword: Yup.string()
    .min(8, 'must be at least 8 characters')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('required field'),
});

export const ResetPassword = () => {
  const isMobile = useIsMobileView();
  const [openModal, setOpenModal] = useState(false);
  const { mutate, isLoading } = useMutation<any, CustomError, { data: ResetPasswordRequest }>(
    'update-pass',
    ApiService.patch('/v1/user/me/change-password'),
    {
      onSuccess: () => setOpenModal(false),
    }
  );

  const handleSubmit = ({ currentPassword, newPassword }: any) => {
    if (currentPassword === newPassword) {
      setOpenModal(false);
    }
    mutate({ data: { newPassword, oldPassword: currentPassword } });
  };
  return (
    <>
      <PageContentBlock fullWidth bRadius style={isMobile ? { marginTop: '16px' } : { margin: '12px' }}>
        <SectionHeader>
          <Typography type="b2Bold" color="darkerBlue">
            Account Settings
          </Typography>
        </SectionHeader>
        <Section>
          <Typography type="b3Bold" color="darkerBlue">
            Your Password
          </Typography>
          <Typography type="b3Normal" color="darkerBlue">
            ******
          </Typography>
        </Section>
        <Section row={!isMobile}>
          <Button onClick={() => setOpenModal(true)}>Edit</Button>
        </Section>
      </PageContentBlock>
      <Modal open={openModal} closeX title="Change your password" handleClose={() => setOpenModal(false)}>
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            rnewPassword: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={changePasswordSchema}
        >
          <Form style={{ display: 'flex', flexDirection: 'column', padding: '24px', overflow: 'hidden' }}>
            <FormTextInputFilled
              name="currentPassword"
              label="Current Password"
              style={{ marginTop: '12px' }}
              type="password"
            />
            <FormTextInputFilled
              name="newPassword"
              label="New Password"
              style={{ marginTop: '12px' }}
              type="password"
            />
            <FormTextInputFilled
              name="rnewPassword"
              label="Repeat New Password"
              style={{ marginTop: '12px' }}
              type="password"
            />
            <Section>
              <GroupButton
                primaryLabel="Confirm"
                loading={isLoading}
                onSecondaryClick={() => setOpenModal(false)}
                secondaryLabel="Cancel"
                primaryType="submit"
                secondaryType="button"
              />
            </Section>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};
