import { Button } from '@material-ui/core';
import React from 'react';
import { AuthenticationRouteLinks } from '../../../features/authentication/navigation/authentication.routes';
import { NavigationService } from '../../../services';


const CongratsStep = () => {

  return (
    <div className="congrats-container">
      <h1 className="title" style={{ color: "#fff" }}>Congratulations!</h1>
      <p style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto", color: "#fff", width: "50%" }}>You've successfully registered for free personal advice with an IncomeMax adviser. We are now sending you an email to confirm your email address. Please check your email and follow the instructions to login.</p>
    </div>
  )
}

export default CongratsStep;
