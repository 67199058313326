const typographyMain = `
  font-family: Raleway !important;
  font-style: normal !important;`;

export const Theme = Object.freeze({
  colors: {
    darkerBlue: '#001E3C',
    darkBlue: '#00446D',
    blue: '#2E8FCE',
    teal: '#78C2B5',
    yellow: '#FAB014',
    darkPink: '#EA5168',
    orange: '#EB6129',
    black: '#000000',
    grey1: '#4F4F4F',
    grey2: '#BDBDBD',
    grey3: '#F2F2F2',
    grey5: '#E0E0E0',
    white: '#FFFFFF',
    fadedBlue: 'rgba(189, 218, 237, 0.3)',
    inputNormal: 'rgba(0, 0, 0, 0.04)',
    inputFocus: 'rgba(0, 0, 0, 0.1)',
  },
  typography: {
    large: {
      h1: `${typographyMain}
      font-weight: bold !important;
      font-size: 60px !important;`,
      h2: `${typographyMain}
      font-weight: bold !important;
      font-size: 40px !important;`,
      h3: `${typographyMain}
      font-weight: bold !important;
      font-size: 30px !important;`,
      b1Bold: `${typographyMain}
      font-weight: bold !important;
      font-size: 24px !important;
      line-height: 30px !important;`,
      b1Normal: `${typographyMain}
      font-weight: normal !important;
      font-size: 24px !important;
      line-height: 30px !important;`,
      b2Bold: `${typographyMain}
      font-weight: bold !important;
      font-size: 20px !important;
      line-height: 25px !important;`,
      b2Normal: `${typographyMain}
      font-weight: normal !important;
      font-size: 20px !important;
      line-height: 25px !important;`,
      b3Bold: `${typographyMain}
      font-weight: bold !important;
      font-size: 16px !important;
      line-height: 20px !important;`,
      b3Normal: `${typographyMain}
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 20px !important;`,
      smallBold: `${typographyMain}
      font-weight: bold !important;
      font-size: 12px !important;
      line-height: 18px !important;`,
      smallNormal: `${typographyMain}
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 18px !important;`,
      small2Bold: `${typographyMain}
      font-weight: bold !important;
      font-size: 10px !important;
      line-height: 10px !important;`,
      small2Normal: `${typographyMain}
      font-weight: normal !important;
      font-size: 10px !important;
      line-height: 10px !important;`,
    },
    small: {
      h1: `${typographyMain}
      font-weight: bold !important;
      font-size: 48px !important;`,
      h2: `${typographyMain}
      font-weight: bold !important;
      font-size: 30px !important;`,
      h3: `${typographyMain}
      font-weight: bold !important;
      font-size: 22px !important;`,
      b1Bold: `${typographyMain}
      font-weight: bold !important;
      font-size: 20px !important;
      line-height: 25px !important;`,
      b1Normal: `${typographyMain}
      font-weight: normal !important;
      font-size: 20px !important;
      line-height: 25px !important;`,
      b2Bold: `${typographyMain}
      font-weight: bold !important;
      font-size: 18px !important;
      line-height: 22px !important;`,
      b2Normal: `${typographyMain}
      font-weight: normal !important;
      font-size: 18px !important;
      line-height: 22px !important;`,
      b3Bold: `${typographyMain}
      font-weight: bold !important;
      font-size: 16px !important;
      line-height: 20px !important;`,
      b3Normal: `${typographyMain}
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 20px !important;`,
      smallBold: `${typographyMain}
      font-weight: bold !important;
      font-size: 12px !important;
      line-height: 18px !important;`,
      smallNormal: `${typographyMain}
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 18px !important;`,
      small2Bold: `${typographyMain}
      font-weight: bold !important;
      font-size: 10px !important;
      line-height: 10px !important;`,
      small2Normal: `${typographyMain}
      font-weight: normal !important;
      font-size: 10px !important;
      line-height: 10px !important;`,
    },
  },
});

export type ThemeTypographyKeys = keyof typeof Theme.typography.large;
export type ColorKeys = keyof typeof Theme.colors;
