import React, { useState } from 'react';
import { Loading } from '../../components/Loading';
import { PaperFlex } from '../../components/PaperFlex';
import { PageContainer } from '../../components/PageContainer';
import { PageHeader } from '../../components/Table/PageHeader';
import type { CustomerQueryParams, CustomerList } from '../../types/customer';
import { Header } from '../Customers/CustomersTable';
import { AdvisorsTable } from './AdvisorsTable';
import { SelectDropdown } from '../../components/Dropdown';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { usePaginationQuery } from '../../components/Table/usePaginationQuery';
import { ChevronDown } from '../../assets/icons/ChevronDown';
import { TextButton } from '../../components/Button';
import { UserList } from '../../types/user';
import { Modal } from '../../components/Dialog';
import { Form, Formik } from 'formik';
import { FormTextInputFilled } from '../../components/FormTextInput';
import { Section } from '../Customer/PageStyleComponents';
import { GroupButton } from '../../components/GroupButton';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { ApiService } from '../../services/api';

const newAdvisorSchema = Yup.object().shape({
  firstName: Yup.string().required('required field'),
  lastName: Yup.string().required('required field'),
  email: Yup.string()
    .email()
    .required('required field')
    .test('test-incomemax-email', "Only supports 'incomemax.org.uk' email", (value) => {
      return value && (value.includes('incomemax.org.uk') as any);
    }),
});

const NewAdvisorModal = ({ open, closeModal, refetchAdvisors }) => {
  const { mutate, isLoading } = useMutation<any, any, any>('create-advisor', ApiService.post('/v1/advisor'), {
    onSuccess: () => {
      closeModal();
      refetchAdvisors();
    },
  });

  const handleSubmit = (values) => {
    mutate({ data: values });
  };

  return (
    <Modal open={open} closeX title="Create New Advisor" handleClose={closeModal}>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          rnewPassword: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={newAdvisorSchema}
      >
        <Form style={{ display: 'flex', flexDirection: 'column', padding: '24px', overflow: 'hidden' }}>
          <FormTextInputFilled name="firstName" label="First Name" style={{ marginTop: '12px' }} type="text" />
          <FormTextInputFilled name="lastName" label="Last Name" style={{ marginTop: '12px' }} type="text" />
          <FormTextInputFilled name="email" label="Email" style={{ marginTop: '12px' }} type="email" />
          <Section>
            <GroupButton
              primaryLabel="Create"
              loading={isLoading}
              onSecondaryClick={closeModal}
              secondaryLabel="Cancel"
              primaryType="submit"
              secondaryType="button"
            />
          </Section>
        </Form>
      </Formik>
    </Modal>
  );
};

const header: Header[] = [
  { label: '#' },
  { label: 'NAME' },
  { label: 'EMAIL' },
  { label: 'ACCOUNT CREATED' },
  { label: 'CUSTOMERS' },
  { label: 'ROLE' },
  { label: 'ACTION' },
];

export const AdvisorsPage = () => {
  const {
    activePage,
    rowsPerPage,
    data,
    handleChangePage,
    handleChangeRowsPerPage,
    isLoading: isLoadingAccounts,
    isFetching,
    forceRefetch,
    error,
  } = usePaginationQuery<UserList, CustomerQueryParams>({
    path: '/v1/advisor',
    cacheKey: 'advisor-list-page',
    holdOldDataOnForceRefetch: true,
  });

  const [openModal, setOpenModal] = useState(false);

  const renderTable = () => {
    if (error || !data) {
      return <div style={{ flex: 1 }}>Error!!!</div>;
    }
    return (
      <AdvisorsTable
        header={header}
        data={data}
        isFetching={isFetching || isLoadingAccounts}
        rowsPerPage={rowsPerPage}
        page={activePage + 1}
        handlePageChange={handleChangePage}
      />
    );
  };

  return (
    <PageContainer alignCenter column>
      <div style={{ border: `24px solid ${Theme.colors.grey3}`, width: '1260px', margin: '0 auto' }}>
        <PaperFlex column alignCenter>
          <PageHeader title="Advisors">
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '12px' }}>
              <TextButton color="darkBlue" type="smallBold" onClick={() => setOpenModal(true)}>
                Add new advisor
              </TextButton>
              <div style={{ margin: '0 24px 0 0' }} />
              <Typography type="smallNormal" color="darkerBlue" style={{ padding: '5px 5px' }}>
                Users per page:
              </Typography>
              <SelectDropdown
                color="darkerBlue"
                type="smallBold"
                value={rowsPerPage}
                items={[10, 15, 20, 25]}
                onChange={handleChangeRowsPerPage}
                IconComponent={ChevronDown}
              />
            </div>
          </PageHeader>

          {isLoadingAccounts ? (
            <div style={{ display: 'flex', width: '100%', height: '565px' }}>
              {/* TODO: fix style for spinner */}
              <Loading />
            </div>
          ) : (
            <>{renderTable()}</>
          )}
        </PaperFlex>
      </div>
      <NewAdvisorModal open={openModal} closeModal={() => setOpenModal(false)} refetchAdvisors={forceRefetch} />
    </PageContainer>
  );
};
