import React, { FunctionComponent } from 'react';
import { User } from '../../../types/user';
import { PageContentBlockMargin, SettingsPageWrapper } from '../../Customer/PageStyleComponents';
import { ResetPassword } from '../ResetPassword';
import { AdvisorAccountSettings } from './AdvisorAccountSettings';
import { ReallocateCustomers } from './ReallocateCustomers';

interface AdvisorSettingsViewProps {
  user: User;
}

export const AdvisorSettingsView: FunctionComponent<AdvisorSettingsViewProps> = ({ user }) => {
  return (
    <SettingsPageWrapper>
      <PageContentBlockMargin>
        <AdvisorAccountSettings user={user} />
      </PageContentBlockMargin>
      <PageContentBlockMargin>
        <ResetPassword />
        <ReallocateCustomers user={user} />
      </PageContentBlockMargin>
    </SettingsPageWrapper>
  );
};
