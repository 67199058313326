import { useEffect, useState } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { ApiService, CustomError } from '../services/api';
interface UsePaginationQueryProps<TVariables extends object> extends UseQueryOptions {
  path: string;
  cacheKey: any[];
  queryParams?: TVariables;
  holdOldDataOnForceRefetch?: boolean;
  useLastItemUidAsOffset?: boolean;
  limit?: number;
  reverseData?: boolean;
}

export const useCustomQuery = <TData extends any, TVariables extends object>({
  path,
  cacheKey,
  queryParams,
  holdOldDataOnForceRefetch,
  refetchOnMount,
  enabled,
  onSuccess,
}: UsePaginationQueryProps<TVariables>) => {
  const [holdOldData, setHoldOldData] = useState<TData>();

  const { isLoading, error, data, isFetching, remove, refetch } = useQuery<TData, CustomError>(
    cacheKey,
    () => ApiService.get(path, queryParams)(),
    {
      refetchOnMount,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (holdOldDataOnForceRefetch && data) {
          setHoldOldData(data);
        }
        if (onSuccess) {
          onSuccess(data);
        }
      },
      enabled,
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => remove, []); // clean the data on unmount

  const forceRefetch = () => {
    remove();
    refetch();
  };

  const loading = holdOldDataOnForceRefetch && holdOldData ? false : isLoading;
  const fetching = holdOldDataOnForceRefetch ? isLoading || isFetching : isFetching;
  const returnData = holdOldDataOnForceRefetch ? holdOldData : data;

  return {
    isLoading: loading,
    error,
    data: returnData,
    isFetching: fetching,
    forceRefetch,
    refetch,
  };
};
