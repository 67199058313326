import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { MenuItem } from '@material-ui/core';
import './onboardingComponents.style.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';

interface Props {
  step: number;
  onAction: any;
  setFormData: any;
  formData: any;
  handleChange: any;
  day: any;
  month: any;
  year: any;
  disabled: any;
}

// date values for dropdown
const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const currYear = new Date().getFullYear();
const years: Array<number> = [];
for (let i = 0; i <= 120; i++) {
  const year: number = currYear - i;
  years.push(year);
}

//yup validation
const validationSchema = yup.object({
  firstname: yup
    .string()
    .required('First Name is required')
    .max(20)
    .matches(/^[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/),
  lastname: yup
    .string()
    .required('Last Name is required')
    .max(20)
    .matches(/^[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/),
  //@todo fix the selection validation
  // month: yup.array().required("Month is required"),
  // year: yup.array().required("Year is required"),
});

const OnboardingStep1: React.FC<Props> = ({
  step,
  onAction,
  formData,
  setFormData,
  day,
  month,
  year,
  disabled,
  handleChange,
}) => {
  const DaySelect = () => {
    return (
      <FormControl variant="filled" className="select">
        <InputLabel>Day</InputLabel>

        <Select
          required={true}
          name="day"
          id="filled-basic"
          value={day}
          onChange={handleChange}
          label="Day"
          variant="filled"
        >
          {days.map((i, index) => (
            <MenuItem key={index} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const MonthSelect = () => {
    return (
      <FormControl variant="filled" className="select">
        <InputLabel>Month</InputLabel>
        <Select name="month" id="filled-basic" value={month} onChange={handleChange} label="Month" variant="filled">
          {months.map((i, index) => (
            <MenuItem key={index} value={i ? i : 'some'}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const YearSelect = () => {
    return (
      <FormControl variant="filled" className="select">
        <InputLabel>Year</InputLabel>
        <Select id="filled-basic" name="year" value={year} label="Year" onChange={handleChange} variant="filled">
          {years.map((i, index) => (
            <MenuItem key={index} value={i ? i : 'some'}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  if (step !== 1) {
    return null;
  }

  return (
    <Container>
      <Container><p align="center">This is the registration process. At the end of the process you'll be asked to confirm you have read our privacy notice which you can find <a target="_blank" href="https://incomemax.org.uk/privacy-policy" style={{color: "#00446D", textDecoration:"underline"}}>here</a> and our terms and conditions which can be found <a target="_blank" href="https://incomemax.org.uk/terms-and-conditions/" style={{color: "#00446D", textDecoration:"underline"}}>here</a></p>
</Container>
      <h1 className="title">What's your name & date of birth?</h1>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          onAction();
          setFormData(values);
        }}
        validationSchema={validationSchema}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="name">
              <Field
                autoComplete="off"
                name="firstname"
                label="First Name"
                id="1"
                variant="filled"
                as={TextField}
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
                error={touched.firstname && errors.firstname}
                helperText={touched.firstname && errors.firstname}
              />
              <Field
                id="2"
                label="Last Name"
                name="lastname"
                variant="filled"
                as={TextField}
                inputProps={{
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
                error={touched.lastname && errors.lastname}
                helperText={touched.lastname && errors.lastname}
              />
            </div>

            <div className="dob">
              <Field
                id="3"
                required={true}
                name="day"
                label="day"
                as={DaySelect}
                error={touched.day && errors.day}
                helperText={touched.day && errors.day}
              />

              <Field
                id="3"
                name="month"
                as={MonthSelect}
                error={touched.month && errors.month}
                helperText={touched.month && errors.month}
              />
              <Field
                id="3"
                as={YearSelect}
                error={touched.year && errors.year}
                helperText={touched.year && errors.year}
              />
            </div>
            <div className="button">
              <Button variant="contained" color="primary" type="submit" disabled={disabled}>
                Next step
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default OnboardingStep1;
