import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MUISelect, SelectProps } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Theme } from '../constants/theme';
import { useIsMobileView } from './MobileView';
import { TypographyProps } from './Typography';

const Label = styled(InputLabel)<{ useCustomStyle?: boolean }>`
  ${({ useCustomStyle }) =>
    useCustomStyle
      ? `color: ${Theme.colors.grey1} !important;
  ${Theme.typography.large.b3Normal}`
      : ``}
`;

const Select = styled(
  ({
    color,
    type,
    size,
    useCustomStyle,
    ...rest
  }: TypographyProps & Omit<SelectProps, 'color'> & { useCustomStyle?: boolean }) => <MUISelect {...rest} />
)`
  ${({ size, type, color }) => {
    const isMobile = useIsMobileView();
    size = size || isMobile ? 'small' : 'large';
    type = type || 'smallNormal';
    const colorR = color || 'darkBlue';

    const colorCss = `color: ${Theme.colors[colorR]};`;
    const typographyCss = Theme.typography[size][type];
    return `${colorCss}${typographyCss}`;
  }}

  ${({ IconComponent }) =>
    IconComponent
      ? ` & > svg {
    font-size: 24px;
    position: absolute;
    right: 0;
    color: inherit;
  }`
      : ''}


  ${({ useCustomStyle }) =>
    useCustomStyle
      ? `
      & > input {
        color: ${Theme.colors.grey1};
        ${Theme.typography.large.b3Normal}
      }
    
      &.MuiFormLabel-root.Mui-focused {
        color: ${Theme.colors.grey1};
      }
    
      &.MuiFilledInput-underline:after {
        border-bottom: 2px solid ${Theme.colors.grey1};
      }
     `
      : ` 
      display: flex;
      align-items: center;
      padding: 0;
      &.MuiInput-underline.Mui-focused:after {
        transform: none;
      }
      &.MuiInput-underline:after {
        content: none;
        background: transparent;
      }
      &.MuiInput-underline:before {
        content: none;
      }
      &::before {
        content: none;
      }
      & > .MuiSelect-select:focus {
        background-color: transparent;
      }
      & > .MuiInputBase-input {
        padding: 0 24px 0 0;
      }`}}
 
`;

export interface SelectDropdownProps<T extends object | string | number> extends TypographyProps {
  label?: string;
  name?: string;
  filled?: boolean;
  value: string | number;
  itemValue?: (item: T) => string | number;
  onChange: SelectProps['onChange'];
  items: T[];
  renderValue?: (items: T[], value: string | number) => ReactNode;
  renderItemValue?: (item: T) => ReactNode;
  getKey?: (item: T) => string | number;
  error?: string;
  IconComponent?: React.ElementType;
  useCustomStyle?: boolean;
}

export const SelectDropdown = <T extends object | string | number>({
  label,
  value,
  filled,
  onChange,
  renderValue,
  renderItemValue,
  items,
  itemValue,
  IconComponent,
  error,
  getKey,
  useCustomStyle,
  ...style
}: SelectDropdownProps<T>) => {
  return (
    <FormControl error={!!error} variant={filled ? 'filled' : undefined}>
      {label && (
        <Label id="select-with-error-label" useCustomStyle={useCustomStyle}>
          {label}
        </Label>
      )}
      <Select
        {...style}
        native={false}
        error={!!error}
        labelId="select-with-error-label"
        id="select-error"
        value={value}
        label={label}
        onChange={onChange}
        useCustomStyle={useCustomStyle}
        renderValue={(value) =>
          renderValue ? renderValue(items, value as string | number) : (value as string | number)
        }
        IconComponent={IconComponent}
      >
        {items.map((item, i) => (
          <MenuItem key={getKey ? getKey(item) : i} value={itemValue ? itemValue(item) : (item as string | number)}>
            {renderItemValue ? renderItemValue(item) : (item as string | number)}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
