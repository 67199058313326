import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { ArrowForward } from '../../assets/icons/ArrowForward';
import { TertiaryButton } from '../../components/Button';
import { Modal } from '../../components/Dialog';
import { GroupButton } from '../../components/GroupButton';
import { useIsMobileView } from '../../components/MobileView';
import { Typography } from '../../components/Typography';
import { ColorKeys } from '../../constants/theme';
import { logoutHandler } from '../../features/main/mainPage/mainPage.component';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import { ApiService, CustomError } from '../../services/api';
import { PageContentBlock, Section } from '../Customer/PageStyleComponents';

const ConfirmModal = ({ close, open, setModalMessage }) => {
  const { user } = useGetUserInfo();

  const { mutate, isLoading } = useMutation<any, CustomError, { path: string }>('delete-user', ApiService.delete(), {
    onSuccess: () => {
      logoutHandler();
    },
    onError: () => {
      setModalMessage({
        title: 'Oops! Something went wrong!',
        color: 'darkPink',
      });
      setTimeout(() => setModalMessage(null), 1000);
    },
  });

  const handleClick = () => {
    mutate({ path: `/v1/user/${user.uid}` });
  };

  const title = 'Are you sure you want to deactivate your account?';

  return (
    <Modal open={open} handleClose={close} closeX title={title} style={{ maxWidth: '400px' }}>
      <div style={{ width: 'calc(100% - 48px)', margin: '24px' }}>
        <GroupButton
          primaryLabel="Yes"
          onPrimaryClick={handleClick}
          loading={isLoading}
          onSecondaryClick={close}
          secondaryLabel="Cancel"
        />
      </div>
    </Modal>
  );
};

export const Deactivate = () => {
  const isMobile = useIsMobileView();
  const [modalMessage, setModalMessage] = useState<{ title: string; color?: ColorKeys } | null>(null);
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <PageContentBlock
        fullWidth
        bRadius
        style={isMobile ? { marginTop: '16px', marginBottom: '16px' } : { margin: '24px 12px 12px' }}
      >
        <Section>
          <Typography type="b3Bold" color="darkerBlue">
            Deactivate account
          </Typography>
          <Typography type="b3Normal" color="darkerBlue" style={{ marginTop: '6px' }}>
            If you deactivate your account you won’t be able to access your messages. We will not contact you after
            deactivating.
          </Typography>
        </Section>
        <Section row={!isMobile}>
          <TertiaryButton
            color="darkBlue"
            type="b3Bold"
            onClick={() => setOpenModal(true)}
            iconAfter={<ArrowForward style={{ marginLeft: '10px', fontSize: 16 }} />}
          >
            Deactivate
          </TertiaryButton>
        </Section>
      </PageContentBlock>
      <ConfirmModal open={openModal} close={() => setOpenModal(false)} setModalMessage={setModalMessage} />
      <Modal
        open={!!modalMessage}
        handleClose={() => setModalMessage(null)}
        title={modalMessage?.title}
        titleColor={modalMessage?.color}
        position="top"
      />
    </>
  );
};
