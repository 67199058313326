import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-query';
import { queryClient } from '../../App';

import { TextButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/Table/BaseTable';
import { PaginationControl } from '../../components/Table/PaginationControl';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import { ApiService, CustomError } from '../../services/api';
import { User, UserList } from '../../types/user';

export interface Header {
  label: string;
}

interface AdvisorsTableProps {
  data: UserList;
  isFetching: boolean;
  page: number;
  header: Header[];
  rowsPerPage: number;
  handlePageChange: (page: number) => void;
}

export const AdvisorsTable: FunctionComponent<AdvisorsTableProps> = ({
  data,
  isFetching,
  page,
  rowsPerPage,
  header,
  handlePageChange,
}) => {
  const [selectedUid, setSelectedUid] = useState('');
  const { user } = useGetUserInfo();
  const heightRows = data.list.length < rowsPerPage ? data.list.length : rowsPerPage;
  const { mutate, isLoading } = useMutation<
    User,
    CustomError,
    { data: { isAdmin: boolean; isAdvisor: boolean }; path: string }
  >('change-role', ApiService.patch(), {
    onSuccess: (data) => {
      setSelectedUid('');
      queryClient.setQueryData(['advisor-list-page', rowsPerPage], (oldData: any) => {
        if (oldData) {
          const index = oldData.pageParams.findIndex((p: any) => p === page);
          const pageIndex = index === -1 ? 0 : index;
          const advisorIndex = oldData.pages[pageIndex].list.findIndex((i: any) => i.uid === data.uid);
          if (pageIndex !== -1 && advisorIndex !== -1) {
            oldData.pages[pageIndex].list[advisorIndex].role = data.role;
          }
          return { ...oldData };
        }
      });
    },
    onError: () => {
      setSelectedUid('');
    },
  });

  const handleChangeRole = (uid: string, role?: string) => {
    const isAdmin = role !== 'ADMIN';
    const isAdvisor = role !== 'ADVISOR';
    setSelectedUid(uid);

    mutate({ path: `/v1/advisor/${uid}`, data: { isAdmin, isAdvisor } });
  };

  return (
    <>
      <TableContainer style={{ width: '1260px', height: `${(heightRows + 1) * 50 + 13}px` }}>
        <Table>
          <TableHead>
            <TableRow>
              {header.map(({ label }, i) => (
                <TableCell key={i} align="left">
                  <Typography color="darkerBlue" type="smallBold" style={{ display: 'flex', alignItems: 'center' }}>
                    {label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ opacity: isFetching ? 0.5 : 1 }}>
            {data.list.map((i, index: number) => {
              const { uid, firstName, lastName, email, createdAt, totalCustomers, role } = i;
              return (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: (index + 1) % 2 === 0 ? Theme.colors.white : Theme.colors.grey3,
                    border: 'none',
                  }}
                >
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal">
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal">
                      {`${firstName} ${lastName}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal">
                      {email}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal">
                      {moment(createdAt).format('DD-MM-YY')}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal">
                      {totalCustomers}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row" style={{ width: '70px' }}>
                    <Typography color="darkerBlue" type="smallNormal">
                      {role === 'ADVISOR' ? 'Advisor' : 'Admin'}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row" style={{ width: '150px' }}>
                    {user.uid === uid ? null : selectedUid === uid && isLoading ? (
                      <Loading style={{ width: '20px', height: '20px' }} />
                    ) : (
                      <TextButton color="darkerBlue" type="smallNormal" onClick={() => handleChangeRole(uid, role)}>
                        {role === 'ADVISOR' ? 'Upgrade to Admin' : 'Downgrade to Advisor'}
                      </TextButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationControl
        totalCount={data.pagination.total}
        // fullWidth={}
        siblingCount={2}
        currentPage={page}
        pageSize={rowsPerPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};
