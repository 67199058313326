import React, { useState } from 'react';
import { useParams } from 'react-router';
import { ReadyState } from 'react-use-websocket';
import styled from 'styled-components';
import { Send } from '../../assets/icons/Send';
import { useIsMobileView } from '../../components/MobileView';
import { TextInput } from '../../components/TextInput';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { useSocketClient } from '../../hooks/useSocketClient';
import { SocketAction } from '../../types/global';

const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  border: none;
  background-color: transparent;
  flex: none;
  cursor: pointer;
`;

const getSocketConnectionMessage = (readyState: ReadyState) => {
  switch (readyState) {
    case ReadyState.CLOSED:
      return 'Connection is closed! Trying to reconnect!';
    case ReadyState.CLOSING:
      return 'Network Error: Closing connection!';
    case ReadyState.UNINSTANTIATED:
      return 'Network Error: Connection not created!';
    case ReadyState.CONNECTING:
      return 'Reconnecting...';
    case ReadyState.OPEN:
      return '';
    default:
      return 'Network Error';
  }
};
export const MessengerTextInput = ({ canWrite, scrollToEnd }: { canWrite: boolean; scrollToEnd: () => void }) => {
  const { accountUid } = useParams<{ accountUid: string }>();
  const isMobile = useIsMobileView();
  const [textInputValue, setTextInputValue] = useState('');
  const { sendJsonMessage, readyState } = useSocketClient();
  const handleTextInput = (e) => {
    setTextInputValue(e.target.value);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (textInputValue.trim()) {
      sendJsonMessage({ action: SocketAction.SEND_MESSAGE, data: { accountUid, text: textInputValue.trim() } });
      scrollToEnd();
    }
    setTextInputValue('');
  };

  const errorMessage = getSocketConnectionMessage(readyState);

  return (
    <form onSubmit={sendMessage} autoComplete="off">
      <div
        style={{
          padding: isMobile ? '16px' : '16px 24px',
          borderTop: `1px solid ${Theme.colors.grey5}`,
          backgroundColor: Theme.colors.white,
        }}
      >
        {!!errorMessage && (
          <Typography color="darkPink" type="smallNormal" style={{ marginTop: '-16px' }}>
            {errorMessage}
          </Typography>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextInput
            style={{ width: '100%', fontSize: '16px' }}
            placeholder={
              canWrite ? 'Type your message here...' : 'Only the allocated advisor can chat to this customer'
            }
            onChange={handleTextInput}
            value={textInputValue}
            disabled={!canWrite || !!errorMessage}
          />
          <SendButton
            style={{ marginLeft: isMobile ? '16px' : '24px' }}
            type="submit"
            disabled={!!errorMessage || !canWrite}
          >
            <Send />
          </SendButton>
        </div>
      </div>
    </form>
  );
};
