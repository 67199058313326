import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { queryClient } from '../../App';
import { Filter } from '../../assets/icons/Filter';
import { Search } from '../../assets/icons/Search';
import { FilterButton } from '../../components/Button';
import { useIsMobileView } from '../../components/MobileView';
import { TextInput } from '../../components/TextInput';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { useSocketClient } from '../../hooks/useSocketClient';
import { useGetUserInfo, useIsCustomer } from '../../hooks/useGetUserInfo';
import { Conversation, ConversationList } from '../../types/conversation';
import { SocketAction, SocketData } from '../../types/global';
import { ReadMessage } from '../../types/message';
import { User } from '../../types/user';
import { ConversationItem } from './ConversationItem';
import { FilterModal } from './FilterModal';

export const Conversations = ({
  conversations,
  isFetching,
  selectedUser,
  setSelectedUser,
  showMessenger,
}: {
  conversations: Conversation[];
  isFetching: boolean;
  selectedUser?: User;
  setSelectedUser: (u: User) => void;
  showMessenger?: () => void;
}) => {
  const { accountUid } = useParams<{ accountUid: string }>();
  const { user } = useGetUserInfo();
  const isCustomer = useIsCustomer();
  const [search, setSearch] = useState('');
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const isMobile = useIsMobileView();
  const { lastJsonMessage } = useSocketClient<SocketData, any>();

  const selectedConversation = conversations.find((c) => c.account.uid === accountUid);
  const cacheKeyUserUid = selectedUser ? selectedUser.uid : user.uid;
  const cacheKeyUnallocated = selectedUser ? undefined : true;
  const cacheKey = ['conversations', cacheKeyUserUid, cacheKeyUnallocated];

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.action === SocketAction.NEW_MESSAGE) {
        const {
          data: { message, newMessages },
        } = lastJsonMessage;

        const isInList = !!conversations.find((c) => c.account.uid === message.accountUid);
        const canWrite = selectedUser && selectedUser.uid === user.uid;
        // update new messages number, last message text and date
        if (isInList) {
          queryClient.setQueryData<ConversationList | undefined>(cacheKey, (allData) => {
            if (allData) {
              const conversationIndex = allData.conversations.findIndex((c) => c.account.uid === message.accountUid);
              if (conversationIndex !== -1) {
                const isAtBottom = queryClient.getQueryData('isAtBottomList');
                const notShowUnreadTotal = canWrite && isAtBottom && accountUid === message.accountUid;
                const conv = allData.conversations[conversationIndex];
                conv.account.advisorUnreadTotal = notShowUnreadTotal ? 0 : newMessages;
                conv.account.lastMessage = message.message;
                conv.account.lastMessageDate = message.createdAt;
              }
            }
            return allData;
          });
        }
        return;
      } else if (lastJsonMessage.action === SocketAction.READ_MESSAGE) {
        const readMessage = lastJsonMessage.data as ReadMessage;
        const inList = !!conversations.find((c) => c.account.uid === readMessage.accountUid);
        if (inList && readMessage.userUid === user.uid) {
          queryClient.setQueryData<ConversationList | undefined>(cacheKey, (allData) => {
            if (allData) {
              const conversationIndex = allData.conversations.findIndex(
                (c) => c.account.uid === readMessage.accountUid
              );
              if (conversationIndex !== -1) {
                const conv = allData.conversations[conversationIndex];
                conv.account.advisorUnreadTotal = 0;
              }
            }
            return allData;
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  if (isCustomer) {
    return null;
  }

  const FilteredName = selectedUser
    ? selectedUser.uid === user?.uid
      ? 'Mine'
      : `${selectedUser.firstName} ${selectedUser.lastName}`
    : 'Not allocated';

  const applyFilters = (u: User) => {
    setSelectedUser(u);
    setSearch('');
  };
  return (
    <div
      style={{
        maxWidth: isMobile ? '' : '400px',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: Theme.colors.white,
        position: 'relative',
        borderRight: `1px solid ${Theme.colors.grey5}`,
      }}
    >
      <div style={{ width: '100%', height: '88px' }}>
        <div
          style={{
            display: 'flex',
            padding: isMobile ? '16px' : '24px',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${Theme.colors.grey5}`,
          }}
        >
          <TextInput
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ marginRight: isMobile ? '8px' : '12px', maxWidth: '300px', flex: 1 }}
            endAdornment={<Search style={{ fontSize: '24px' }} />}
          />
          <FilterButton
            onClick={() => setFilterOpen(!filterOpen)}
            style={{
              marginLeft: isMobile ? '8px' : '12px',
              maxWidth: '300px',
              color: Theme.colors.darkerBlue,
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <Typography type="smallNormal">{FilteredName}</Typography> <Filter style={{ fontSize: '24px' }} />
          </FilterButton>
        </div>
      </div>
      <div style={{ flex: 1, overflow: 'auto', opacity: isFetching ? 0.5 : 1 }}>
        {!isEmpty(conversations) ? (
          conversations
            .filter(
              (c) =>
                c.customer.firstName.toLowerCase().includes(search.toLowerCase()) ||
                c.customer.lastName.toLowerCase().includes(search.toLowerCase())
            )
            .map((c) => (
              <ConversationItem
                key={c.account.uid}
                conversation={c}
                selectedConversation={selectedConversation}
                onClick={showMessenger}
              />
            ))
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '24px' }}>
            <Typography color="grey1" type="smallNormal">{`This advisor doesn't have any customers`}</Typography>
          </div>
        )}
      </div>
      <FilterModal
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        me={user}
        selected={selectedUser}
        applyFilters={applyFilters}
      />
    </div>
  );
};
