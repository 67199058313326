import { Radio } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Close } from '../../assets/icons/Close';
import { GroupButton } from '../../components/GroupButton';
import { Loading } from '../../components/Loading';
import { useIsMobileView } from '../../components/MobileView';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { ApiService, CustomError } from '../../services/api';
import { AdvisorList } from '../../types/advisor';
import { User } from '../../types/user';

export const FilterModal = ({ open, onClose, applyFilters, selected, me }: any) => {
  const { data, isLoading, error } = useQuery<AdvisorList, CustomError>(
    ['advisors-filter'],
    ApiService.get('/v1/user/advisor', { limit: 100, offset: 0 })
  );
  const [selectedAdvisor, setSelectedAdvisor] = useState(selected);

  const isMobile = useIsMobileView();
  const handleOnClose = () => {
    onClose();
    setSelectedAdvisor(selected);
  };

  const handleApplyFilters = () => {
    applyFilters(selectedAdvisor);
    onClose();
  };

  if (!open) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data) {
    return <div>Error</div>;
  }

  const advisors = data.list.filter((a) => a.uid !== me.uid);
  const list: { label: string; value?: User }[] = [
    { label: 'Mine', value: me },
    { label: 'Not allocated', value: undefined },
    ...advisors.map((a) => ({ label: `${a.firstName} ${a.lastName}`, value: a })),
  ];
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 10000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          padding: isMobile ? '16px' : '24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${Theme.colors.grey5}`,
        }}
      >
        <Typography type="b3Bold" color="darkerBlue">
          Filter conversations by advisor
        </Typography>
        <Close
          style={{ color: Theme.colors.darkerBlue, fontSize: '20px', cursor: 'pointer' }}
          onClick={handleOnClose}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
        {list.map((a) => (
          <div
            key={a.value?.uid || 'not'}
            style={{
              padding: isMobile ? '16px' : '16px 24px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            onClick={() => setSelectedAdvisor(a.value)}
          >
            <Typography type="b3Normal" color="darkerBlue">
              {a.label}
            </Typography>
            <Radio
              checked={a.value?.uid === selectedAdvisor?.uid}
              style={{ color: Theme.colors.darkerBlue, height: '10px', width: '10px' }}
            />
          </div>
        ))}
      </div>
      <div style={{ padding: isMobile ? '16px' : '24px', boxShadow: '0px -4px 20px rgba(0, 0, 0, 0.1)' }}>
        <GroupButton
          primaryLabel="Confirm"
          onPrimaryClick={handleApplyFilters}
          secondaryLabel="Cancel"
          onSecondaryClick={handleOnClose}
        />
      </div>
    </div>
  );
};
