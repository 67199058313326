import styled from 'styled-components';
import { ColorKeys, Theme, ThemeTypographyKeys } from '../constants/theme';
import { useIsMobileView } from './MobileView';

export interface TypographyProps {
  size?: 'large' | 'small';
  type?: ThemeTypographyKeys;
  color?: ColorKeys;
}

export const Typography = styled.div<TypographyProps>`
  ${({ size, type, color }) => {
    const isMobile = useIsMobileView();
    size = size || isMobile ? 'small' : 'large';
    type = type || 'smallNormal';
    color = color || 'darkBlue';

    const colorCss = `color: ${Theme.colors[color]};`;
    const typographyCss = Theme.typography[size][type];
    return `${colorCss}${typographyCss}`;
  }}
`;
