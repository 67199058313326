import React, { FunctionComponent } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { Theme } from '../constants/theme';
import { Loading } from './Loading';
import { useIsMobileView } from './MobileView';
import { Typography, TypographyProps } from './Typography';

const StyledLink = styled(Link)`
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  text-decoration: none;
`;

const TextButtonTypography = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
`;

const TertiaryTypography = styled(Typography)<TypographyProps>`
  padding: 4px 0;
  border-bottom: 1px solid ${({ color }) => (color ? Theme.colors[color] : Theme.colors.darkBlue)};
`;

const TertiaryButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ color }) => (color ? Theme.colors[color as 'darkBlue'] : Theme.colors.darkBlue)};
`;

type BtnType = 'primary' | 'secondary';
type BtnStyleColor = 'dark' | 'light';
interface ButtonProps {
  styleType?: BtnType;
  color?: BtnStyleColor;
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
}

interface CustomButtonProps {
  color?: string;
  backgroundColor?: string;
  border?: boolean;
  bradius?: boolean;
  size: 'large' | 'small';
  bloading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const getPrimaryColors = (size: 'large' | 'small'): { [k in BtnStyleColor | 'grey']: CustomButtonProps } => ({
  dark: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.darkBlue,
    bradius: true,
    size,
  },
  light: {
    color: Theme.colors.darkBlue,
    backgroundColor: Theme.colors.white,
    bradius: true,
    size,
  },
  grey: {
    color: Theme.colors.white,
    backgroundColor: Theme.colors.grey2,
    bradius: true,
    size,
  },
});

const StyledButton = styled.button<CustomButtonProps>`
  flex: none;
  cursor: pointer;
  height: auto;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ size }) => (size === 'large' ? 'min-width: 120px;' : 'min-width: 120px;')};
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')}
  ${({ color }) => (color ? `color: ${color};` : '')}
  ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
  ${({ border, color }) => (border ? `border: 1px solid ${color ? color : Theme.colors.darkBlue};` : 'border: none;')}
  ${({ bradius }) => (bradius ? `border-radius: 4px;` : '')} 
  ${({ size }) => Theme.typography[size].b3Bold}
  ${({ disabled }) =>
    disabled
      ? `pointer-events: none;
        cursor: default;`
      : ``};
  ${({ bloading }) =>
    bloading
      ? `pointer-events: none;
        cursor: default;`
      : ``};
`;

export const Button: FunctionComponent<
  ButtonProps &
    Omit<
      React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
      keyof ButtonProps | 'ref'
    >
> = ({ color, styleType, iconBefore, iconAfter, children, disabled, loading, ...rest }) => {
  const isMobile = useIsMobileView();
  const size = isMobile ? 'small' : 'large';
  styleType = styleType || 'primary';
  color = color || 'dark';
  const buttonStyle = getPrimaryColors(size);

  let props = buttonStyle[disabled ? 'grey' : color];
  if (styleType === 'secondary') {
    props = { ...buttonStyle['light'], border: true };
  }
  return (
    <StyledButton disabled={disabled} bloading={loading} {...props} {...rest}>
      {loading ? (
        <Loading style={{ width: '20px', height: '20px', color: props.color }} />
      ) : (
        <>
          {iconBefore}
          {children}
          {iconAfter}
        </>
      )}
    </StyledButton>
  );
};

interface TextButtonProps extends TypographyProps, Partial<Pick<LinkProps, 'to' | 'target' | 'style'>> {
  onClick?: () => void | Promise<void>;
}
export const TextButton: FunctionComponent<TextButtonProps> = ({ children, color, size, type, to, ...rest }) => {
  if (to) {
    return (
      <StyledLink {...{ to, ...rest }}>
        <TextButtonTypography {...{ color, size, type }}>{children}</TextButtonTypography>
      </StyledLink>
    );
  }
  return <TextButtonTypography {...{ color, size, type, ...rest }}>{children}</TextButtonTypography>;
};

interface TertiaryButtonProps extends TypographyProps, Partial<Pick<LinkProps, 'to' | 'target'>> {
  onClick?: () => void | Promise<void>;
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;
}

export const TertiaryButton: FunctionComponent<TertiaryButtonProps> = ({
  to,
  color,
  size,
  type,
  iconBefore,
  iconAfter,
  children,
  ...rest
}) => {
  if (to) {
    return (
      <StyledLink {...{ to, ...rest }}>
        {iconBefore}
        <TertiaryTypography {...{ color, size, type }}>{children}</TertiaryTypography>
        {iconAfter}
      </StyledLink>
    );
  }
  return (
    <TertiaryButtonWrapper {...{ color, ...rest }}>
      {iconBefore}
      <TertiaryTypography {...{ color, size, type }}>{children}</TertiaryTypography>
      {iconAfter}
    </TertiaryButtonWrapper>
  );
};

export const FilterButton = styled.button`
  height: 40px;
  flex: none;
  cursor: pointer;
  padding: 12px 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${Theme.colors.grey3};
  border: none;
`;
