import React from 'react';
import { queryClient } from '../../../App';
import { NavigationService } from '../../../services';

export const PageNotFound = () => {
  return <div>Page Not Found 404</div>;
};

export const sessionExpCheck = () => {
  const exp = localStorage.getItem('token_exp');
  if (!exp || Date.now() >= parseInt(exp!, 10) * 1000) {
    logoutHandler();
    return false;
  }
  return true;
};

export function logoutHandler() {
  localStorage.clear();
  NavigationService.navigate('/auth/login');
  setTimeout(() => {
    queryClient.clear();
  }, 500);
}
