import React from 'react';

function ArrowBackBase(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" {...props}>
      <path d="M16 7H3.83l5.59-5.59L8 0 0 8l8 8 1.41-1.41L3.83 9H16V7z" fill="currentColor" />
    </svg>
  );
}

export const ArrowBack = React.memo(ArrowBackBase);
