import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Loading } from '../components/Loading';
import { useIsMobileView } from '../components/MobileView';
import { Theme } from '../constants/theme';
import { NavFooter } from '../features/dashboard/main/NavFooter';
import { NavHeader } from '../features/dashboard/main/NavHeader';
import { PageNotFound } from '../features/main/mainPage/mainPage.component';
import { useGetUserInfo } from '../hooks/useGetUserInfo';
import { ChatPage } from './Chat/ChatPage';
import { CustomerPage } from './Customer/CustomerPage';
import { CustomersPage } from './Customers/CustomersPage';
import { Settings } from './Settings/Settings';
import { AdvisorsPage } from './Advisors/AdvisorsPage';

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const PageBody = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: block;
  background-color: ${Theme.colors.grey3};
`;

export const BodyCenterMaxWidth = styled.div`
  max-width: 1328px;
  margin: 0 auto;
  height: 100%;
`;

export const PrivateRouter = () => {
  const match = useRouteMatch();
  const isMobileView = useIsMobileView();
  const { user, isLoading, error } = useGetUserInfo();

  if (isLoading) {
    return <Loading />;
  }

  if (error || !user) {
    return <Redirect to={{ pathname: '/error', state: { error } }} />;
  }

  const isAdvisor = user.permissions.includes('APP_ADVISOR');
  const isAdmin = user.permissions.includes('APP_ADMIN');
  return (
    <FlexCol>
      <NavHeader me={user} isMobile={isMobileView} />
      <PageBody>
        <BodyCenterMaxWidth>
          <Switch>
            <Route path={`${match.path}/chat`} component={ChatPage} />
            <Route path={`${match.path}/settings`} component={Settings} />
            {isAdvisor && <Route exact path={`${match.path}/customers`} component={CustomersPage} />}
            {isAdvisor && <Route path={`${match.path}/customers/:customerId`} component={CustomerPage} />}
            {isAdmin && <Route exact path={`${match.path}/advisors`} component={AdvisorsPage} />}
            <Route path={match.path} exact render={() => <Redirect to={`${match.path}/chat`} />} />
            <Route component={PageNotFound} />
          </Switch>
        </BodyCenterMaxWidth>
      </PageBody>
      {isMobileView && <NavFooter me={user} />}
    </FlexCol>
  );
};
