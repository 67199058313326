import { Avatar as MUIAvatar, AvatarProps as MUIAvatarProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { ColorKeys, Theme, ThemeTypographyKeys } from '../constants/theme';
import { Typography } from './Typography';

interface AvatarProps extends MUIAvatarProps {
  color?: ColorKeys;
  backgroundColor?: ColorKeys;
  size?: ThemeTypographyKeys;
}
export const Avatar = styled(({ color, className, size, backgroundColor, children, ...rest }: AvatarProps) => {
  return (
    <MUIAvatar {...rest} className={className} component="div">
      <Typography color={color} type={size}>
        {children}
      </Typography>
    </MUIAvatar>
  );
})`
  letter-spacing: 1px;
  padding-left: 1px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `${Theme.colors[backgroundColor]} !important` : `inherit !important`};

  &.MuiAvatar-colorDefault {
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? `${Theme.colors[backgroundColor]} !important` : `inherit !important`};
  }
`;
