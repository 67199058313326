//@ts-nocheck
import {
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';
import { Address } from '../../../common/interfaces/onboarding.interface';
import { Formik, Form, Field } from 'formik';
import InsertAddressManually from './InsertAddressManually';
import { AnyStyledComponent } from 'styled-components';

interface Props {
  step: number;
  onAction: any;
  apiKey?: string;
  identifier: string;
  country: string;
  placeholder?: string | boolean;
  handleStatus: any;
  status: any;
  address: any;
  handleAddressChange: any;
  disabled: any;
}

const OnboardingStep2: React.FC<Props> = (props: Props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [postCodeInput, setPostcodeInput] = useState('');
  const [toggleView, setToggleView] = useState(false);
  const search = (event: any) => {
    setPostcodeInput(event.target.value);
    if (event.target.value.length >= 5) searchAddress(event.target.value);
  };

  const handleToggleView = () => {
    setToggleView(!toggleView);
  };

  const searchAddress = (postCodeInputs: any) => {
    let { apiKey, identifier, country } = props;
    country === '' ? (country = 'UK') : (country = 'UK'); // defaultProps only works if the prop is absent, not set to an empty string
    setLoading(true);
    fetch(
      `https://ws.postcoder.com/pcw/${apiKey}/address/${country}/${encodeURIComponent(
        postCodeInputs
      )}?format=json&lines=2&identifier=${identifier}&postcodeonly=true`
    )
      .then((response) => {
        return response.json();
      })
      .then(
        (data) => {
          setData(data);
          setLoading(false);

          // this.setState({ data: data, loading: false });
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
  };

  if (props.step !== 2) return null;

  return (
    <>
      {toggleView ? (
        <InsertAddressManually
          handleChange={props.handleAddressChange}
          toggleView={handleToggleView}
          onAction={props.onAction}
          addressLine2={props.address?.addressline2}
          postcode={props.address?.postcode}
          town={props.address?.posttown}
          country={props.country}
          county={props.address?.county}
          addressLine1={props.address?.street}
        />
      ) : (
        <Container>
          <h1 className="title"> What's your address?</h1>
          <div className="postcode-fields">
            <TextField label="Your postcode" required={true} variant="filled" value={postCodeInput} onChange={search} />
            { <FormControl variant="filled">
                    <InputLabel>Select your address</InputLabel>   
            <Select variant="filled" label="Select address" value={props.address} onChange={props.handleAddressChange}>
              {data.map((i: Address, index) => (
                <MenuItem value={i} key={index}>
                  {i.summaryline}
                </MenuItem>
              ))}
            </Select>
             </FormControl>   }
            {/* <a
              onClick={handleToggleView}
              style={{ color: "black", textDecoration: "underline" }}
            >
              My address isn't listed
            </a> */}
            <FormControl variant="filled">
              <InputLabel>Status (tenant/owner)</InputLabel>
              <Select
                variant="filled"
                value={props.status}
                label="Status (tenant/owner)"
                onChange={props.handleStatus}
                id="filled-basic"
              >
                <MenuItem value="tenant">Tenant</MenuItem>
                <MenuItem value="owner">Owner</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="button">
            <Button variant="contained" color="primary" disabled={props.disabled} onClick={props.onAction}>
              Next step
            </Button>
          </div>
        </Container>
      )}
    </>
  );
};

export default OnboardingStep2;
