import { useEffect, useState } from 'react';
import { InfiniteData, useInfiniteQuery as useInfiniteRQuery, UseInfiniteQueryOptions } from 'react-query';
import { ApiService, CustomError } from '../services/api';
import { Pagination } from '../types/global';
interface UsePaginationQueryProps<TVariables extends object> extends UseInfiniteQueryOptions {
  path: string;
  cacheKey: (string | number)[];
  queryParams?: TVariables;
  holdOldDataOnForceRefetch?: boolean;
  useLastItemUidAsOffset?: boolean;
  limit?: number;
  reverseData?: boolean;
}

export const useInfiniteQuery = <TData extends { pagination: Pagination; list: any[] }, TVariables extends object>({
  path,
  cacheKey,
  queryParams,
  holdOldDataOnForceRefetch,
  useLastItemUidAsOffset,
  limit = 20,
  reverseData,
  refetchOnMount,
}: UsePaginationQueryProps<TVariables>) => {
  const [holdOldData, setHoldOldData] = useState<TData['list']>([]);

  const fetch = <K>({ pageParam = 0 }) =>
    ApiService.get(path)<K, any>({
      limit: limit,
      offset: pageParam,
      ...queryParams,
    });

  const getNextPageParam = (item: TData) => {
    const { limit, offset, total } = item.pagination;
    if (useLastItemUidAsOffset) {
      return total ? total : undefined;
    }
    return total > offset + limit ? limit + offset : undefined;
  };

  const getPageData = (data: InfiniteData<TData> | undefined): TData['list'] | undefined => {
    if (!data) {
      return;
    }
    const allData = data.pages.reduce((allData, pageData) => {
      return [...allData, ...pageData.list];
    }, [] as TData['list']);
    if (reverseData) {
      return allData.reverse();
    }
    return allData;
  };

  const { isLoading, error, data, fetchNextPage, isFetching, remove, refetch, hasNextPage } = useInfiniteRQuery<
    TData,
    CustomError
  >(cacheKey, fetch, {
    refetchOnMount,
    getNextPageParam: getNextPageParam,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (holdOldDataOnForceRefetch && data) {
        const allData = getPageData(data);
        allData && setHoldOldData(allData);
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => remove, []); // clean the data on unmount

  const forceRefetch = () => {
    remove();
    refetch();
  };

  const loading = holdOldDataOnForceRefetch && holdOldData ? false : isLoading;
  const fetching = holdOldDataOnForceRefetch ? isLoading || isFetching : isFetching;
  const returnData = holdOldDataOnForceRefetch ? holdOldData : getPageData(data);

  return {
    fetchNextPage,
    isLoading: loading,
    error,
    data: returnData,
    isFetching: fetching,
    forceRefetch,
    hasNextPage,
  };
};
