import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { LoginPage } from './Login/LoginPage';
import { ForgotPassword } from './ResetPassword/ForgotPassword';
import { ResetPassword } from './ResetPassword/ResetPassword';
import CongratsStep from './Onboarding/onboardingComponents/CongratsStep';
import OnboardingLayout from './Onboarding/OnboardingLayout/OnboardingLayout';
import { BlockedUser } from './Error/BlockedUser';
import { SetNewPassword } from './ResetPassword/SetNewPassword';

export const PublicRouter = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/forgot-password`} component={ForgotPassword} />
      <Route path={`${match.path}/set-new-password`} component={SetNewPassword} />
      <Route path={`${match.path}/reset-password`} component={ResetPassword} />
      <Route path={`${match.path}/login`} component={LoginPage} />
      <Route path={`${match.path}/blocked-user`} component={BlockedUser} />
      <Route path={`${match.path}/onboarding`} component={OnboardingLayout} />
      <Route path={`${match.path}/congrats`} component={CongratsStep} />
    </Switch>
  );
};
