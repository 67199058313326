import { Form, Formik, FormikHelpers, useField, useFormikContext } from 'formik';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { TertiaryButton } from '../../components/Button';
import { FormTextInputFilled } from '../../components/FormTextInput';
import { GroupButton } from '../../components/GroupButton';
import { FormSwitch } from '../../components/SwitchButton';
import { Typography } from '../../components/Typography';
import { PageContentBlock, Section, SectionHeader } from './PageStyleComponents';
import * as Yup from 'yup';
import { isNil, omitBy } from 'lodash';
import { AdvisorModal } from './AdvisorModal';
import { useMutation } from 'react-query';
import { ApiService, CustomError } from '../../services/api';
import { queryClient } from '../../App';
import { Modal } from '../../components/Dialog';
import { ColorKeys } from '../../constants/theme';
import { useHistory } from 'react-router-dom';
import { useIsMobileView } from '../../components/MobileView';
import { Account, AddAdvisorInput, UpdateAccountInput, UpdateAccountResponse } from '../../types/account';
import { User } from '../../types/user';
import { Customer } from '../../types/customer';

interface CustomerDetailsProps {
  customer: User;
  account: Account;
  advisor?: User;
}

interface Values {
  salesforceURL?: string;
  isInactive: boolean;
  advisorUid?: string;
  advisorFirstName?: string;
  advisorLastName?: string;
}

const validationSchema = Yup.object().shape({
  isInactive: Yup.boolean(),
  salesforceURL: Yup.string().url('must be a valid URL format').optional(),
  advisorUid: Yup.string().optional(),
});

const BlockModal = ({ open, close, customer, setModalMessage }) => {
  const history = useHistory();
  const { mutate, isLoading } = useMutation<any, CustomError, { data: any; path: string }>(
    'block-user',
    ApiService.patch(),
    {
      onSuccess: () => {
        history.goBack();
      },
      onError: () => {
        setModalMessage({
          title: 'Oops! Something went wrong!',
          color: 'darkPink',
        });
        setTimeout(() => setModalMessage(null), 1000);
      },
    }
  );

  const blockAdvisor = () => {
    mutate({ path: `/v1/user/${customer.uid}`, data: { blockedAt: new Date() } });
  };
  const title = `Are you sure you want to block the customer ${customer.firstName} ${customer.lastName}?`;

  return (
    <Modal open={open} handleClose={close} closeX title={title} style={{ maxWidth: '480px' }}>
      <div style={{ width: 'calc(100% - 48px)', margin: '24px' }}>
      <div style={{ marginBottom: '24px' }}>
      <Typography type="b3Bold" color="darkerBlue">Please note that blocking a user cannot be undone.</Typography> 
      <Typography type="b3Bold" color="darkerBlue">Please click to confirm a permanent block on this user!</Typography>
      </div>
        <GroupButton
          primaryLabel="Yes"
          onPrimaryClick={blockAdvisor}
          loading={isLoading}
          onSecondaryClick={close}
          secondaryLabel="Cancel"
        />
      </div>
    </Modal>
  );
};

const AdvisorSection = ({ openAdvisorModal, openBlockModal }: any) => {
  const { values } = useFormikContext<Values>();
  const haveAdvisor = !!values.advisorFirstName && !!values.advisorLastName;
  const advisorName = `${values.advisorFirstName} ${values.advisorLastName}`;
  return (
    <>
      {haveAdvisor && (
        <Section>
          <Typography type="b3Bold" color="darkerBlue">
            Advisor
          </Typography>
          <Typography type="b3Normal" color="darkerBlue">
            {advisorName}
          </Typography>
        </Section>
      )}
      <Section style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <TertiaryButton type="b3Bold" color="darkBlue" onClick={openAdvisorModal}>
          {haveAdvisor ? 'Reallocate' : 'Allocate advisor'}
        </TertiaryButton>
        <TertiaryButton type="b3Bold" color="darkBlue" onClick={openBlockModal} style={{ marginRight: '24px' }}>
          {'Block'}
        </TertiaryButton>
      </Section>
    </>
  );
};

const ButtonSection = ({ loading, ...props }: Values & { loading: boolean }) => {
  const isMobile = useIsMobileView();
  const [{ value: salesforceURL }] = useField('salesforceURL');
  const [{ value: isInactive }] = useField('isInactive');
  const [{ value: advisorUid }] = useField('advisorUid');
  const filteredValues = omitBy({ salesforceURL, isInactive, advisorUid }, isNil);
  const filteredProps = omitBy(props, isNil);
  const showButtons =
    filteredValues.salesforceURL !== filteredProps.salesforceURL ||
    filteredValues.isInactive !== filteredProps.isInactive ||
    filteredValues.advisorUid !== filteredProps.advisorUid;
  const history = useHistory();
  const [confirmModal, setConfirmModal] = useState(false);
  const [block, setBlocking] = useState(false);
  const [next, setNext] = useState<{ action: any; location: any } | null>(null);

  useEffect(() => {
    setBlocking(showButtons);
  }, [showButtons]);

  function callback(location: any, action: any) {
    if (block) {
      setNext({ location, action });
      setConfirmModal(true);
      return false;
    }
    return;
  }

  const unblockRef = useRef(history.block(callback));

  const handleNavigate = () => {
    setConfirmModal(false);
    setBlocking(false);
    unblockRef.current();
    setTimeout(() => {
      if (next) {
        setNext(null);
        if (next.action === 'POP') {
          history.goBack();
        } else if (next.action === 'PUSH') {
          history.push(next.location.pathname);
        } else if (next.action === 'REPLACE') {
          history.replace(next.location.pathname);
        }
      }
    }, 100);
  };

  if (!showButtons) {
    return null;
  }

  return (
    <>
      <Section>
        <GroupButton
          primaryLabel="Save"
          secondaryLabel="Cancel"
          primaryType="submit"
          secondaryType="reset"
          loading={loading}
        />
      </Section>
      <Modal
        open={confirmModal}
        handleClose={() => setConfirmModal(false)}
        title="Are you sure you want to leave?"
        position={isMobile ? undefined : 'top'}
        closeX
        keepMounted={false}
      >
        <div style={{ display: 'flex', flexDirection: 'column', margin: '6px 24px 24px 24px', maxWidth: '410px' }}>
          <Typography type="b3Normal" color="darkerBlue" style={{ marginBottom: '24px' }}>
            You haven’t clicked ‘save’ any edits you made on this page will be cancelled.
          </Typography>
          <GroupButton
            primaryLabel="Yes leave"
            secondaryLabel="Cancel"
            onPrimaryClick={handleNavigate}
            onSecondaryClick={() => {
              setConfirmModal(false);
              setNext(null);
            }}
          />
        </div>
      </Modal>
    </>
  );
};
export const CustomerDetails: FunctionComponent<CustomerDetailsProps> = ({ account, advisor, customer }) => {
  const [advisorModal, setAdvisorModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<{ title: string; color?: ColorKeys } | null>(null);
  const [blockModal, setBlockModal] = useState(false);

  const initialValues: Values = {
    isInactive: !!account.closedAt,
    salesforceURL: account.salesforceURL || '',
    advisorUid: advisor?.uid || '',
    advisorFirstName: advisor?.firstName || '',
    advisorLastName: advisor?.lastName || '',
  };

  const { mutateAsync: mutateAccount, isLoading: isLoadingAccount } = useMutation<
    UpdateAccountResponse,
    CustomError,
    { data: UpdateAccountInput }
  >('patch-account', ApiService.patch(`/v1/account/${account.uid}`), {
    onSuccess: (data) => {
      queryClient.setQueryData<Customer | undefined>(['customer', customer.uid], (oldData) => {
        if (oldData) {
          return { ...oldData, ...data };
        }
      });
    },
  });

  const { mutateAsync: mutateAdvisor, isLoading: isLoadingAdvisor } = useMutation<
    Account,
    CustomError,
    { data: AddAdvisorInput }
  >('add-advisor', ApiService.post(`/v1/account/${account.uid}/add-advisor`), {
    onSuccess: (data) => {
      queryClient.setQueryData<Customer | undefined>(['customer', customer.uid], (oldData) => {
        if (oldData) {
          return { ...oldData, advisor: data.advisor };
        }
      });
    },
  });

  const handleSubmit = async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    const formattedValues = omitBy<Values>(values, isNil);
    const formattedInitial = omitBy<Values>(initialValues, isNil);

    const { isInactive, advisorUid, salesforceURL } = formattedValues;
    const promises: Promise<UpdateAccountResponse | Account>[] = [];
    if (isInactive !== formattedInitial.isInactive || salesforceURL !== formattedInitial.salesforceURL) {
      promises.push(
        mutateAccount({
          data: { salesforceURL, close: isInactive !== formattedInitial.isInactive ? isInactive : undefined },
        })
      );
    }

    if (advisorUid && advisorUid !== formattedInitial.advisorUid) {
      promises.push(mutateAdvisor({ data: { advisorUserUid: advisorUid } }));
    }

    const res = await Promise.allSettled(promises);
    const error = res.some((r) => r.status !== 'fulfilled');
    if (error) {
      if (!modalMessage) {
        setModalMessage({
          title: 'Oops! Something went wrong!',
          color: 'darkPink',
        });
        setTimeout(() => setModalMessage(null), 1000);
      }
    } else {
      if (!modalMessage) {
        setModalMessage({ title: 'Saved' });
        setTimeout(() => setModalMessage(null), 1000);
      }
    }
  };

  return (
    <PageContentBlock>
      <SectionHeader>
        <Typography type="b2Bold" color="darkerBlue">
          Customer account details
        </Typography>
      </SectionHeader>
      <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Status
            </Typography>
            <Typography type="smallNormal" color="darkerBlue">
              Making a customer inactive simply removes them from your chat window. If a customer comments when they are
              inactive, they will automatically be made live again. Two weeks of inactivity from both parties results in
              automatic inactivity.
            </Typography>
            <FormSwitch name="isInactive" labelOn="Active" labelOff="Inactive" />
          </Section>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Account created
            </Typography>
            <Typography type="b3Normal" color="darkerBlue">
              {moment(account.createdAt).format('DD-MM-YY')}
            </Typography>
          </Section>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Salesforce account
            </Typography>
            <FormTextInputFilled
              name="salesforceURL"
              label="Salesforce account"
              placeholder="Paste link here..."
              style={{ marginTop: '12px' }}
            />
          </Section>
          <AdvisorSection openAdvisorModal={() => setAdvisorModal(true)} openBlockModal={() => setBlockModal(true)} />
          <ButtonSection {...initialValues} loading={isLoadingAdvisor || isLoadingAccount} />
          <AdvisorModal
            open={advisorModal}
            closeModal={() => setAdvisorModal(false)}
            name="advisorUid"
            customer={customer}
          />
          <BlockModal
            open={blockModal}
            close={() => setBlockModal(false)}
            customer={customer}
            setModalMessage={setModalMessage}
          />
        </Form>
      </Formik>
      <Modal
        open={!!modalMessage}
        handleClose={() => setModalMessage(null)}
        title={modalMessage?.title}
        titleColor={modalMessage?.color}
        position="top"
      />
    </PageContentBlock>
  );
};
