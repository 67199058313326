import React from 'react';
import { useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowBack } from '../../assets/icons/ArrowBack';
import { ArrowForward } from '../../assets/icons/ArrowForward';
import { Avatar } from '../../components/Avatar';
import { Button, TertiaryButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { useIsMobileView } from '../../components/MobileView';
import { Typography } from '../../components/Typography';
import { ApiService, CustomError } from '../../services/api';
import { Customer } from '../../types/customer';
import { HeaderPageContainer, LeftWrapper, PageContentBlock, Section, SectionHeader } from './PageStyleComponents';
import { CustomerDetails } from './CustomerDetails';
import { SingleSwitch } from '../../components/SwitchButton';

export const CustomerPage = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const history = useHistory();
  const isMobile = useIsMobileView();
  const { data, isLoading, error } = useQuery<Customer, CustomError>(
    ['customer', customerId],
    ApiService.get(`/v1/customer/${customerId}`),
    {}
  );

  if (isLoading) {
    return (
      <div style={{ height: '100%', width: '100%', display: 'flex' }}>
        <Loading />
      </div>
    );
  }
  if (error || !data) {
    return <div>{error && error.message} </div>;
  }

  const { account, customer, advisor } = data;
  const { firstName, lastName } = customer;

  const SalesforceButtonText = account.salesforceURL ? 'Open Salesforce' : 'Salesforce account not linked';
  const SalesforceButtonIcon = account.salesforceURL ? <ArrowForward style={{ marginLeft: '10px' }} /> : undefined;
  const customerInitials = `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`;
  const customerName = `${firstName} ${lastName}`;

  const disabled = true;

  return (
    <>
      <HeaderPageContainer isMobile={isMobile}>
        <LeftWrapper isMobile={isMobile}>
          <TertiaryButton
            onClick={() => history.goBack()}
            type="b3Bold"
            iconBefore={<ArrowBack style={{ fontSize: '16px', marginRight: '7px' }} />}
          >
            Back
          </TertiaryButton>
          <Avatar color="white" backgroundColor="darkBlue" size="b3Bold" style={{ margin: '0 12px 0 22px' }}>
            {customerInitials}
          </Avatar>
          <Typography type="b2Bold" color="darkerBlue">{`${customerName} • Customer`}</Typography>
        </LeftWrapper>
        <Button
          styleType="primary"
          disabled={!account.salesforceURL}
          onClick={() => {
            const win = window.open(account.salesforceURL as string, '_blank');
            win && win.focus();
          }}
          iconAfter={SalesforceButtonIcon}
        >
          {SalesforceButtonText}
        </Button>
      </HeaderPageContainer>
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
        <CustomerDetails account={account} advisor={advisor} customer={customer} />
        <PageContentBlock disabled={disabled}>
          <SectionHeader>
            <Typography type="b2Bold" color="darkerBlue">
              Customers contact details
            </Typography>
            {disabled && (
              <Typography type="b3Normal" color="darkerBlue" style={{ marginTop: '6px' }}>
                You can’t edit these settings.
              </Typography>
            )}
          </SectionHeader>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Name
            </Typography>
            <Typography type="b3Normal" color="darkerBlue">
              {`${customer.firstName} ${customer.lastName}`}
            </Typography>
          </Section>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Address
            </Typography>
            <Typography type="b3Normal" color="darkerBlue">
              {`${account.street}, ${account.city}, ${account.postalCode}`}
            </Typography>
          </Section>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Phone Number
            </Typography>
            <Typography type="b3Normal" color="darkerBlue">
              {customer.phone}
            </Typography>
          </Section>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Contact & Account email
            </Typography>
            <Typography type="b3Normal" color="darkerBlue">
              {customer.email}
            </Typography>
          </Section>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Partner Referral
            </Typography>
            <Typography type="b3Normal" color="darkerBlue">
              -
            </Typography>
          </Section>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Situation
            </Typography>
            {account.situation.map((item, i) => (
              <Typography style={{ textTransform: 'capitalize' }} type="b3Normal" color="darkerBlue">
                {item}
              </Typography>
            ))}
          </Section>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Worries
            </Typography>
            {account.worryAbout.map((item, i) => (
              <Typography style={{ textTransform: 'capitalize' }} type="b3Normal" color="darkerBlue">
                {item}
              </Typography>
            ))}
          </Section>
        </PageContentBlock>
        <PageContentBlock disabled={disabled}>
          <SectionHeader>
            <Typography type="b2Bold" color="darkerBlue">
              Customers notification settings
            </Typography>
            {disabled && (
              <Typography type="b3Normal" color="darkerBlue" style={{ marginTop: '6px' }}>
                You can’t edit these settings.
              </Typography>
            )}
          </SectionHeader>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Allow notifications:
            </Typography>
            <SingleSwitch
              name="isInactive"
              labelOn="Yes"
              labelOff="No"
              disabled={true}
              value={!customer.notificationPreference?.allow}
            />
          </Section>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              I want to receive notifications:
            </Typography>
            <Typography type="b3Normal" color="darkerBlue">
              Choose to receive notifications when your get them ‘instantly’ or get them as a ‘daily’ round up.
            </Typography>
            <SingleSwitch
              name="pref"
              labelOn="Instantly"
              labelOff="Daily"
              disabled={true}
              value={customer.notificationPreference?.type === 'DAILY'}
            />
          </Section>
          <Section>
            <Typography type="b3Bold" color="darkerBlue">
              Allow contact by phone:
            </Typography>
            <Typography type="b3Normal" color="darkerBlue">
              Allow us to contact you by phone, if we don’t hear back within 3 days
            </Typography>
            <SingleSwitch
              name="pref"
              labelOn="Yes"
              labelOff="No"
              disabled={true}
              value={!customer.notificationPreference?.phone}
            />
          </Section>
        </PageContentBlock>
      </div>
    </>
  );
};
