import React from 'react';

import styled from 'styled-components';
import { Typography } from '../../components/Typography';

const Wrapper = styled.div`
  height: 100vh;
  background-color: #00446d;
  width: 100%;
  text-align: center;
`;

export const BlockedUser = () => {
  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography type="h2" color="white">
          Account Blocked
        </Typography>
        <Typography type="h3" color="white" style={{ marginTop: '48px' }}>
        Due to recent activity on IncomeMax messenger, you are no longer able to access this service.
        </Typography>
      </div>
    </Wrapper>
  );
};
