import React from 'react';
import { Backdrop, Button, CircularProgress, Container, TextField, Tooltip } from '@material-ui/core';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';

interface Props {
  step: number;
  formData: any;
  setFormData: any;
  submit: any;
  onboarding: boolean;
}

const validationSchema = yup.object({
  email: yup.string().required('Email is required').email('Invalid email'),
  emailConfirmation: yup.string().test('email-match', 'Emails must match', function (value: any) {
    return this.parent.email === value;
  }),
  phoneNumber: yup.number().required('Phone number is mandatory'),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Password must have at least one uppercase character, one numerical character and be at least 8 characters long'
    )
    .required('Password is required'),
  passwordConfirmation: yup.string().test('passwords-match', 'Passwords must match', function (value: any) {
    return this.parent.password === value;
  }),
});

const OnboardingStep6: React.FC<Props> = ({ step, formData, setFormData, submit, onboarding }) => {
  
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const renderSpinner = () => {
    return;
  };

  if (step !== 6) return null;

  return (
    <Container>
      <h1 className="title">Create your account</h1>
      <Formik
        initialValues={formData}
        onSubmit={(values) => {
          submit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ errors, touched, values, setFieldValue, handleChange }) => (
          <>
            <Form>
              <div className="create-acc">
                <Field
                  id="1"
                  onChange={handleChange}
                  label="Email"
                  name="email"
                  variant="filled"
                  as={TextField}
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                />
                <Field
                  id="2"
                  name="emailConfirmation"
                  variant="filled"
                  onChange={handleChange}
                  as={TextField}
                  label="Repeat email address"
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                  error={touched.emailConfirmation && errors.emailConfirmation}
                  helperText={touched.emailConfirmation && errors.emailConfirmation}
                />
                <Field
                  variant="filled"
                  label="Phone"
                  onChange={handleChange}
                  name="phoneNumber"
                  as={TextField}
                  error={touched.phoneNumber && errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                />
                <Tooltip
                  style={{ backgroundColor: '#ffff', color: 'black', zIndex: 100 }}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="top"
                  arrow
                  onClose={handleTooltipClose}
                  open={open}
                  title="Add"
                >
                  <Field
                    variant="filled"
                    type="password"
                    label="Password"
                    id="3"
                    onChange={handleChange}
                    name="password"
                    as={TextField}
                    error={touched.password && errors.password}
                    helperText={touched.password && errors.password}
                    style={{ pointerEvents: 'auto' }}
                    inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autoComplete: 'off',
                      },
                    }}
                  />
                </Tooltip>

                <Field
                  id="4"
                  variant="filled"
                  type="password"
                  label="Confirm Password"
                  onChange={handleChange}
                  name="passwordConfirmation"
                  as={TextField}
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                  error={touched.passwordConfirmation && errors.passwordConfirmation}
                  helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                />
                <div>
                  <label>
                    <Field type="checkbox" name="privacyOptIn" />
                    <span style={{ fontSize: 16 }}>I have read the <a href="https://incomemax.org.uk/privacy-policy/" style={{ fontSize: '16px', color: "#00446D", textDecoration: "underline" }} target="_blank">Privacy Policy</a>.</span>
                  </label>
                </div>
                <div>
                  <label>
                    <Field type="checkbox" name="termsOptIn" />
                    <span style={{ fontSize: 16 }}>I agree to the <a href="https://incomemax.org.uk/terms-and-conditions/" style={{ fontSize: '16px', color: "#00446D", textDecoration: "underline" }} target="_blank">Terms and Conditions</a>.</span>
                  </label>
                </div>
                {/* <p>*Password must have at least one upper case, at least one number and at least one special character</p> */}
              </div>
              <div className="button">
                <Button disabled={!values.privacyOptIn || !values.termsOptIn} variant="contained" color="primary" type="submit">
                  Create account
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
      <Backdrop style={{ color: '#00446D', zIndex: 999 }} open={onboarding}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Container>
  );
};

export default OnboardingStep6;
