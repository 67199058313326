import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';
import { Theme } from '../../constants/theme';
import { Typography } from '../Typography';

const PaginationContainer = styled.ul<{ fullWidth?: boolean }>`
  display: flex;
  height: min-content;
  justify-content: center;
  align-items: center;
  margin: 12px;
  padding: 0;
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
`;

const PaginationItem = styled.li<{ disabled?: boolean; selected?: boolean; backgroundFill?: boolean }>`
  height: 20px;
  width: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  ${({ backgroundFill }) => (backgroundFill ? `background-color: ${Theme.colors.darkBlue};` : '')}
  ${({ disabled, selected }) =>
    disabled
      ? `
  pointer-events: none;
  cursor: default;
  `
      : selected
      ? `
  background-color: ${Theme.colors.darkBlue};
  color: ${Theme.colors.white}
  pointer-events: none;
  cursor: default;
  `
      : `
  cursor: pointer;
  `};
`;

const ListRow = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Theme.colors.grey3};
  margin: 0 12px;
  border-radius: 16px;
  padding: 0;
  ${({ fullWidth }) => (fullWidth ? 'flex: 1;' : 'width: 186px;')};
`;

const chevronStyle = {
  fontSize: '10px',
  color: Theme.colors.white,
};

const range = (start: number, end: number) => {
  const length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

interface UsePaginationProps {
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
}
const DOTS = 'DOTS';
export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }: UsePaginationProps) => {
  return useMemo<Array<string | number>>(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;
    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }
    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);
    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;
    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);
      return [...leftRange, DOTS, totalPageCount];
    }
    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }
    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    return [];
  }, [totalCount, pageSize, siblingCount, currentPage]);
};

interface PaginationControlProps {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  fullWidth?: boolean;
}

export const PaginationControl: FunctionComponent<PaginationControlProps> = (props) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, fullWidth } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <PaginationContainer fullWidth={fullWidth}>
      <PaginationItem onClick={onPrevious} disabled={currentPage === 1} backgroundFill>
        <ChevronLeft style={chevronStyle} />
      </PaginationItem>
      <ListRow fullWidth={fullWidth}>
        {paginationRange.map((pageNumber, i) => {
          if (pageNumber === DOTS) {
            return (
              <PaginationItem key={i} disabled={true}>
                <Typography type="smallNormal" color="black">
                  &#8230;
                </Typography>
              </PaginationItem>
            );
          }
          return (
            <PaginationItem
              key={i}
              selected={pageNumber === currentPage}
              onClick={() => onPageChange(pageNumber as number)}
            >
              <Typography type="smallNormal" color={pageNumber === currentPage ? 'white' : 'black'}>
                {pageNumber}
              </Typography>
            </PaginationItem>
          );
        })}
      </ListRow>
      <PaginationItem disabled={currentPage === lastPage} onClick={onNext} backgroundFill>
        <ChevronRight style={chevronStyle} />
      </PaginationItem>
    </PaginationContainer>
  );
};
