import React, { useState } from 'react';
import { Button } from '../../../components/Button';
import { useIsMobileView } from '../../../components/MobileView';
import { Typography } from '../../../components/Typography';
import { AdvisorModal } from '../../Customer/AdvisorModal';
import { PageContentBlock, Section, SectionHeader } from '../../Customer/PageStyleComponents';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { ApiService, CustomError } from '../../../services/api';
import { User } from '../../../types/user';
import { queryClient } from '../../../App';

export const ReallocateCustomers = ({ user }: any) => {
  const isMobile = useIsMobileView();
  const [isOpenModal, setOpenModal] = useState(false);
  return (
    <PageContentBlock
      fullWidth
      bRadius
      style={isMobile ? { marginTop: '16px', marginBottom: '16px' } : { margin: '24px 12px' }}
    >
      <SectionHeader>
        <Typography type="b2Bold" color="darkerBlue">
          Customer Re-allocation
        </Typography>
      </SectionHeader>
      <Section>
        <Typography type="b3Normal" color="darkerBlue">
          Allocate all your customers to another advisor.
        </Typography>
      </Section>
      <Section row={!isMobile}>
        <Button onClick={() => setOpenModal(true)}>Re-allocate</Button>
      </Section>
      <AdvisorReallocateModal closeModal={() => setOpenModal(false)} isOpen={isOpenModal} user={user} />
    </PageContentBlock>
  );
};

const AdvisorReallocateModal = ({ closeModal, user, isOpen }: any) => {
  const { mutate, isLoading } = useMutation<
    User,
    CustomError,
    { data: { oldAdvisorUid: string; newAdvisorUid: string } }
  >('reallocate-advisor', ApiService.post('/v1/user/advisor/reallocate'), {
    onSuccess: () => {
      queryClient.invalidateQueries('advisorsModalAllocation');
      closeModal();
    },
  });

  const onSubmit = ({ newAdvisorUid }: any) => {
    if (user.uid === newAdvisorUid) {
      closeModal();
    } else {
      mutate({ data: { oldAdvisorUid: user.uid, newAdvisorUid } });
    }
  };
  return (
    <Formik initialValues={{ newAdvisorUid: user.uid }} onSubmit={onSubmit}>
      <Form>
        <AdvisorModal
          open={isOpen}
          closeModal={closeModal}
          name="newAdvisorUid"
          title="Re-allocate Customers"
          submit={true}
          isLoadingSubmit={isLoading}
        />
      </Form>
    </Formik>
  );
};
