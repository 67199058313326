import { Button, Container, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React from "react";

interface Props {
  addressLine1: string;
  addressLine2: string;
  town: string;
  county: string;
  postcode: string;
  country: string;
  handleChange: any;
  toggleView: any;
  onAction: any;
}

const InsertAddressManually: React.FC<Props> = ({
  addressLine1,
  addressLine2,
  town,
  county,
  postcode,
  country,
  toggleView,
  onAction,
  handleChange
}) => {
  return (
    <Container>
      <h1 className="title">What's your address</h1>
      <div className="postcode-fields">
        <TextField
          label="Address line 1"
          required={true}
          variant="filled"
          value={addressLine1}
          onChange={handleChange}
        />
         <TextField
          label="Address line 2"
          required={true}
          variant="filled"
          onChange={handleChange}
          value={addressLine2}
        />
         <TextField
          label="Town/city"
          required={true}
          variant="filled"
          onChange={handleChange}
          value={town}
        />
         <TextField
          label="County"
          required={true}
          variant="filled"
          value={county}
        />
         <TextField
          label="Postcode"
          required={true}
          variant="filled"
          value={postcode}
        />
        <FormControl>
            <InputLabel>
                Country
            </InputLabel>
            <Select variant="filled" value={country} onChange={handleChange}>
                <MenuItem value="scotlant">Scotland</MenuItem>
            </Select>
        </FormControl>
        <div className="button">
        <Button variant="contained" color="primary" 
            // disabled={props.address === "" || props.status === "" || postCodeInput === "" ? true : false }
            onClick={onAction}>
          Next step
        </Button>
       
      </div>
      <Button  onClick={toggleView}>
            Back to address lookup
        </Button>  </div>
    </Container>
  );
};

export default InsertAddressManually;
