import { TableSortLabel as MUITableSortLabel } from '@material-ui/core';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { PolygonDown } from '../../assets/icons/PolygonDown';
import { TextButton } from '../../components/Button';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/Table/BaseTable';
import { PaginationControl } from '../../components/Table/PaginationControl';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { CustomerList } from '../../types/customer';
import { OrderBy } from '../../types/global';
import { RelativeDateTimeFormatter } from '../../utils';
import { UnreadCircle } from '../Customer/PageStyleComponents';

const TableSortLabel = styled(MUITableSortLabel)`
  height: 18px;
  width: 18px;
  & .MuiTableSortLabel-root {
    opacity: 1 !important;
  }
`;

export interface Header {
  label: string;
  orderByValue?: string | number;
}

interface CustomersTableProps {
  data: CustomerList;
  isFetching: boolean;
  page: number;
  header: Header[];
  rowsPerPage: number;
  handlePageChange: (page: number) => void;
  allocateAdvisor: (uid: string) => void;
  orderBy: OrderBy;
  handleChangeOrderBy: (col: string | number) => void;
}

export const CustomersTable: FunctionComponent<CustomersTableProps> = ({
  data,
  isFetching,
  page,
  rowsPerPage,
  orderBy,
  header,
  handlePageChange,
  handleChangeOrderBy,
  allocateAdvisor,
}) => {
  const heightRows = data.list.length < rowsPerPage ? data.list.length : rowsPerPage;

  return (
    <>
      <TableContainer style={{ width: '1260px', height: `${(heightRows + 1) * 50 + 13}px` }}>
        <Table>
          <TableHead>
            <TableRow>
              {header.map(({ label, orderByValue }, i) => (
                <TableCell key={i} align="left">
                  <Typography color="darkerBlue" type="smallBold" style={{ display: 'flex', alignItems: 'center' }}>
                    {label}
                    {orderByValue && (
                      <TableSortLabel
                        active={!!orderBy[orderByValue]}
                        direction={orderBy[orderByValue]}
                        IconComponent={PolygonDown}
                        onClick={() => handleChangeOrderBy(orderByValue)}
                      />
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ opacity: isFetching ? 0.5 : 1 }}>
            {data.list.map((i, index: number) => {
              const { account, customer, advisor } = i;
              const messages = account.advisorUnreadTotal ? (
                <>
                  <UnreadCircle type="small2Bold" style={{ marginRight: '7px' }}>
                    {account.advisorUnreadTotal}
                  </UnreadCircle>
                  unread
                </>
              ) : (
                `${account.messagesTotal} total`
              );

              return (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: (index + 1) % 2 === 0 ? Theme.colors.white : Theme.colors.grey3,
                    border: 'none',
                  }}
                >
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal">
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal">
                      {`${customer.firstName} ${customer?.lastName}`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal">
                      {customer.email}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal">
                      {moment(customer.createdAt).format('DD-MM-YY')}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    {advisor ? (
                      <Typography color="darkerBlue" type="smallBold">
                        {`${advisor.firstName} ${advisor.lastName}`}
                      </Typography>
                    ) : (
                      <TextButton color="darkerBlue" type="smallBold" onClick={() => allocateAdvisor(account.uid)}>
                        Allocate
                      </TextButton>
                    )}
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal">
                      {account.closedAt ? 'Inactive' : 'Active'}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <Typography color="darkerBlue" type="smallNormal" style={{ display: 'flex', alignItems: 'center' }}>
                      {messages}
                      {`${
                        account.lastMessageDate
                          ? ` • ${RelativeDateTimeFormatter(
                              new Date(account.lastMessageDate as unknown as number),
                              true
                            ).toLowerCase()}`
                          : ''
                      }`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <TextButton
                      color="darkerBlue"
                      type="smallNormal"
                      to={
                        account.salesforceURL
                          ? { pathname: account.salesforceURL }
                          : `/incomemax/customers/${customer.uid}`
                      }
                      target={account.salesforceURL ? '_blank' : undefined}
                    >
                      {account.salesforceURL ? 'Open' : 'Add'}
                    </TextButton>
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    <TextButton color="darkerBlue" type="smallNormal" to={`/incomemax/customers/${customer.uid}`}>
                      Manage
                    </TextButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationControl
        totalCount={data.pagination.total}
        // fullWidth={}
        siblingCount={2}
        currentPage={page}
        pageSize={rowsPerPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};
