import React from 'react';

function FilterBase(props: any) {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V2H18V0H0ZM7 12H11V10H7V12ZM15 7H3V5H15V7Z"
        fill="currentColor"
      />
    </svg>
  );
}

export const Filter = React.memo(FilterBase);
