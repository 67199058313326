import React from 'react';

function ChevronLeftBase(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 12" fill="currentColor" {...props}>
      <path d="M7.705 1.41L6.295 0l-6 6 6 6 1.41-1.41L3.125 6l4.58-4.59z" fill="currentColor" />
    </svg>
  );
}

export const ChevronLeft = React.memo(ChevronLeftBase);
