import React, { useState } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Redirect, Link, useHistory } from 'react-router-dom';
import SimpleAlerts from '../../components/Alert';
import { useMutation } from 'react-query';
import { ApiService, CustomError } from '../../services/api';
import type { LoginResponse, LoginRequest } from '../../types/login';
import { queryClient } from '../../App';
// import './LoginPage.style.css';
import styled from 'styled-components';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';

const Wrapper = styled.div`
  height: 100vh;
  background-color: #00446d;
  width: 100%;
  text-align: center;
`;

export const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const { mutate, isLoading, data } = useMutation<LoginResponse, CustomError, { data: LoginRequest }, any>(
    ApiService.post('/v1/login'),
    {
      onError: (error) => {
        if (error.name === 'UserNotConfirmedException') {
          setErrorMsg('User not confirmed. Please check your email and confirm your email before login.');
          setAlertState(true);
        } else if (error.message.includes('blocked')) {
          history.push('blocked-user');
        } else if (error.message.includes('TemporaryPassword')) {
          history.push('set-new-password', { email, password });
        } else {
          setErrorMsg(error.message);
          setAlertState(true);
        }
      },
    }
  );

  const [errorMsg, setErrorMsg] = useState('');
  const [alertState, setAlertState] = useState(false);
  const closeAlert = () => {
    setAlertState(false);
  };

  const handlePassword = (event: any) => {
    setPassword(event.target.value);
  };
  const onSubmit = (event: any) => {
    event.preventDefault();
    const formData: LoginRequest = { email, password };
    mutate({ data: formData });
  };

  if (data) {
    localStorage.setItem('user profile', JSON.stringify(data));
    localStorage.setItem('token', data.jwtToken);
    localStorage.setItem('token_exp', `${data.payload.exp}`);
    queryClient.clear();
    return <Redirect to="/incomemax" />;
  }

  return (
    <Wrapper>
      <SimpleAlerts openAlert={alertState} closeAlert={closeAlert} message={errorMsg} />
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography color="white" type="b3Bold">
          IncomeMax Messenger
        </Typography>
        <Typography
          color="white"
          type="h1"
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
          }}
        >
          Sign in
        </Typography>
        <div
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            type="b2Normal"
            color="white"
            style={{ textAlign: 'start', marginLeft: '18px', marginTop: '24px' }}
          >
            Email
          </Typography>
          <input
            type="email"
            aria-label="email"
            value={email}
            onChange={(event: any) => setEmail(event.target.value)}
            style={{
              border: '1px solid white',
              borderRadius: '4px',
              backgroundColor: 'transparent',
              maxWidth: '300px',
              width: '100%',
              alignSelf: 'center',
              color: 'white',
              padding: '12px',
              verticalAlign: 'middle',
            }}
          />

          <Typography
            type="b2Normal"
            color="white"
            style={{ textAlign: 'start', marginLeft: '18px', marginTop: '24px' }}
          >
            Password
          </Typography>
          <input
            value={password}
            onChange={handlePassword}
            style={{
              border: '1px solid white',
              borderRadius: '4px',
              backgroundColor: 'transparent',
              maxWidth: '300px',
              width: '100%',
              alignSelf: 'center',
              color: 'white',
              padding: '12px',
            }}
            type="password"
          />

          <Button
            styleType="secondary"
            style={{
              // backgroundColor: '#fff',
              // color: '#00446D',
              width: '250px',
              alignSelf: 'center',
              height: '50px',
              marginTop: '48px',
            }}
            onClick={onSubmit}
          >
            Sign in
          </Button>

          <p style={{ marginTop: '4px', color: '#fff' }}>
            <Link style={{ textDecoration: 'underline', color: 'white', fontSize: '14px' }} to="/auth/forgot-password">
              Forgot your password
            </Link>
          </p>
          <p style={{ marginTop: '20px', color: '#fff' }}>
            {`Don't have an account?`}
            &nbsp;
            <Link style={{ textDecoration: 'underline' }} to="/auth/onboarding">
              Sign up.
            </Link>
          </p>
        </div>
        <Backdrop style={{ color: '#fff', zIndex: 999 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </Wrapper>
  );
};
