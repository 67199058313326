import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '../../components/Avatar';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { Conversation } from '../../types/conversation';
import { RelativeDateTimeFormatter } from '../../utils';
import { UnreadCircle } from '../Customer/PageStyleComponents';

export const ConversationItem = ({
  conversation,
  selectedConversation,
  onClick,
}: {
  conversation: Conversation;
  selectedConversation?: Conversation | null;
  onClick: any;
}) => {
  const {
    customer: { firstName, lastName },
    account,
  } = conversation;
  const customerInitials = `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`.toUpperCase();
  const isSelected = selectedConversation?.account.uid === account.uid;
  const dateTime = account.lastMessageDate ? new Date(account.lastMessageDate) : new Date(account.createdAt);

  return (
    <Link
      to={`/incomemax/chat/${account.uid}`}
      style={{
        height: '64px',
        width: '100%',
        cursor: 'pointer',
        backgroundColor: isSelected ? Theme.colors.grey3 : 'transparent',
      }}
      onClick={onClick}
    >
      <div
        style={{
          borderLeft: isSelected ? `5px solid ${Theme.colors.yellow}` : 'none',
          padding: isSelected ? '12px 24px 12px 19px' : '12px 24px',
          display: 'flex',
        }}
      >
        <Avatar color="black" size="smallBold" backgroundColor="blue">
          {customerInitials}
        </Avatar>
        <div
          style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', marginLeft: '8px' }}
        >
          <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography
              color="darkerBlue"
              type={isSelected ? 'b3Bold' : 'b3Normal'}
            >{`${firstName} ${lastName}`}</Typography>
            <Typography color="darkerBlue" type="smallBold">
              {RelativeDateTimeFormatter(dateTime)}
            </Typography>
          </div>
          <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography
              color="darkerBlue"
              type="smallNormal"
              style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden', maxWidth: '250px' }}
            >
              {account.lastMessage ?? null}
            </Typography>
            {account.advisorUnreadTotal ? (
              <UnreadCircle type="small2Bold" style={{ height: '14px', width: '14px' }}>
                {account.advisorUnreadTotal}
              </UnreadCircle>
            ) : null}
          </div>
        </div>
      </div>
    </Link>
  );
};
