import { Modal, Paper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Dialog = styled(Paper)`
  max-width: 500px;
  padding: 24px;
  font-family: Raleway !important;
  border-radius: 4px;
  font-size: 1.2rem;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 16px;
  padding: 18px 0;
`;

const Message = styled.div`
  font-size: 18px;
  border: 1px solid black;
  padding: 12px;
  border-radius: 4px;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 24px 0 24px;
`;

const Btn = styled.button<{ underline?: boolean }>`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  ${({ underline }) => (underline ? 'text-decoration-line: underline;' : '')}
  color: #001e3c;
  padding-left: 4px;
  cursor: pointer;
  border: none;
  background: transparent;
  height: auto;
  flex: none;
`;

export const DeleteMessageModal = ({ message, open, onClose, deleteMessage }: any) => {
  return (
    <Modal style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} open={open} onClose={onClose}>
      <Dialog>
        <Title>Delete message</Title>
        <Description>Are you sure you want to delete this message? This cannot be undone.</Description>
        <Message>{message?.message}</Message>
        <FlexEnd>
          <Btn style={{ paddingRight: '16px' }} onClick={onClose}>
            Cancel
          </Btn>

          <Btn underline style={{ paddingLeft: '16px' }} onClick={deleteMessage}>
            Delete
          </Btn>
        </FlexEnd>
      </Dialog>
    </Modal>
  );
};
