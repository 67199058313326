import { useQuery } from 'react-query';
import { User } from '../types/user';
import { ApiService } from '../services/api';

export const useGetUserInfo = () => {
  const { data, isLoading, error } = useQuery<User & { permissions: string[] }>(
    'userInfo',
    ApiService.get('/v1/user/me'),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  return { user: data as User & { permissions: string[] }, isLoading, error };
};

export const useIsCustomer = () => {
  const { user } = useGetUserInfo();
  return !!user?.permissions.includes('APP_CUSTOMER');
};

export const useIsAdmin = () => {
  const { user } = useGetUserInfo();
  return !!user?.permissions.includes('APP_ADMIN');
};
