import React from 'react';
import styled from 'styled-components';
import {
  Table as MUITable,
  TableProps,
  TableCell as MUITableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';

import { Theme } from '../../constants/theme';

export const Table = styled(({ striped, ...rest }: { striped?: boolean } & TableProps) => <MUITable {...rest} />)`
  &:tr {
    border: none;
    background-color: ${Theme.colors.white};
  }
  &:tbody {
    &tr::nth-child(odd) {
      background-color: ${Theme.colors.grey3};
    }
  }
`;

export const TableCell = styled(MUITableCell)`
  border: none;
`;

// exporting all from one place
export { TableContainer, TableHead, TableRow, TableBody };
