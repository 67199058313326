import { TextareaAutosize } from '@material-ui/core';
import styled from 'styled-components';
import { Theme } from '../constants/theme';

export const MessageInput = styled(TextareaAutosize)`
  ${Theme.typography.large.b3Normal}
  color: ${Theme.colors.black};
  border-radius: 4px;
  padding: 9px 14px;
  font-size: 16px;
`;
