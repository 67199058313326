import React from 'react';

function SendBase(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 10l.01-7 20.99 9-20.99 9-.01-7 15-2-15-2zm2.01-3.97l7.51 3.22-7.52-1 .01-2.22zm7.5 8.72L3.5 17.97v-2.22l7.51-1z"
        fill="black"
      />
    </svg>
  );
}

export const Send = React.memo(SendBase);
