import React from 'react';
import { EventAvailable, MailOutlined, Settings } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { NavButton, NavText } from '../../../components/NavButton';

const Container = styled.footer`
  background-color: #00446d;
  position: static;
  display: flex;
  flex-direction: row;
  height: 80px;
`;

export const NavFooter = ({ me }: any) => {
  const location = useLocation();
  const isAdvisor = me.permissions?.includes('APP_ADVISOR');
  return (
    <Container>
      {isAdvisor && (
        <NavButton isActive={location.pathname.includes('customers')} col to={'/incomemax/customers'}>
          <EventAvailable />
          <NavText col isActive={location.pathname.includes('customers')}>
            Customers
          </NavText>
        </NavButton>
      )}

      <NavButton isActive={location.pathname.includes('chat')} col to={'/incomemax/chat'}>
        <MailOutlined />
        <NavText col isActive={location.pathname.includes('chat')}>
          Chat
        </NavText>
      </NavButton>

      <NavButton col isActive={location.pathname.includes('settings')} to={'/incomemax/settings'}>
        <Settings />
        <NavText col isActive={location.pathname.includes('settings')}>
          Settings
        </NavText>
      </NavButton>
    </Container>
  );
};
