import { Input } from '@material-ui/core';
import styled from 'styled-components';
import { Theme } from '../constants/theme';

export const TextInput = styled(Input)`
  height: 40px;
  background-color: ${Theme.colors.grey3};
  border-radius: 100px;
  color: ${Theme.colors.darkerBlue};
  ${Theme.typography.large.smallNormal}
  border: none;
  padding: 0px 15px;

  &::before {
    border: none !important;
  }
  &::after {
    border: none !important;
  }
  &:hover {
    border: none !important;
  }
  &.MuiInput-underline:before {
    content: none !important;
  }
  &.MuiInput-underline:after {
    content: none !important;
  }
  & > .MuiInputBase-input {
    padding: 4px 4px;
    height: auto;
  }
`;
