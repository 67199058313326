import React from 'react';
import { Container, InputLabel, FormControl, Select, TextField, Button, MenuItem } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
interface Props {
  step: number;
  onAction: any;
  handleChangeElectricity: any;
  electricity: any;
  handleChangeElectricityManual: any;
  handleChangeGas: any;
  gas: any;
  gasNew: any;
  electricityNew: any;
  handleChangeGasManual: any;
}

const OnboardingStep5: React.FC<Props> = ({
  step,
  onAction,
  handleChangeElectricity,
  electricity,
  handleChangeElectricityManual,
  handleChangeGas,
  gasNew,
  handleChangeGasManual,
  gas,
  electricityNew,
}) => {
  if (step !== 5) return null;
  return (
    <Container>
      <h1 className="title">Who supplies your gas and electricity?</h1>

      <div className="gas-electricity-dropdown">
        <FormControl variant="filled" className="select">
          <InputLabel>Electricity Supplier</InputLabel>
          <Select variant="filled" value={electricity} onChange={handleChangeElectricity}>
            <MenuItem value={'Other'}>Other</MenuItem>
            <MenuItem value={'Avro Energy'}>Avro Energy</MenuItem>
            <MenuItem value={'Boost'}>Boost</MenuItem>
            <MenuItem value={'Boost'}>Boost</MenuItem>
            <MenuItem value={'Bulb'}>Bulb</MenuItem>
            <MenuItem value={'Bristol Energy'}>Bristol Energy</MenuItem>
            <MenuItem value={'British Gas'}>British Gas</MenuItem>
            <MenuItem value={'E'}>E</MenuItem>
            <MenuItem value={'E. ON'}>E. ON</MenuItem>
            <MenuItem value={'Ecotricity'}>Ecotricity</MenuItem>
            <MenuItem value={'EDF Energy'}>EDF Energy</MenuItem>
            <MenuItem value={'Green Energy UK'}>Green Energy UK</MenuItem>
            <MenuItem value={'Green Network Energy'}>Green Network Energy</MenuItem>
            <MenuItem value={'Npower'}>Npower</MenuItem>
            <MenuItem value={'Octopus Energy'}>Octopus Energy</MenuItem>
            <MenuItem value={'Outfox the Market'}>Outfox the Market</MenuItem>
            <MenuItem value={'Ovo'}>Ovo</MenuItem>
            <MenuItem value={"People's Energy"}>People's Energy</MenuItem>
            <MenuItem value={'Pure Planet'}>Pure Planet</MenuItem>
            <MenuItem value={"Sainsbury's Energy"}>Sainsbury's Energy</MenuItem>
            <MenuItem value={'Scottish Power'}>Scottish Power</MenuItem>
            <MenuItem value={'Shell Energy'}>Shell Energy</MenuItem>
            <MenuItem value={'So Energy'}>So Energy</MenuItem>
            <MenuItem value={'SSE'}>SSE</MenuItem>
            <MenuItem value={'Utilita'}>Utilita</MenuItem>
            <MenuItem value={'Utility Point'}>Utility Point</MenuItem>
            <MenuItem value={'Utility Warehouse'}>Utility Warehouse</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="filled" className="select">
          <InputLabel>Gas Supplier</InputLabel>
          <Select variant="filled" value={gas} onChange={handleChangeGas}>
            <MenuItem value={'Other'}>Other</MenuItem>
            <MenuItem value={'Avro Energy'}>Avro Energy</MenuItem>
            <MenuItem value={'Boost'}>Boost</MenuItem>
            <MenuItem value={'Boost'}>Boost</MenuItem>
            <MenuItem value={'Bulb'}>Bulb</MenuItem>
            <MenuItem value={'Bristol Energy'}>Bristol Energy</MenuItem>
            <MenuItem value={'British Gas'}>British Gas</MenuItem>
            <MenuItem value={'E'}>E</MenuItem>
            <MenuItem value={'E. ON'}>E. ON</MenuItem>
            <MenuItem value={'Ecotricity'}>Ecotricity</MenuItem>
            <MenuItem value={'EDF Energy'}>EDF Energy</MenuItem>
            <MenuItem value={'Green Energy UK'}>Green Energy UK</MenuItem>
            <MenuItem value={'Green Network Energy'}>Green Network Energy</MenuItem>
            <MenuItem value={'Npower'}>Npower</MenuItem>
            <MenuItem value={'Octopus Energy'}>Octopus Energy</MenuItem>
            <MenuItem value={'Outfox the Market'}>Outfox the Market</MenuItem>
            <MenuItem value={'Ovo'}>Ovo</MenuItem>
            <MenuItem value={"People's Energy"}>People's Energy</MenuItem>
            <MenuItem value={'Pure Planet'}>Pure Planet</MenuItem>
            <MenuItem value={"Sainsbury's Energy"}>Sainsbury's Energy</MenuItem>
            <MenuItem value={'Scottish Power'}>Scottish Power</MenuItem>
            <MenuItem value={'Shell Energy'}>Shell Energy</MenuItem>
            <MenuItem value={'So Energy'}>So Energy</MenuItem>
            <MenuItem value={'SSE'}>SSE</MenuItem>
            <MenuItem value={'Utilita'}>Utilita</MenuItem>
            <MenuItem value={'Utility Point'}>Utility Point</MenuItem>
            <MenuItem value={'Utility Warehouse'}>Utility Warehouse</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="skip">
        <p>If you don’t have this information to hand you can skip this step.</p>
        <Button variant="text" onClick={onAction}>
          Skip step <ArrowForwardIcon />{' '}
        </Button>
        <Button variant="contained" color="primary" onClick={onAction}>
          Next step
        </Button>
      </div>
    </Container>
  );
};

export default OnboardingStep5;
