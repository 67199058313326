import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { ApiService, CustomError } from '../../services/api';
interface UsePaginationQueryProps<TVariables extends object> {
  path: string;
  cacheKey: string;
  initialRowsPerPage?: number;
  initialPage?: number;
  queryParams?: TVariables;
  holdOldDataOnForceRefetch?: boolean;
}

export const usePaginationQuery = <TData extends object, TVariables extends object>({
  path,
  cacheKey,
  initialRowsPerPage,
  initialPage,
  queryParams,
  holdOldDataOnForceRefetch,
}: UsePaginationQueryProps<TVariables>) => {
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage || 10);
  const [activePage, setActivePage] = useState(initialPage || 0);
  const [page, setPage] = useState(initialPage || 0);
  const [holdOldData, setHoldOldData] = useState<TData>();

  const fetch = <K>({ pageParam = 0 }) =>
    ApiService.get(path)<K, any>({
      limit: rowsPerPage,
      offset: pageParam * rowsPerPage,
      ...queryParams,
    });

  const { isLoading, error, data, fetchNextPage, isFetching, remove, refetch } = useInfiniteQuery<TData, CustomError>(
    [cacheKey, rowsPerPage],
    fetch,
    {
      onSuccess: () => {
        setActivePage(page);
      },
      refetchOnWindowFocus: false,
    }
  );

  const getPageData = () => {
    if (!data) {
      return;
    }
    const index = data.pageParams.findIndex((p) => p === activePage);
    const pageIndex = index === -1 ? 0 : index;
    return data.pages[pageIndex];
  };

  const pageData = getPageData();

  useEffect(() => {
    if (holdOldDataOnForceRefetch && pageData) {
      setHoldOldData(pageData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  const handleChangePage = (newPage: number) => {
    // newPage arg can be from 1 to as many pages, but it starts from 1
    const pageZeroIndexed = newPage - 1;
    if (data) {
      const filterParamForPage = pageZeroIndexed === 0 ? undefined : pageZeroIndexed;
      const dataIndex = data.pageParams.findIndex((p) => p === filterParamForPage);
      setPage(pageZeroIndexed);
      if (isEmpty(data.pages[dataIndex])) {
        fetchNextPage({ pageParam: pageZeroIndexed });
      } else {
        setActivePage(pageZeroIndexed);
      }
    }
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    setRowsPerPage(parseInt(event.target.value as string, 10));
    forceRefetch();
  };

  const forceRefetch = () => {
    remove();
    setPage(0);
    refetch();
  };

  const loading = holdOldDataOnForceRefetch && holdOldData ? false : isLoading;
  const fetching = holdOldDataOnForceRefetch ? isLoading || isFetching : isFetching;
  const returnData = holdOldDataOnForceRefetch ? holdOldData : pageData;

  return {
    activePage,
    rowsPerPage,
    handleChangePage,
    isLoading: loading,
    error,
    data: returnData,
    isFetching: fetching,
    handleChangeRowsPerPage,
    forceRefetch,
  };
};
