import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../../../components/Loading';
import { useIsMobileView } from '../../../components/MobileView';
import { ApiService, CustomError } from '../../../services/api';
import { Account } from '../../../types/account';
import { ListResponse } from '../../../types/global';
import { User } from '../../../types/user';
import { PageContentBlockMargin, SettingsPageWrapper } from '../../Customer/PageStyleComponents';
import { Deactivate } from '../Deactivate';
import { ResetPassword } from '../ResetPassword';
import { CustomerAccountSettings } from './CustomerAccountSettings';
import { NotificationSettings } from './NotificationSettings';

interface CustomerSettingsViewProps {
  user: User;
}
export const CustomerSettingsView: FunctionComponent<CustomerSettingsViewProps> = ({ user }) => {
  const isMobile = useIsMobileView();

  const { data, isLoading, error } = useQuery<ListResponse<{ account: Account }>, CustomError>(
    'user-account',
    ApiService.get('/v1/account', { limit: 1, offset: 0 })
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data) {
    return <div>{error ? error.message : ''}</div>;
  }

  const account = data.list[0]?.account;
  return (
    <SettingsPageWrapper>
      <PageContentBlockMargin>
        <CustomerAccountSettings account={account} user={user} />
      </PageContentBlockMargin>
      <PageContentBlockMargin>
        <ResetPassword />
        {!isMobile && <Deactivate />}
      </PageContentBlockMargin>
      <PageContentBlockMargin>
        <NotificationSettings user={user} />
        {isMobile && <Deactivate />}
      </PageContentBlockMargin>
    </SettingsPageWrapper>
  );
};
