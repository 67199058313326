import React, { useState } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import SimpleAlerts from '../../components/Alert';
import { useMutation } from 'react-query';
import { ApiService, CustomError } from '../../services/api';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import { FormTextInput } from '../../components/FormTextInput';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoginResponse } from '../../types/login';
import styled from 'styled-components';
import { queryClient } from '../../App';

const Wrapper = styled.div`
  height: 100vh;
  background-color: #00446d;
  width: 100%;
  text-align: center;
`;

const changePasswordSchema = Yup.object().shape({
  newPassword: Yup.string().min(8, 'must be at least 8 characters').required('required field'),
  newRPassword: Yup.string()
    .min(8, 'must be at least 8 characters')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('required field'),
});

const initialValues: any = {
  newPassword: '',
  newRPassword: '',
};

export const SetNewPassword = () => {
  const history = useHistory<{ email: string; password: string }>();
  const email = history.location.state['email'];
  const password = history.location.state['password'];

  const { mutate, isLoading, data } = useMutation<LoginResponse, CustomError, { data: any }, any>(
    ApiService.post('/v1/login'),
    {
      onError: (error) => {
        if (error.name === 'UserNotConfirmedException') {
          setErrorMsg('User not confirmed. Please check your email and confirm your email before login.');
        } else {
          setErrorMsg(error.message);
        }
        setAlertState(true);
      },
      onSuccess: (data) => {
        localStorage.setItem('user profile', JSON.stringify(data));
        localStorage.setItem('token', data.jwtToken);
        localStorage.setItem('token_exp', `${data.payload.exp}`);
        queryClient.clear();
        setTimeout(() => {
          history.push('/incomemax');
        }, 1000);
      },
    }
  );

  const [errorMsg, setErrorMsg] = useState('');
  const [alertState, setAlertState] = useState(false);

  const closeAlert = () => {
    setAlertState(false);
  };

  const onSubmit = (values: any) => {
    const { newPassword } = values;

    const formData = { email, password, newPassword };
    mutate({ data: formData });
  };

  if (!email || !password) {
    return <Redirect to={'/auth/login'} />;
  }

  return (
    <Wrapper>
      {data ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            color="white"
            type="b2Normal"
            style={{
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '50%',
            }}
          >
            Your password has been updated.
          </Typography>
        </div>
      ) : (
        <>
          <SimpleAlerts openAlert={alertState} closeAlert={closeAlert} message={errorMsg} />
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={changePasswordSchema}>
              <Form>
                <Typography color="white" type="b3Bold">
                  IncomeMax Messenger
                </Typography>

                <Typography color="white" type="h2">
                  Set new password
                </Typography>
                <div
                  style={{
                    textAlign: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography type="b2Normal" color="white" style={{ textAlign: 'start', marginTop: '24px' }}>
                    New password
                  </Typography>
                  <FormTextInput
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    aria-label="email"
                    style={{
                      border: '1px solid white',
                      borderRadius: '4px',
                      backgroundColor: 'transparent',
                      width: '100%',
                      alignSelf: 'center',
                      color: 'white',
                      padding: '12px',
                      verticalAlign: 'middle',
                    }}
                  />
                  <Typography type="b2Normal" color="white" style={{ textAlign: 'start', marginTop: '24px' }}>
                    Confirm new password
                  </Typography>

                  <FormTextInput
                    id="newRPassword"
                    name="newRPassword"
                    type="password"
                    aria-label="email"
                    style={{
                      border: '1px solid white',
                      borderRadius: '4px',
                      backgroundColor: 'transparent',
                      width: '100%',
                      alignSelf: 'center',
                      color: 'white',
                      padding: '12px',
                      verticalAlign: 'middle',
                    }}
                  />
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button
                      styleType="secondary"
                      style={{
                        // backgroundColor: '#fff',
                        // color: '#00446D',
                        border: '1px solid white',
                        alignSelf: 'center',
                        height: '50px',
                        marginTop: '48px',
                      }}
                      type="submit"
                    >
                      Continue
                    </Button>
                    <Button
                      type="button"
                      styleType="primary"
                      style={{
                        // backgroundColor: '#fff',
                        // color: '#00446D',
                        border: '1px solid white',

                        alignSelf: 'center',
                        height: '50px',
                        marginTop: '48px',
                      }}
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            </Formik>
            <Backdrop style={{ color: '#fff', zIndex: 999 }} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </>
      )}
    </Wrapper>
  );
};
