import React, { FunctionComponent, useState } from 'react';
import { Button } from '../../../components/Button';
import { useIsMobileView } from '../../../components/MobileView';
import { Typography } from '../../../components/Typography';
import { Account } from '../../../types/account';
import { User } from '../../../types/user';
import { PageContentBlock, Section, SectionHeader } from '../../Customer/PageStyleComponents';
import { SettingsModal } from '../SettingsModal';

interface CustomerAccountSettingsProps {
  user: User;
  account: Account;
}
export const CustomerAccountSettings: FunctionComponent<CustomerAccountSettingsProps> = ({ user, account }) => {
  const isMobile = useIsMobileView();
  const [openModal, setOpenModal] = useState(false);
  return (
    <PageContentBlock fullWidth bRadius style={isMobile ? { marginTop: '16px' } : { margin: '12px' }}>
      <SectionHeader>
        <Typography type="b2Bold" color="darkerBlue">
          Contact Details
        </Typography>
      </SectionHeader>
      <Section>
        <Typography type="b3Bold" color="darkerBlue">
          Name
        </Typography>
        <Typography type="b3Normal" color="darkerBlue">
          {`${user.firstName} ${user.lastName}`}
        </Typography>
      </Section>
      <Section>
        <Typography type="b3Bold" color="darkerBlue">
          Address
        </Typography>
        <Typography type="b3Normal" color="darkerBlue">
          {`${account.street}, ${account.city}, ${account.postalCode}`}
        </Typography>
      </Section>
      <Section>
        <Typography type="b3Bold" color="darkerBlue">
          Phone Number
        </Typography>
        <Typography type="b3Normal" color="darkerBlue">
          {user.phone}
        </Typography>
      </Section>
      <Section>
        <Typography type="b3Bold" color="darkerBlue">
          Contact & Account email
        </Typography>
        <Typography type="b3Normal" color="darkerBlue">
          {user.email}
        </Typography>
      </Section>
      <Section row={!isMobile}>
        <Button onClick={() => setOpenModal(true)}>Edit</Button>
      </Section>
      <SettingsModal open={openModal} onClose={() => setOpenModal(false)} user={user} account={account} />
    </PageContentBlock>
  );
};
