import React, { useEffect, useRef, useState } from 'react';
import { InfiniteData } from 'react-query';
import { ReadyState } from 'react-use-websocket';
import { ListRange, Virtuoso } from 'react-virtuoso';
import { queryClient } from '../../App';
import { Loading } from '../../components/Loading';
import { Theme } from '../../constants/theme';
import { useInfiniteQuery } from '../../hooks/useInfiniteQuery';
import { useSocketClient } from '../../hooks/useSocketClient';
import { useGetUserInfo, useIsCustomer } from '../../hooks/useGetUserInfo';
import { ListResponse, SocketAction, SocketData } from '../../types/global';
import { Message, MessageBody, ReadMessage } from '../../types/message';
import { DeleteMessageModal } from './DeleteMessageModal';
import { MessageItem } from './Message';
import { MessengerTextInput } from './MessengerTextInput';
import { Typography } from '../../components/Typography';
import { useIsMobileView } from '../../components/MobileView';

const START_INDEX = 1000000;
const INITIAL_ITEM_COUNT = 100;

export const MessagesView = ({ conversation }) => {
  const [startIndex, setStartIndex] = useState(START_INDEX);
  const { account } = conversation;
  const cacheKey = ['messages', account.uid];
  const { lastJsonMessage } = useSocketClient<SocketData, any>();
  
  // listen for new messages
  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.action === SocketAction.NEW_MESSAGE) {
        const message = lastJsonMessage.data as MessageBody;
        const isForThisConversation = account.uid === message.message.accountUid;
        // add new message to this conversation
        if (isForThisConversation) {
          queryClient.setQueryData<InfiniteData<ListResponse<Message>> | undefined>(cacheKey, (allData) => {
            if (allData) {
              allData.pages[0].list.unshift(message.message);
              setStartIndex((prev) => prev + 1);
            }
            return allData;
          });
        }

        return;
      } else if (lastJsonMessage.action === SocketAction.READ_MESSAGE) {
        const readMessage = lastJsonMessage.data as ReadMessage;
        const isForThisConversation = account.uid === readMessage.accountUid;
        if (isForThisConversation) {
          queryClient.setQueryData<InfiniteData<ListResponse<Message>> | undefined>(cacheKey, (allData) => {
            if (allData) {
              allData.pages.forEach((p) => {
                return p.list.forEach((m) => {
                  if (readMessage.messageUids.includes(m.uid)) {
                    m.isNew = false;
                    return m;
                  }
                });
              });
            }
            return allData;
          });
        }
        return;
      } else if (lastJsonMessage.action === SocketAction.EDITED_MESSAGE) {
        const editedMessage = lastJsonMessage.data as Message;
        const isForThisConversation = account.uid === editedMessage.accountUid;
        if (isForThisConversation) {
          queryClient.setQueryData<InfiniteData<ListResponse<Message>> | undefined>(cacheKey, (allData) => {
            if (allData) {
              const newData = {
                pageParams: allData.pageParams,
                pages: allData.pages.map((p) => ({
                  pagination: p.pagination,
                  list: p.list.map((m) => (m.uid === editedMessage.uid ? editedMessage : m)),
                })),
              };

              return newData;
            }
            return allData;
          });
        }
        return;
      } else if (lastJsonMessage.action === SocketAction.DELETED_MESSAGE) {
        const deletedMessage = lastJsonMessage.data as Message;
        const isForThisConversation = account.uid === deletedMessage.accountUid;
        if (isForThisConversation) {
          queryClient.setQueryData<InfiniteData<ListResponse<Message>> | undefined>(cacheKey, (allData) => {
            if (allData) {
              const newData = {
                pageParams: allData.pageParams,
                pages: allData.pages.map((p) => ({
                  pagination: p.pagination,
                  list: p.list.filter((m) => m.uid !== deletedMessage.uid),
                })),
              };

              return newData;
            }
            return allData;
          });
        }
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  return <MessagesView1 conversation={conversation} cacheKey={cacheKey} startIndex={startIndex} />;
};

export const MessagesView1 = ({ conversation, cacheKey, startIndex }) => {
  const { user } = useGetUserInfo();
  const { account, customer, advisor } = conversation;
  const { readyState, sendJsonMessage } = useSocketClient<SocketData, any>();

  const canWrite = user.uid === customer.uid || user.uid === advisor?.uid;

  const { data, isLoading, error, fetchNextPage, hasNextPage, forceRefetch } = useInfiniteQuery<
    ListResponse<Message>,
    any
  >({
    path: `/v1/message/${account.uid}`,
    cacheKey,
    reverseData: true,
    limit: INITIAL_ITEM_COUNT,
    useLastItemUidAsOffset: true,
  });

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      forceRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState]);

  useEffect(() => {
    forceRefetch();
    queryClient.setQueryData('isAtBottomList', () => false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.uid]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', flex: 1, backgroundColor: Theme.colors.white }}>
        <Loading />
      </div>
    );
  }
  if (error || !data) {
    return <div>Error</div>;
  }

  const handleRangeChanged = (range: ListRange) => {
    const { endIndex } = range;
    const isAtBottom = endIndex === startIndex - 1;

    const hasUnreadMessages = data && data.some((m) => m.isNew && m.user.uid !== user.uid);
    if (isAtBottom && canWrite && hasUnreadMessages) {
      sendJsonMessage({ action: SocketAction.READ_MESSAGE, data: { accountUid: account.uid } });
    }
    const isAtBottomList = queryClient.getQueryData<boolean | undefined>('isAtBottomList');
    if (isAtBottom !== isAtBottomList) {
      queryClient.setQueryData('isAtBottomList', () => isAtBottom);
    }
  };

  const editMessage = (messageUid: string, text: string) => {
    sendJsonMessage({ action: SocketAction.EDIT_MESSAGE, data: { messageUid, text } });
  };

  const deleteMessage = (messageUid: string) => {
    sendJsonMessage({ action: SocketAction.DELETE_MESSAGE, data: { messageUid } });
  };

  return (
    <List
      user={user}
      data={data}
      hasNextPage={hasNextPage}
      startIndex={startIndex}
      fetchNextPage={fetchNextPage}
      handleRangeChanged={handleRangeChanged}
      customer={customer}
      canWrite={canWrite}
      advisor={advisor}
      editMessage={editMessage}
      deleteMessage={deleteMessage}
    />
  );
};

const List = ({
  data,
  startIndex,
  fetchNextPage,
  handleRangeChanged,
  customer,
  advisor,
  hasNextPage,
  user,
  canWrite,
  editMessage,
  deleteMessage,
}) => {
  const isCustomer = useIsCustomer();
  const [unreadMessageState, setUnreadMessageState] = useState<{ total: number; uid: string }>();
  const [editMessageTextUid, setEditMessageTextUid] = useState('');
  const [editMessageTextValue, setEditMessageTextValue] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const listRef = useRef(null);
  const unreadMessages = data.filter((m) => m.isNew && m.user.uid !== user.uid);
  const totalUnread = unreadMessages.length;
  const firstUnreadMessageUid = totalUnread ? unreadMessages[0].uid : undefined;
  const firstUnreadIndex = data.findIndex((m) => m.isNew);
  const selectedMessage = data.find((m) => m.uid === editMessageTextUid);

  const showWelcomeMessage = isCustomer && !data.find((m: Message) => m.user.uid !== user.uid);

  const lastMessageUid = data.length > 0 ? data[data.length - 1].uid : null;

  const isMobile = useIsMobileView();


  useEffect(() => {
    const isAtBottom = queryClient.getQueryData<boolean | undefined>('isAtBottomList');

    if (isAtBottom) {
      return setUnreadMessageState(undefined);
    }

    if (totalUnread) {
      return setUnreadMessageState({ total: totalUnread, uid: firstUnreadMessageUid });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  const initialTopMostItemIndex =
    firstUnreadIndex === -1 ? data.length - 1 : firstUnreadIndex <= 2 ? 0 : firstUnreadIndex - 2;

  const scrollToEnd = () => {
    (listRef?.current as any)?.scrollToIndex({ index: data.length - 1 });
  };

  const cleanEditState = () => {
    setEditMessageTextUid('');
    setEditMessageTextValue('');
  };

  const saveEditMessageText = () => {
    if (!selectedMessage) {
      return;
    }
    const newText = editMessageTextValue.trim();
    // deleting message
    if (newText === '') {
      // setDeleteModalOpen(true);
      return;
    }
    // saving without changes
    if (newText === selectedMessage.message.trim()) {
      cleanEditState();
      return;
    }
    editMessage(selectedMessage.uid, newText);
    cleanEditState();
  };

  const deleteMessageHandler = () => {
    deleteMessage(selectedMessage.uid);
    cleanEditState();
    setDeleteModalOpen(false);
  };

  const openDeleteMessageModal = () => {
    setDeleteModalOpen(true);
  };

  return (
    <>
      <div style={{ display: 'flex', flex: 1, backgroundColor: Theme.colors.white }}>
        <Virtuoso
          ref={listRef}
          style={{ width: '100%', height: '100%' }}
          data={data}
          firstItemIndex={startIndex - data.length}
          initialTopMostItemIndex={initialTopMostItemIndex}
          followOutput={'smooth'}
          rangeChanged={handleRangeChanged}
          startReached={() => {
            fetchNextPage();
          }}
          overscan={{ reverse: 100, main: 0 }}
          itemContent={(_, message) => {
            return (
              <MessageItem
                key={message.uid}
                me={user}
                messageItem={message}
                customer={customer}
                advisor={advisor}
                unreadMessages={unreadMessageState}
                editMessageTextValue={editMessageTextValue}
                editMessageTextUid={editMessageTextUid}
                setEditMessageTextValue={setEditMessageTextValue}
                setEditMessageTextUid={setEditMessageTextUid}
                saveEditMessageText={saveEditMessageText}
                openDeleteMessageModal={openDeleteMessageModal}
                isEditable={lastMessageUid === message.uid}
              />
            );
          }}
          components={{
            Header: () => {
              return hasNextPage ? (
                <div
                  style={{
                    padding: '2rem',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {<Loading />}
                </div>
              ) : (
                <div
                  style={{
                    padding: '1px',
                  }}
                >
                  {showWelcomeMessage && (
                    <div
                      style={{
                        width: '100%',
                        paddingBottom: '16px',
                        padding: '48px 0',
                      }}
                    >
                      <div
                        style={{
                          padding: 0,
                          paddingLeft: '24px',
                          paddingRight: '24px',
                        }}
                      >
                        <Typography
                          color="black"
                          type="b3Normal"
                          style={{
                            padding: isMobile ? '8px' : '12px',
                            backgroundColor: Theme.colors.grey3,
                            borderRadius: '4px',
                            minWidth: '100px',
                            marginBottom: '20px'
                          }}
                        >
                          Thank you for signing up to IncomeMax messenger. Since 2009, IncomeMax have helped our customers find over £32 million of extra income! One of our expert advisors will be allocated to your case in the next working day. In order to help you, we need to gather some details about yourself, your household and your finances.
                        </Typography>
                        <Typography
                          color="black"
                          type="b3Normal"
                          style={{
                            padding: isMobile ? '8px' : '12px',
                            backgroundColor: Theme.colors.grey3,
                            borderRadius: '4px',
                            minWidth: '100px',
                            marginBottom: '20px'
                          }}
                        >
                          If you are able to, please fill in the form <a style={{ fontSize: '16px', color: "#00446D", textDecoration: "underline" }} href="https://www.surveymonkey.co.uk/r/incomemaxvanquis" target="_blank">here</a>. This will help to speed up the process.
                        </Typography>
                        <Typography
                          color="black"
                          type="b3Normal"
                          style={{
                            padding: isMobile ? '8px' : '12px',
                            backgroundColor: Theme.colors.grey3,
                            borderRadius: '4px',
                            minWidth: '100px',
                            marginBottom: '20px'
                          }}
                        >
                          Don't worry if you're not able to, as an advisor will be with you soon.
                        </Typography>
                        <Typography
                          color="black"
                          type="b3Normal"
                          style={{
                            padding: isMobile ? '8px' : '12px',
                            backgroundColor: Theme.colors.grey3,
                            borderRadius: '4px',
                            minWidth: '100px',
                            marginBottom: '20px'
                          }}
                        >
                          Feel free to get started in the chat below in the meantime and let us know what help you need.
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              );
            },
          }}
        />
      </div>
      <MessengerTextInput canWrite={canWrite} scrollToEnd={scrollToEnd} />
      <DeleteMessageModal
        open={deleteModalOpen && selectedMessage}
        onClose={() => setDeleteModalOpen(false)}
        deleteMessage={deleteMessageHandler}
        message={selectedMessage}
      />
    </>
  );
};
