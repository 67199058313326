import React from 'react';

import { LinearProgress } from '@material-ui/core';

interface Props {
  progress: number;
}

const Progress: React.FC<Props> = ({ progress }) => {
  return (
    <div style={{ height: '10px' }}>
      <LinearProgress variant="determinate" value={progress} />
    </div>
  );
};

export default Progress;

// import React, {useState, useEffect} from 'react'
// import Stepper from '@material-ui/core/Stepper';
// // import { StepConnector } from '@material-ui/core';
// import Step from '@material-ui/core/Step';
// import StepLabel from '@material-ui/core/StepLabel';
// import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
// import styles from "./Onboarding.module.css";

// const useStyles = makeStyles((theme) => ({
//     root: {
//       width: '80%',
//       marginLeft: '13%',
//       height: '34px',
//       paddingTop: '140px',
//       position: 'absolute',
//       alignContent: "flex-end",
//       justifContent: 'flex-end',
//       border: '1px solid rgba(201, 209, 219, 0.13)',
//       '&$active': {
//         color: '#77C1B4' //todo
//       },
//       '&%completed': {
//         color: '#77C1B4'
//           }

//     },
//     button: {
//       marginRight: theme.spacing(1),
//     },
//     instructions: {
//       marginTop: theme.spacing(1),
//       marginBottom: theme.spacing(1),
//     },

//   }));

// function getSteps() {
//     return ['Your name', 'Your home', 'Your situation' ,'Your worries', 'Electricity and water suplpiers', 'Finish'];
//   }

// export default function MyStepper({activeStep}){
//     const classes = useStyles();
//     const allSteps = getSteps()
//     return(
//         <div className={classes.root}>
//            <Stepper activeStep={activeStep} alternativeLabel>
//         {allSteps.map((label) => (
//           <Step key={label}>
//             <StepLabel>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>

//         </div>
//     )
// }
