import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { PageNotFound } from '../features/main/mainPage/mainPage.component';
import { NavigationService } from '../services';
import { GlobalErrorPage } from './Error/ErrorPage';
import { PrivateRouter } from './PrivateRouter';
import { PublicRouter } from './PublicRouter';

export const MainRouter = () => {
  return (
    <Router ref={(navigatorRef: any) => NavigationService.setTopLevelNavigator(navigatorRef)}>
      <Switch>
        <Route path="/auth" component={PublicRouter} />
        <Route path="/incomemax" component={PrivateRouter} />
        <Route path="/error" component={GlobalErrorPage} />
        <Route path="/" exact render={() => <Redirect to="/incomemax" />} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};
