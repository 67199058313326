import React, { FunctionComponent } from 'react';
import { PaginationControl } from '../../components/Table/PaginationControl';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/Table/BaseTable';
import type { ListResponse } from '../../types/global';
import type { Advisor } from '../../types/advisor';
import { Radio as MUIRadio } from '@material-ui/core';
import styled from 'styled-components';

const CustomCell = styled(TableCell)`
  padding: 12px 24px;
`;

const Radio = styled(({ isSelected, ...rest }) => <MUIRadio {...rest} />)`
  color: ${({ isSelected }) => (isSelected ? Theme.colors.white : Theme.colors.darkerBlue)} !important;
  padding: 0 10px;
  & .MuiSvgIcon-root {
    font-size: 24px;
  }
`;

interface AdvisorsTableProps {
  data: ListResponse<Advisor>;
  isFetching: boolean;
  page: number;
  rowsPerPage: number;
  handlePageChange: (page: number) => void;
  setAdvisorUid: (uid: string) => void;
  selectedAdvisorUid?: string | null;
}

export const AdvisorsTable: FunctionComponent<AdvisorsTableProps> = ({
  data,
  isFetching,
  page,
  rowsPerPage,
  handlePageChange,
  setAdvisorUid,
  selectedAdvisorUid,
}) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomCell align="left">
                <Typography color="darkerBlue" type="smallBold">
                  NAME
                </Typography>
              </CustomCell>
              <CustomCell align="left">
                <Typography color="darkerBlue" type="smallBold">
                  CUSTOMERS
                </Typography>
              </CustomCell>
              <CustomCell align="left" style={{ width: '86px' }}>
                <Typography color="darkerBlue" type="smallBold">
                  ACTION
                </Typography>
              </CustomCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ opacity: isFetching ? 0.5 : 1 }}>
            {data.list.map((i, index: number) => {
              const { firstName, lastName, totalCustomers, uid } = i;
              const isSelected = uid === selectedAdvisorUid;
              const styleRow = {
                backgroundColor: (index + 1) % 2 === 0 ? Theme.colors.white : Theme.colors.grey3,
                border: 'none',
              };
              const selectedRowStyle = isSelected ? { ...styleRow, backgroundColor: Theme.colors.darkBlue } : styleRow;
              const selectedColor = isSelected ? 'white' : 'darkerBlue';
              return (
                <TableRow key={index} style={selectedRowStyle}>
                  <CustomCell align="left" component="th" scope="row">
                    <Typography color={selectedColor} type="smallNormal">
                      {`${firstName} ${lastName}`}
                    </Typography>
                  </CustomCell>
                  <CustomCell align="left" component="th" scope="row">
                    <Typography color={selectedColor} type="smallNormal">
                      {totalCustomers}
                    </Typography>
                  </CustomCell>
                  <CustomCell align="left" component="th" scope="row">
                    <Typography color={isSelected ? 'darkBlue' : 'darkerBlue'} type="smallNormal">
                      Select
                      <Radio
                        isSelected={isSelected}
                        checked={isSelected}
                        onChange={() => setAdvisorUid(uid)}
                        value="Select"
                        name="radio-buttons"
                      />
                    </Typography>
                  </CustomCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationControl
        totalCount={data.pagination.total}
        // fullWidth={}
        siblingCount={2}
        currentPage={page}
        pageSize={rowsPerPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};
