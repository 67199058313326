import { Form, Formik, useField, useFormikContext } from 'formik';
import { isEmpty, omitBy, pick, startCase } from 'lodash';
import React from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { queryClient } from '../../App';
import { TextButton } from '../../components/Button';
import { Modal } from '../../components/Dialog';
import { SelectDropdown, SelectDropdownProps } from '../../components/Dropdown';
import { FormTextInputFilled } from '../../components/FormTextInput';
import { GroupButton } from '../../components/GroupButton';
import { useIsMobileView } from '../../components/MobileView';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { ApiService } from '../../services/api';
import { useIsCustomer } from '../../hooks/useGetUserInfo';
import { Account } from '../../types/account';
import { User } from '../../types/user';
import { Section } from '../Customer/PageStyleComponents';

const FormTextInputStyled = styled(FormTextInputFilled)<{ isMobile?: boolean }>`
  margin-top: ${({ isMobile }) => {
    return isMobile ? '16px !important' : '24px !important';
  }};
  width: 310px;
`;

const Dropdown = ({
  isMobile,
  ...props
}: Omit<SelectDropdownProps<string>, 'onChange' | 'name' | 'value'> & { name: string; isMobile?: boolean }) => {
  const [field, meta] = useField(props);
  const hasError = !!meta.touched && !!meta.error;

  return (
    <div style={{ marginTop: isMobile ? '16px' : '24px' }}>
      <SelectDropdown
        type="b3Normal"
        {...field}
        {...props}
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        //@ts-ignore
        style={{ width: '310px', color: Theme.colors.grey1 }}
        error={hasError ? `*${meta.error}` : undefined}
        useCustomStyle
      />
    </div>
  );
};

const ResetButton = () => {
  const { handleReset } = useFormikContext();

  return (
    <TextButton onClick={handleReset} color="darkerBlue" style={{ marginTop: '14px' }}>
      Delete and start over
    </TextButton>
  );
};

export const SettingsModal = ({
  open,
  onClose,
  user,
  account,
}: {
  user: User;
  open: boolean;
  onClose: () => void;
  account?: Account;
}) => {
  const isCustomer = useIsCustomer();
  const isMobile = useIsMobileView();
  const { mutateAsync: mutateUser, isLoading: loadingUser } = useMutation<any, any, any>(
    'user-update',
    ApiService.patch(`/v1/user/${user.uid}`),
    {
      onSuccess: (data) => {
        queryClient.setQueryData('userInfo', (oldData: object) => (oldData ? { ...oldData, ...data } : data));
      },
    }
  );
  const { mutateAsync: mutateAccount, isLoading: loadingAccount } = useMutation<any, any, any>(
    'account-update',
    ApiService.patch(`/v1/account/${account?.uid}`),
    {
      onSuccess: (data) => {
        queryClient.setQueryData('user-account', (oldData: any) => {
          if (oldData) {
            return {
              ...oldData,
              list: [{ ...oldData.list[0], account: { ...oldData.list[0].account, ...data.account } }],
            };
          }
          return oldData;
        });
      },
    }
  );

  return (
    <Modal
      open={open}
      handleClose={onClose}
      title="Update Contact Details"
      closeX
      style={{ maxWidth: 'auto', overflow: isMobile ? 'auto' : 'hidden' }}
    >
      <Formik
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          email: user.email,
          street: account?.street,
          city: account?.city,
          postalCode: account?.postalCode,
          placeStatus: account?.placeStatus,
        }}
        onSubmit={async (values) => {
          const userNewValues = pick(values, ['firstName', 'lastName', 'phone', 'email']);
          const userOldValues = pick(user, ['firstName', 'lastName', 'phone', 'email']);
          const userInput = omitBy(userNewValues, (v, k) => userOldValues[k] === v);

          const shouldUpdateUser = !isEmpty(userInput);
          const promises: Promise<any>[] = [];
          if (account) {
            const accountNewValues = pick(values, ['street', 'city', 'postalCode', 'placeStatus']);
            const accountOldValues = pick(account, ['street', 'city', 'postalCode', 'placeStatus']);

            const accountInput = omitBy(accountNewValues, (v, k) => accountOldValues[k] === v);
            const shouldUpdateAccount = !isEmpty(accountInput);
            if (shouldUpdateAccount) {
              promises.push(mutateAccount({ data: accountInput }));
            }
          }

          if (shouldUpdateUser) {
            promises.push(mutateUser({ data: userInput }));
          }

          await Promise.allSettled(promises);
          onClose();
        }}
      >
        <Form style={{ padding: isMobile ? '0 16px' : '0 24px' }}>
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            <div>
              <Section>
                <Typography type="b3Bold" color="darkerBlue">
                  Name
                </Typography>
                <FormTextInputStyled name="firstName" label="First name" isMobile={isMobile} />
                <FormTextInputStyled name="lastName" label="Last name" isMobile={isMobile} />
              </Section>
              <Section>
                <Typography type="b3Bold" color="darkerBlue">
                  Telephone number
                </Typography>
                <FormTextInputStyled name="phone" label="Telephone number" isMobile={isMobile} />
              </Section>
              <Section>
                <Typography type="b3Bold" color="darkerBlue">
                  Contact & Account email
                </Typography>
                <FormTextInputStyled disabled name="email" label="Email address" isMobile={isMobile} />
              </Section>
            </div>
            {isCustomer && account && (
              <div style={{ marginLeft: isMobile ? '0' : '24px' }}>
                <Section>
                  <Typography type="b3Bold" color="darkerBlue">
                    Address
                  </Typography>
                  <FormTextInputStyled name="postalCode" label="Postcode" isMobile={isMobile} />
                  <FormTextInputStyled name="street" label="Address Line 1" isMobile={isMobile} />
                  <FormTextInputStyled name="city" label="Address Line 2" isMobile={isMobile} />
                  <Dropdown
                    name="placeStatus"
                    label="Status"
                    items={['TENANT', 'OWNER']}
                    renderValue={(_, value) => startCase((value as string).toLowerCase())}
                    renderItemValue={(value) => startCase(value.toLowerCase())}
                    filled
                    isMobile={isMobile}
                  />
                  <ResetButton />
                </Section>
              </div>
            )}
          </div>
          <div style={{ padding: isMobile ? '42px 0 16px' : '54px 0 24px', maxWidth: '310px' }}>
            <GroupButton
              primaryLabel="Update"
              secondaryLabel="Cancel"
              primaryType="submit"
              secondaryType="button"
              onSecondaryClick={onClose}
              loading={loadingUser || loadingAccount}
            />
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
