import React from 'react';

function PolygonDownBase(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 9 7" fill="currentColor" {...props}>
      <path d="M4.5 7L.603.25h7.794L4.5 7z" fill="currentColor" />
    </svg>
  );
}

export const PolygonDown = React.memo(PolygonDownBase);
