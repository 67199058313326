import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Redirect, Link, useHistory } from 'react-router-dom';
import SimpleAlerts from '../../components/Alert';
import { useMutation } from 'react-query';
import { ApiService, CustomError } from '../../services/api';
import type { LoginResponse, LoginRequest } from '../../types/login';
import { queryClient } from '../../App';
import styled from 'styled-components';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';

const Wrapper = styled.div`
  height: 100vh;
  background-color: #00446d;
  width: 100%;
  text-align: center;
`;

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const history = useHistory();
  const { mutate, isLoading, error, data } = useMutation<LoginResponse, CustomError, { data: LoginRequest }, any>(
    ApiService.post('/v1/forgot-password')
  );

  const [errorMsg, setErrorMsg] = useState('');
  const [alertState, setAlertState] = useState(false);

  useEffect(() => {
    if (error) {
      setAlertState(true);
      if (error.name === 'UserNotConfirmedException') {
        setErrorMsg('User not confirmed. Please check your email and confirm your email before login.');
      } else {
        setErrorMsg(error.message);
      }
    }
  }, [error]);

  const closeAlert = () => {
    setAlertState(false);
  };

  const onSubmit = (event: any) => {
    const formData: LoginRequest = { email };
    mutate({ data: formData });
  };

  return (
    <Wrapper>
      {data ? (
        <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          color="white"
          type="b2Normal"
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
          }}
        >
          We have emailed you a password recovery link. Please check your email to continue resetting your password.
        </Typography>
        </div>
      ) : (
        <>
          <SimpleAlerts openAlert={alertState} closeAlert={closeAlert} message={errorMsg} />
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              color="white"
              type="h2"
              style={{
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '50%',
              }}
            >
              Get Reset Password Link
            </Typography>
            <Typography
              color="white"
              type="b2Normal"
              style={{
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '50%',
              }}
            >
              Please enter your IncomeMax Messenger email address to reset your password.
            </Typography>
            <div
              style={{
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography type="b2Normal" color="white" style={{ textAlign: 'start', marginTop: '24px' }}>
                Email
              </Typography>
              <input
                type="email"
                aria-label="email"
                value={email}
                onChange={(event: any) => setEmail(event.target.value)}
                style={{
                  border: '1px solid white',
              
                  borderRadius: '4px',
                  backgroundColor: 'transparent',
                  maxWidth: '300px',
                  alignSelf: 'center',
                  color: 'white',
                  padding: '12px',
                  marginTop: '12px',
                  verticalAlign: 'middle',
                }}
              />
              <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'space-between' }}><a href='https://incomemax.org.uk/contact/'><Typography type="b2Normal" color="white" style={{ textAlign: 'start', marginTop: '12px', textDecoration:'underline' }}>
                Forgot your email
              </Typography></a></div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
             
                <Button
                  styleType="secondary"
                  style={{
                    // backgroundColor: '#fff',
                    // color: '#00446D',
                    border: '1px solid white',
                    alignSelf: 'center',
                    height: '50px',
                    marginTop: '48px',
                  }}
                  onClick={onSubmit}
                >
                  Email me
                </Button>
                <Button
                  styleType="primary"
                  style={{
                    // backgroundColor: '#fff',
                    // color: '#00446D',
                    border: '1px solid white',

                    alignSelf: 'center',
                    height: '50px',
                    marginTop: '48px',
                  }}
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <Backdrop style={{ color: '#fff', zIndex: 999 }} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </>
      )}
    </Wrapper>
  );
};
