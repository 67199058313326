import { useState } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { ApiService } from '../services/api';

export const useFileUploadMutation = <TData, TError, TVariables extends { data: FormData; path: string }>(
  options: Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'> | undefined
) => {
  const [progress, setProgress] = useState(0);

  const mutation = useMutation<TData, TError, TVariables>((args) => {
    // const contentLength = Array.from(args.data.entries(), ([key, prop]) =>
    //   typeof prop === 'string' ? prop.length : prop.size
    // ).reduce((total, len) => total + len, 0);

    return ApiService.post()({
      ...args,
      onUploadProgress: (ev) => setProgress(Math.round((ev.loaded * 100) / ev.total)),
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }, options);

  return { ...mutation, progress };
};
