import React, { CSSProperties, useRef, useState } from 'react';
import { ArrowBack } from '../../assets/icons/ArrowBack';
import { Button, TertiaryButton } from '../../components/Button';
import { useIsMobileView } from '../../components/MobileView';
import { Theme } from '../../constants/theme';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { useFileUploadMutation } from '../../hooks/useFileUploadMutation';
import { Box, LinearProgress, LinearProgressProps } from '@material-ui/core';
import { Modal } from '../../components/Dialog';
import { useInfiniteQuery } from '../../hooks/useInfiniteQuery';
import { Loading } from '../../components/Loading';
import { Virtuoso } from 'react-virtuoso';
import { getInitialsFromUser, TimeFormatter } from '../../utils';
import { useIsCustomer } from '../../hooks/useGetUserInfo';
import { User } from '../../types/user';
import { Avatar } from '../../components/Avatar';
import { Typography } from '../../components/Typography';

const START_INDEX = 1000000;

export const Item = ({
  url,
  name,
  customer,
  createdAt,
  user,
}: {
  url: string;
  name: string;
  createdAt: string;
  user: User;
  customer: User;
}) => {
  const isMeCustomer = useIsCustomer();
  const isCustomersMessage = customer.uid === user.uid;
  const isRightSideMessage = isCustomersMessage ? isMeCustomer : !isMeCustomer;
  const messageStyle: CSSProperties = isRightSideMessage ? { flexDirection: 'row-reverse' } : { flexDirection: 'row' };
  const avatarStyleColor = isCustomersMessage ? 'black' : 'white';
  const avatarStyleBColor = isCustomersMessage ? 'blue' : 'darkBlue';
  const messageUserName = getInitialsFromUser(user);
  const isMobile = useIsMobileView();
  return (
    <div style={{ padding: isMobile ? '8px 24px' : '8px 16px', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-start',
          ...messageStyle,
        }}
      >
        <Avatar color={avatarStyleColor} backgroundColor={avatarStyleBColor} size="smallBold">
          {messageUserName}
        </Avatar>
        <div style={{ paddingLeft: '16px' }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: isMobile ? '100%' : '80%',
          }}
        >
          <a href={url} download>
            <Typography
              color="black"
              type="b3Normal"
              style={{
                padding: isMobile ? '8px' : '12px',
                backgroundColor: isRightSideMessage ? Theme.colors.fadedBlue : Theme.colors.grey3,
                borderRadius: '4px',
                textDecoration: 'underline',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {name || url}
            </Typography>
          </a>
          <div style={{ display: 'flex', paddingTop: '6px', justifyContent: 'space-between' }}>
            <Typography color="black" type="smallNormal" style={{ paddingRight: '6px' }}>{`${TimeFormatter.format(
              new Date(createdAt)
            )}`}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography type="smallNormal" color="darkBlue">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const Standard = (props: any) => {
  const { mutate, progress, isLoading } = useFileUploadMutation({
    onSuccess: () => {
      props.onClose();
    },
  });

  const handleSubmit = async (files) => {
    const body = new FormData();
    files.forEach((data) => body.append('files', data.file));
    mutate({ data: body, path: `/v1/conversation/${props.account.uid}/upload` });
  };
  if (isLoading || progress) {
    return <LinearProgressWithLabel value={isLoading && progress > 95 ? 95 : progress} />;
  }

  return (
    <>
      <Dropzone onSubmit={handleSubmit} styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }} />
    </>
  );
};

export const LinksAndUpload = ({
  onClose,
  account,
  customer,
}: {
  onClose: () => void;
  customer: any;
  account: any;
}) => {
  const listRef = useRef(null);
  const isMobile = useIsMobileView();
  const [uploadModal, openUploadModal] = useState(false);
  const { data, isLoading, fetchNextPage, hasNextPage, error, forceRefetch } = useInfiniteQuery({
    cacheKey: ['links-download', account.uid],
    path: `/v1/conversation/${account.uid}/download`,
    limit: 20,
    reverseData: false,
  });

  const onCloseModal = () => {
    forceRefetch();
    openUploadModal(false);
  };

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <div
        style={{
          padding: isMobile ? '16px' : '16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: Theme.colors.white,
          borderBottom: `1px solid ${Theme.colors.grey5}`,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TertiaryButton
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            //@ts-ignore
            style={{ marginRight: '16px' }}
            onClick={onClose}
            type="b3Bold"
            iconBefore={<ArrowBack style={{ fontSize: '16px', marginRight: '7px' }} />}
          >
            Back
          </TertiaryButton>
        </div>
        <Button onClick={() => openUploadModal(true)}>New Upload</Button>
      </div>

      <div style={{ display: 'flex', flex: 1, width: '100%' }}>
        <Modal open={uploadModal} handleClose={onCloseModal} title="Upload Files" closeX preventBackdropClose>
          <div style={{ width: '300px', padding: '24px' }}>
            <Standard onClose={onCloseModal} account={account} />
          </div>
        </Modal>

        <div style={{ display: 'flex', flex: 1, backgroundColor: Theme.colors.white }}>
          {isLoading ? (
            <Loading />
          ) : error || !data ? (
            <div>error</div>
          ) : (
            <Virtuoso
              ref={listRef}
              style={{ width: '100%', height: '100%' }}
              data={data}
              firstItemIndex={START_INDEX - data.length}
              initialTopMostItemIndex={data.length - 1}
              followOutput={'smooth'}
              startReached={() => {
                fetchNextPage();
              }}
              overscan={{ reverse: 100, main: 0 }}
              itemContent={(_, link) => {
                return <Item {...link} customer={customer} />;
              }}
              components={{
                Header: () => {
                  return hasNextPage ? (
                    <div
                      style={{
                        padding: '2rem',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {<Loading />}
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: '1px',
                      }}
                    />
                  );
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
