import { FormControl, FormHelperText, Input, InputLabel, TextField as MUITextField } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { Theme } from '../constants/theme';

export const FormTextInput = ({ label, style, ...props }: any) => {
  const [field, meta] = useField(props);
  const hasError = !!meta.touched && !!meta.error;
  return (
    <FormControl error={hasError} {...props}>
      {label && <InputLabel htmlFor="component-input-label">{label}</InputLabel>}
      <Input id="component-input-label" {...field} {...props} aria-describedby="component-error-text" style={style} />
      {hasError ? <FormHelperText id="component-error-text">*{meta.error}</FormHelperText> : null}
    </FormControl>
  );
};

const TextField = styled(({ placeholder, ...props }) => <MUITextField {...props} label=" " />)`
  & > label,
  input {
    color: ${Theme.colors.grey1};
    ${Theme.typography.large.b3Normal}
  }

  & > .MuiFormLabel-root.Mui-focused:before {
    color: ${Theme.colors.grey1};
  }

  & .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${Theme.colors.grey1};
  }
  ${({ placeholder, label, value }) => {
    return placeholder && !value
      ? `& > label:before{
              content: '${placeholder}';
        }
        & > .MuiFormLabel-root.Mui-focused:before{
          content: '${label}';
        }
        `
      : `& > label:before{

        content: '${label}';
         }`;
  }}
`;

export const FormTextInputFilled = (props: any) => {
  const [field, meta] = useField(props);
  const hasError = !!meta.touched && !!meta.error;
  return (
    <TextField
      error={hasError}
      id="form-text-input"
      {...field}
      {...props}
      helperText={hasError ? `*${meta.error}` : undefined}
      variant="filled"
    />
  );
};
