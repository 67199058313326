import { isEmpty, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { ReadyState } from 'react-use-websocket';
import { Loading } from '../../components/Loading';
import { useIsMobileView } from '../../components/MobileView';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { useGetUserInfo, useIsCustomer } from '../../hooks/useGetUserInfo';
import { useSocketClient } from '../../hooks/useSocketClient';
import { Account } from '../../types/account';
import { Conversation, ConversationList } from '../../types/conversation';
import { SocketAction } from '../../types/global';
import { User } from '../../types/user';
import { Conversations } from './Conversations';
import { LinksAndUpload } from './LinksAndUpload';
import { Messenger } from './Messenger';

export const Chat = ({ conversations, isFetching, selectedUser, setSelectedUser }: any) => {
  const { accountUid } = useParams<{ accountUid: string }>();
  const isCustomer = useIsCustomer();
  const isMobile = useIsMobileView();
  const [showMessenger, setShowMessenger] = useState(isCustomer);
  const [showLinks, setShowLinks] = useState(false);
  const conversation = conversations.find((c) => c.account.uid === accountUid);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | undefined>(conversation);
  // keep old conversation until the new one is selected
  useEffect(() => {
    if (conversation) {
      setSelectedConversation(conversation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountUid]);

  if (isCustomer && !isEmpty(conversations)) {
    const convAccountUid = conversations[0].account.uid;
    if (convAccountUid !== accountUid) {
      return <Redirect to={`/incomemax/chat/${convAccountUid}`} />;
    }
  }

  if (!selectedConversation && !isEmpty(conversations)) {
    return <Redirect to={`/incomemax/chat/${conversations[0].account.uid}`} />;
  }

  if (isMobile) {
    return (
      <div style={{ display: 'flex', minHeight: '100%', width: '100%' }}>
        {!showMessenger && (
          <Conversations
            conversations={conversations}
            isFetching={isFetching}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            showMessenger={() => setShowMessenger(true)}
          />
        )}
        {showMessenger &&
          (!showLinks ? (
            <Messenger
              conversation={selectedConversation}
              closeMessenger={() => setShowMessenger(false)}
              showLinks={() => setShowLinks(true)}
            />
          ) : (
            <LinksAndUpload
              onClose={() => setShowLinks(false)}
              account={selectedConversation?.account}
              customer={selectedConversation?.customer}
            />
          ))}
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%', position: 'relative' }}>
      <Conversations
        conversations={conversations}
        isFetching={isFetching}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      {!showLinks ? (
        <Messenger conversation={selectedConversation} showLinks={() => setShowLinks(true)} />
      ) : (
        <LinksAndUpload
          onClose={() => setShowLinks(false)}
          account={selectedConversation?.account}
          customer={selectedConversation?.customer}
        />
      )}
    </div>
  );
};

const ChatAccount = ({ user }: any) => {
  const [selectedUser, setSelectedUser] = useState<User | undefined>(user);
  const unallocated = selectedUser ? undefined : true;
  const queryParams = unallocated ? { unallocated } : undefined;
  const userUid = selectedUser ? selectedUser.uid : user?.uid;
  const [firstTimeConnection, setFirstTimeConnection] = useState(true);
  const { readyState, lastJsonMessage } = useSocketClient<any, any>();
  const cacheKey = ['conversations', userUid, unallocated];
  const { data, isLoading, error, isFetching, refetch } = useCustomQuery<ConversationList, { unallocated?: boolean }>({
    cacheKey,
    path: `/v1/conversation/${userUid}`,
    queryParams,
    refetchOnMount: 'always',
    holdOldDataOnForceRefetch: true,
  });

  // handle on reconnect
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      console.log(readyState, 'readyState');
      setFirstTimeConnection(false);
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState]);

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.action === SocketAction.NEW_MESSAGE) {
        const {
          data: { message },
        } = lastJsonMessage;
        if (message.type === 'INFO' && message.message === 'New Advisor') {
          if (data?.conversations.some((c) => c.account.uid === message.accountUid) || message.user.uid === userUid) {
            refetch();
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  if (isLoading || firstTimeConnection) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        <Loading />
      </div>
    );
  }

  if (error || !data) {
    return <div>Error</div>;
  }

  const conversationsWithoutMessage = data.conversations.filter((c) => !c.account.lastMessageDate);
  const conversationsWithMessages = data.conversations.filter((c) => !!c.account.lastMessageDate);

  const conversationsToDisplay = [
    ...orderBy(conversationsWithMessages, ['account.lastMessageDate'], ['desc']),
    ...orderBy(conversationsWithoutMessage, ['account.createdAt'], ['desc']),
  ];

  return (
    <Chat
      conversations={conversationsToDisplay}
      isFetching={isFetching}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
    />
  );
};

const ChatUser = () => {
  const { accountUid } = useParams<{ accountUid: string }>();
  const { user } = useGetUserInfo();
  const isCustomer = useIsCustomer();
  const [firstMount, setFirstMount] = useState(true);
  const { data, isLoading, error } = useCustomQuery<{ account: Account }, any>({
    cacheKey: ['data', 'account', accountUid, 'chat'],
    path: `/v1/account/${accountUid}`,
    enabled: accountUid !== 'no-clients' && firstMount && !isCustomer,
  });

  useEffect(() => {
    setFirstMount(false);
  }, []);

  if (isLoading) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        <Loading />
      </div>
    );
  }

  if (error) {
    return <div>Error</div>;
  }

  let selectedUser = user;

  if (data && data.account.advisor && !isCustomer) {
    selectedUser = data.account.advisor;
  }
  return <ChatAccount user={selectedUser} />;
};

const ChatRedirect = () => {
  const { user } = useGetUserInfo();
  const isCustomer = useIsCustomer();
  const match = useRouteMatch();
  const { uid } = user;
  const { data, isLoading, error } = useCustomQuery<ConversationList, any>({
    cacheKey: ['conv', 'default'],
    path: `/v1/conversation/${uid}`,
  });

  if (isLoading || !data) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        <Loading />
      </div>
    );
  }

  if (error || !data) {
    return <div>Error</div>;
  }

  if (data.conversations.length === 0 && isCustomer) {
    return <div>{"You don't have any advisors yet"}</div>;
  }

  const conversationsWithoutMessage = data.conversations.filter((c) => !c.account.lastMessageDate);
  const conversationsWithMessages = data.conversations.filter((c) => !!c.account.lastMessageDate);

  const conversationsToDisplay = [
    ...orderBy(conversationsWithMessages, ['account.lastMessageDate'], ['desc']),
    ...orderBy(conversationsWithoutMessage, ['account.createdAt'], ['desc']),
  ];

  const conv = conversationsToDisplay[0];

  return <Redirect to={`${match.path}/${conv ? conv.account.uid : 'no-clients'}`} />;
};

export const ChatPage = () => {
  const match = useRouteMatch();
  const { readyState, lastJsonMessage } = useSocketClient<any, any>();
  return (
    <Switch>
      <Route path={`${match.path}/:accountUid`}>
        <ChatUser />
        {/* <Chat
          conversations={conversationsToDisplay}
          isFetching={isFetching}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          refetchConversation={refetch}
          /> */}
      </Route>
      <ChatRedirect />
    </Switch>
  );
};
