import styled from 'styled-components';
import { Theme } from '../constants/theme';

export interface BaseFlexProps {
  justifyCenter?: boolean;
  column?: boolean;
  alignCenter?: boolean;
}

export const PageContainer = styled.div<BaseFlexProps>`
  display: flex;
  flex: 1;
  ${({ alignCenter }) => (alignCenter ? 'align-items: center;' : '')}
  ${({ justifyCenter }) => (justifyCenter ? 'justify-content: center;' : '')}
  ${({ column }) => (column ? 'flex-direction: column;' : '')}
  background-color: ${Theme.colors.grey3};
`;
