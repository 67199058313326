import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavButton = styled(({ isActive, col, children, ...rest }) => (
  <Link {...rest}>
    {children}
    {isActive && (
      <div
        style={{
          height: '6px',
          width: '100%',
          backgroundColor: '#FAB014',
          position: 'absolute',
          ...(col ? { top: 0 } : { bottom: 0 }),
        }}
      />
    )}
  </Link>
))<{ isActive: boolean; col?: boolean }>`
  display: flex;
  position: relative;
  text-decoration: none;
  margin: ${({ col }) => (col ? '0' : '0 12px')};
  cursor: pointer;
  color: #fff;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  ${({ col }) => (col ? `flex-direction: column; width: 100%; padding: 16px 0;` : '')}
`;

export const NavText = styled.p<{ isActive?: boolean; col?: boolean }>`
  display: inline-block;
  white-space: nowrap;
  font-family: Raleway;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
  padding: ${({ col }) => (col ? '5px' : '0 5px')};
`;
