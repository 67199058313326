import React from 'react';

function ChevronRightBase(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 8 12" fill="currentColor" {...props}>
      <path d="M1.705 0L.295 1.41 4.875 6l-4.58 4.59L1.705 12l6-6-6-6z" fill="currentColor" />
    </svg>
  );
}

export const ChevronRight = React.memo(ChevronRightBase);
