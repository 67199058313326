import React from 'react';
import { IconButton } from '@material-ui/core';
import IM from '../../../assets/incomemax.svg';
import { EventAvailable, ExitToApp, MailOutlined, Settings } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { logoutHandler } from '../../main/mainPage/mainPage.component';
import styled from 'styled-components';
import { NavButton, NavText } from '../../../components/NavButton';
// import { Avatar } from '@material-ui/core';
import { Theme } from '../../../constants/theme';
import { BodyCenterMaxWidth } from '../../../pages/PrivateRouter';
import { Avatar } from '../../../components/Avatar';

const Container = styled.header<{ isMobile: boolean }>`
  background-color: ${Theme.colors.darkBlue};
  height: ${({ isMobile }) => (isMobile ? '80px' : '65px')};
  align-items: center;
`;

export const NavHeader = ({ me, isMobile }: any) => {
  const location = useLocation();
  const name = `${me.firstName.slice(0, 1)}${me.lastName.slice(0, 1)}`;
  const isAdvisor = me.permissions?.includes('APP_ADVISOR');
  const isAdmin = me.permissions.includes('APP_ADMIN');
  return (
    <Container isMobile={isMobile}>
      <BodyCenterMaxWidth>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <img src={IM} alt="Logo" style={{ padding: '18px 24px 10px 24px' }} />
          <div
            style={{ display: 'flex', flex: 1, height: '100%', justifyContent: 'space-between', alignItems: 'center' }}
          >
            {isMobile ? (
              <div />
            ) : (
              <div style={{ display: 'flex', height: '100%' }}>
                {isAdmin && (
                  <NavButton isActive={location.pathname.includes('advisors')} to={'/incomemax/advisors'}>
                    <EventAvailable />
                    <NavText isActive={location.pathname.includes('advisors')}>Advisors</NavText>
                  </NavButton>
                )}
                {isAdvisor && (
                  <NavButton isActive={location.pathname.includes('customers')} to={'/incomemax/customers'}>
                    <EventAvailable />
                    <NavText isActive={location.pathname.includes('customers')}>Customers</NavText>
                  </NavButton>
                )}
                <NavButton isActive={location.pathname.includes('chat')} to={'/incomemax/chat'}>
                  <MailOutlined />
                  <NavText isActive={location.pathname.includes('chat')}>Chat</NavText>
                </NavButton>

                <NavButton isActive={location.pathname.includes('settings')} to={'/incomemax/settings'}>
                  <Settings />
                  <NavText isActive={location.pathname.includes('settings')}>Settings</NavText>
                </NavButton>
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt="profile" backgroundColor="white" color="darkBlue" size="b3Bold">
                {name}
              </Avatar>
              {!isMobile && <NavText>{me.firstName}</NavText>}
              <IconButton style={{ color: '#fff', marginRight: '12px' }} onClick={logoutHandler}>
                <NavText>Sign out</NavText>
                <ExitToApp />
              </IconButton>
            </div>
          </div>
        </div>
      </BodyCenterMaxWidth>
    </Container>
  );
};