import { debounce } from 'lodash';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { queryClient } from '../../App';
import { Search } from '../../assets/icons/Search';
import { Modal } from '../../components/Dialog';
import { GroupButton } from '../../components/GroupButton';
import { Loading } from '../../components/Loading';
import { usePaginationQuery } from '../../components/Table/usePaginationQuery';
import { TextInput } from '../../components/TextInput';
import { ApiService, CustomError } from '../../services/api';
import { Account, AddAdvisorInput } from '../../types/account';
import { AdvisorList, AdvisorQueryParams } from '../../types/advisor';
import { User } from '../../types/user';
import { AdvisorsTable } from './AdvisorsTable';

interface AdvisorModalProps {
  accountUid?: string | null;
  customer?: User;
  closeModal: () => void;
  activeCustomersPage: number;
  cacheKey: number;
}

export const AdvisorModal: FunctionComponent<AdvisorModalProps> = ({
  accountUid,
  customer,
  closeModal,
  cacheKey,
  activeCustomersPage,
}) => {
  const [search, setSearch] = useState('');
  const [selectedAdvisorUid, setSelectedAdvisorUid] = useState<string | null>(null);
  const { activePage, rowsPerPage, data, handleChangePage, isLoading, isFetching, error, forceRefetch } =
    usePaginationQuery<AdvisorList, AdvisorQueryParams>({
      path: '/v1/user/advisor',
      cacheKey: 'advisors',
      queryParams: { search },
    });

  const close = () => {
    setSelectedAdvisorUid(null);
    closeModal();
  };

  const {
    mutate,
    isLoading: loading,
    // error, TODO: Handle error
  } = useMutation<Account, CustomError, { data: AddAdvisorInput }>(
    'add-advisor',
    ApiService.post(`/v1/account/${accountUid}/add-advisor`),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['customers', cacheKey], (oldData: any) => {
          const index = oldData.pageParams.findIndex((p: any) => p === activeCustomersPage);
          const pageIndex = index === -1 ? 0 : index;
          const accountIndex = oldData.pages[pageIndex].list.findIndex((i: any) => i.account.uid === accountUid);
          if (pageIndex !== -1 && accountIndex !== -1) {
            oldData.pages[pageIndex].list[accountIndex].advisor = data.advisor;
          }
          return { ...oldData };
        });

        queryClient.setQueryData('advisors', (oldData: any) => {
          const index = oldData.pageParams.findIndex((p: any) => p === activePage);
          const pageIndex = index === -1 ? 0 : index;
          const advisorIndex = oldData.pages[pageIndex].list.findIndex((i: any) => i.uid === selectedAdvisorUid);
          if (pageIndex !== -1 && advisorIndex !== -1) {
            oldData.pages[pageIndex].list[advisorIndex].totalCustomers++;
          }
          return { ...oldData };
        });

        close();
      },
    }
  );

  const setAdvisorUid = (uid: string) => {
    setSelectedAdvisorUid(uid);
  };

  const handleChangeAdvisors = () => {
    selectedAdvisorUid && mutate({ data: { advisorUserUid: selectedAdvisorUid } });
  };

  const debounceRefetch = useRef(debounce(forceRefetch, 1000)).current;

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    debounceRefetch();
  };

  const renderTable = () => {
    if (error || !data) {
      return <div style={{ flex: 1 }}>Error!!!</div>;
    }
    return (
      <AdvisorsTable
        data={data}
        isFetching={isFetching}
        rowsPerPage={rowsPerPage}
        page={activePage + 1}
        handlePageChange={handleChangePage}
        setAdvisorUid={setAdvisorUid}
        selectedAdvisorUid={selectedAdvisorUid}
      />
    );
  };

  const title = customer ? `Allocate ${customer.firstName} ${customer.lastName} to an advisor` : '';

  return (
    <Modal open={!!accountUid} handleClose={close} closeX title={title}>
      <TextInput
        placeholder="Search for name or email"
        value={search}
        onChange={handleSearch}
        style={{ width: 'calc(100% - 48px)', margin: '24px' }}
        endAdornment={<Search style={{ fontSize: '24px' }} />}
      />
      {isLoading ? (
        <div style={{ display: 'flex', width: '100%', height: '120px' }}>
          {/* TODO: fix style for spinner */}
          <Loading />
        </div>
      ) : (
        renderTable()
      )}
      <div style={{ width: 'calc(100% - 48px)', margin: '24px' }}>
        <GroupButton
          primaryLabel="Confirm"
          onPrimaryClick={handleChangeAdvisors}
          onSecondaryClick={close}
          secondaryLabel="Cancel"
          primaryDisabled={!selectedAdvisorUid}
          loading={loading}
        />
      </div>
    </Modal>
  );
};
