import React, { CSSProperties } from 'react';
import { Avatar } from '../../components/Avatar';
import { TextButton } from '../../components/Button';
import { useIsMobileView } from '../../components/MobileView';
import { MessageInput } from '../../components/TextAreaInput';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { useIsCustomer } from '../../hooks/useGetUserInfo';
import { SlimUser } from '../../types/conversation';
import { Message } from '../../types/message';
import { User } from '../../types/user';
import { getFullNameFromUser, getInitialsFromUser, TimeFormatter } from '../../utils/helpers';
import { DeleteForeverOutlined, Check, Close } from '@material-ui/icons';

export const MessageItem = ({
  messageItem,
  customer,
  advisor,
  unreadMessages,
  editMessageTextValue,
  editMessageTextUid,
  setEditMessageTextValue,
  setEditMessageTextUid,
  me,
  saveEditMessageText,
  openDeleteMessageModal,
  isEditable,
}: {
  isEditable?: boolean;
  messageItem: Message;
  customer: SlimUser;
  advisor: SlimUser;
  unreadMessages?: { total?: number; uid?: string };
  editMessageTextValue: string;
  editMessageTextUid: string;
  setEditMessageTextValue: (v: string) => void;
  setEditMessageTextUid: (v: string) => void;
  saveEditMessageText: () => void;
  openDeleteMessageModal: () => void;
  me: User;
}) => {
  const isMobile = useIsMobileView();
  const isMeCustomer = useIsCustomer();
  const { user, message, createdAt, editedAt, type, uid } = messageItem;
  const isNewAdvisorInfo = type === 'INFO' && message === 'New Advisor';
  if (isNewAdvisorInfo) {
    return (
      <div style={{ padding: isMobile ? '8px 24px' : '8px 16px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography type="b3Normal" color="black">{`${getFullNameFromUser(
            user
          )} has been added to the conversation`}</Typography>
        </div>
      </div>
    );
  }
  const isEditingText = editMessageTextUid === uid;
  const canEdit = isEditable && user.uid === me.uid && (me.uid === customer.uid || me.uid === advisor?.uid);

  const editTextHandler = () => {
    setEditMessageTextValue(message);
    setEditMessageTextUid(uid);
  };

  const cancelEditTextHandler = () => {
    setEditMessageTextUid('');
    setEditMessageTextValue('');
  };

  const onChangeText = (e: any) => {
    const value = e.target.value;
    setEditMessageTextValue(value);
  };

  const isCustomersMessage = customer.uid === user.uid;
  const isRightSideMessage = isCustomersMessage ? isMeCustomer : !isMeCustomer;
  const messageStyle: CSSProperties = isRightSideMessage ? { flexDirection: 'row-reverse' } : { flexDirection: 'row' };
  const avatarStyleColor = isCustomersMessage ? 'black' : 'white';
  const avatarStyleBColor = isCustomersMessage ? 'blue' : 'darkBlue';
  const messageUserName = getInitialsFromUser(user);

  return (
    <div style={{ padding: isMobile ? '8px 24px' : '8px 16px', display: 'flex', flexDirection: 'column' }}>
      {unreadMessages && unreadMessages.uid === uid && !!unreadMessages.total && (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingBottom: '16px' }}>
          <Typography type="b3Normal" color="black">
            {`${unreadMessages.total} Unread Message${unreadMessages.total > 1 ? 's' : ''}`}
          </Typography>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-start',
          ...messageStyle,
        }}
      >
        <Avatar color={avatarStyleColor} backgroundColor={avatarStyleBColor} size="smallBold">
          {messageUserName}
        </Avatar>
        <div style={{ paddingLeft: '16px' }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: isMobile ? '100%' : '80%',
            width: isEditingText ? '100%' : '',
          }}
        >
          {!isEditingText ? (
            <Typography
              color="black"
              type="b3Normal"
              style={{
                padding: isMobile ? '8px' : '12px',
                backgroundColor: isRightSideMessage ? Theme.colors.fadedBlue : Theme.colors.grey3,
                borderRadius: '4px',
                minWidth: '100px',
              }}
            >
              {message}
            </Typography>
          ) : (
            <MessageInput
              style={{ resize: 'none', backgroundColor: Theme.colors.fadedBlue }}
              value={editMessageTextValue}
              onChange={onChangeText}
              aria-label="maximum height"
              minRows={1}
            />
          )}
          <div style={{ display: 'flex', paddingTop: '6px', justifyContent: 'space-between' }}>
            {isEditingText ? (
              <>
                <div>
                  <Check
                    style={{ fontSize: '24px', marginRight: '6px', cursor: 'pointer' }}
                    onClick={saveEditMessageText}
                  />
                  <Close
                    onClick={cancelEditTextHandler}
                    style={{ fontSize: '24px', marginLeft: '6px', cursor: 'pointer' }}
                  />
                </div>
                <DeleteForeverOutlined
                  style={{ fontSize: '24px', cursor: 'pointer' }}
                  onClick={openDeleteMessageModal}
                />
              </>
            ) : (
              <>
                <Typography color="black" type="smallNormal" style={{ paddingRight: '6px' }}>{`${TimeFormatter.format(
                  new Date(createdAt)
                )}${editedAt ? ' | Edited' : ''}`}</Typography>
                {canEdit && (
                  <TextButton type="smallNormal" color="darkerBlue" onClick={editTextHandler}>
                    Edit message
                  </TextButton>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
