import { Container, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import { useEffect } from 'react';

interface Props {
  onAction: any;
  step: number;
  checkValues: any;
  handleChanges: any;
  addMore: any;
  // checked: any;
}

const OnboardingStep3: React.FC<Props> = ({ onAction, step, checkValues, handleChanges, addMore }) => {
  const [addText, setAddText] = useState('');

  const handleTextInput = (event: any) => {
    setAddText(event.target.value);
  };

  if (step !== 3) return null;
  return (
    <Container>
      <h1 className="title">What's your situation?</h1>
      <p style={{ textAlign: 'center' }}>Choose as many as you like.</p>
      <div className="fab-container">
        <div className=" floating-buttons">
          {checkValues.Checks.map((i: any, index1) => {
            return i.options.map((item: any, index2) => {
              return (
                <div className={item.checked ? 'some' : 'else'} key={`${index1}-${index2}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.checked || false}
                        color="primary"
                        onChange={() => handleChanges(item.value)}
                        name={item.name}
                        value={item.value}
                      />
                    }
                    label={item.name}
                  />
                </div>
              );
            });
          })}
        </div>
        <div className="add-more">
          <TextField label="Anything else ..." variant="filled" name="addMore" onChange={handleTextInput} />
          <Button onClick={() => addMore(addText)}>
            {' '}
            Add <Add />
          </Button>
        </div>
        <div className="button">
          <Button variant="contained" color="primary" onClick={onAction}>
            Next step
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default OnboardingStep3;
