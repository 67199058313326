import React from 'react';
import { ArrowBack } from '../../assets/icons/ArrowBack';
import { Avatar } from '../../components/Avatar';
import { TertiaryButton, TextButton } from '../../components/Button';
import { useIsMobileView } from '../../components/MobileView';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { useIsCustomer } from '../../hooks/useGetUserInfo';
import { Conversation } from '../../types/conversation';
import { User } from '../../types/user';
import { getFullNameFromUser, getInitialsFromUser } from '../../utils';
import { MessagesView } from './MessagesView';

export const Messenger = ({
  conversation,
  closeMessenger,
  showLinks,
}: {
  conversation?: Conversation;
  closeMessenger?: any;
  showLinks: () => void;
}) => {
  const isMobile = useIsMobileView();
  const isCustomer = useIsCustomer();
  // advisor doesn't have any customers
  if (!conversation && !isCustomer) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: Theme.colors.white,
        }}
      >
        <Typography color="grey1" type="b3Normal">
          Allocate some customers and start using chat
        </Typography>
      </div>
    );
  }

  if (!conversation) {
    return null;
  }

  const { advisor, account, customer } = conversation;
  const userToDisplay = isCustomer ? advisor : customer;
  const title = isCustomer ? 'IncomeMax Advisor' : 'Customer';
  const avatarBgColor = isCustomer ? 'darkBlue' : 'blue';
  const avatarColor = isCustomer ? 'white' : 'black';
  let initials = getInitialsFromUser(userToDisplay as User);
  let fullName = getFullNameFromUser(userToDisplay as User);
  // client doesn't have any advisors
  if (!initials) {
    initials = 'IMX';
  }
  if (!fullName) {
    fullName = 'IncomeMax';
  }

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <div
        style={{
          padding: isMobile ? '16px' : '16px 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: Theme.colors.white,
          borderBottom: `1px solid ${Theme.colors.grey5}`,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isMobile && !isCustomer && (
            <TertiaryButton
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              //@ts-ignore
              style={{ marginRight: '16px' }}
              onClick={closeMessenger}
              type="b3Bold"
              iconBefore={<ArrowBack style={{ fontSize: '16px', marginRight: '7px' }} />}
            >
              Back
            </TertiaryButton>
          )}
          <Avatar
            color={avatarColor}
            size="smallBold"
            backgroundColor={avatarBgColor}
            style={{ height: '30px', width: '30px', marginRight: '7px' }}
          >
            {initials}
          </Avatar>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="darkerBlue" type="smallBold">
              {fullName}
            </Typography>
            <Typography color="darkerBlue" type="smallNormal">
              {title}
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isCustomer && (
            <>
              <TextButton
                to={`/incomemax/customers/${customer.uid}`}
                color="darkerBlue"
                type="smallBold"
                style={{ marginLeft: '24px' }}
              >
                Manage
              </TextButton>
              <TextButton
                color="darkerBlue"
                type="smallBold"
                style={{ marginLeft: '24px' }}
                onClick={
                  account.salesforceURL
                    ? () => {
                        const win = window.open(account.salesforceURL as string, '_blank');
                        win && win.focus();
                      }
                    : undefined
                }
                to={!account.salesforceURL ? `/incomemax/customers/${customer.uid}` : undefined}
              >
                {account.salesforceURL ? 'Salesforce >' : 'Add Salesforce'}
              </TextButton>
            </>
          )}
          {process.env['REACT_APP_UPLOAD_FLAG'] === 'true' ? (
            <TextButton color="darkerBlue" type="smallBold" style={{ marginLeft: '24px' }} onClick={showLinks}>
              {'Media, links & docs'}
            </TextButton>
          ) : null}
        </div>
      </div>
      <MessagesView conversation={conversation} />
    </div>
  );
};
