import React from 'react';
import { Typography } from '../Typography';

export const PageHeader = ({ title, children }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        width: 'calc(100% - 48px)',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: '24px 24px 0px 24px',
      }}
    >
      <Typography color="darkerBlue" type="b2Bold" style={{ marginBottom: '24px' }}>
        {title}
      </Typography>
      {children}
    </div>
  );
};
