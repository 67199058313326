import styled from 'styled-components';
import { useIsMobileView } from '../../components/MobileView';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';

export const HeaderPageContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  border-bottom: 1px solid ${Theme.colors.grey3};
  background-color: ${Theme.colors.white};
  ${({ isMobile }) =>
    isMobile
      ? `flex-direction: column; 
      padding: 16px;`
      : `justify-content: space-between; 
      align-items: center; 
      padding: 26px;`}
`;

export const LeftWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  ${({ isMobile }) => (isMobile ? `margin-bottom: 16px;` : ``)}
`;

export const Section = styled.div<{ row?: boolean }>`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  margin-top: 24px;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageContentBlock = styled.div<{ disabled?: boolean; fullWidth?: boolean; bRadius?: boolean }>`
  ${({ fullWidth }) => {
    if (fullWidth) {
      return '';
    }
    const isMobile = useIsMobileView();
    return isMobile ? '' : 'width: 33.3%;';
  }}
  ${({ bRadius }) => (bRadius ? 'border-radius: 4px;' : '')}
  padding: 24px;
  border: 1px solid ${Theme.colors.white};
  border-style: none solid solid solid;
  background-color: ${({ disabled }) => (disabled ? Theme.colors.grey3 : Theme.colors.white)};
`;

export const PageContentBlockMargin = styled.div<{ disabled?: boolean }>`
  ${() => {
    const isMobile = useIsMobileView();
    return isMobile ? '' : 'width: 33.3%;';
  }}
`;

export const SettingsPageWrapper = styled.div`
  height: 100%;
  display: flex;
  ${() => {
    const isMobile = useIsMobileView();
    return isMobile
      ? `
  padding: 0 16px 16px 16px; 
  flex-direction: column;
  `
      : `
  padding: 12px; 
  flex-direction: row;
  `;
  }}
`;

export const UnreadCircle = styled(Typography)`
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  vertical-align: middle;
  color: ${Theme.colors.black};
  text-align: center;
  background-color: ${Theme.colors.orange};
`;
