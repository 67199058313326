import { useField, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { FunctionComponent, useRef, useState } from 'react';
import { Search } from '../../assets/icons/Search';
import { Modal } from '../../components/Dialog';
import { GroupButton } from '../../components/GroupButton';
import { Loading } from '../../components/Loading';
import { usePaginationQuery } from '../../components/Table/usePaginationQuery';
import { TextInput } from '../../components/TextInput';
import { AdvisorList, AdvisorQueryParams } from '../../types/advisor';
import { User } from '../../types/user';
import { AdvisorsTable } from './AdvisorsTable';

interface AdvisorModalProps {
  customer?: User;
  closeModal: () => void;
  name: string;
  open: boolean;
  title?: string;
  submit?: boolean;
  isLoadingSubmit?: boolean;
  currentAdvisorUid?: string;
}

export const AdvisorModal: FunctionComponent<AdvisorModalProps> = ({
  customer,
  closeModal,
  open,
  name,
  title,
  submit,
  isLoadingSubmit,
}) => {
  const [search, setSearch] = useState('');
  const [field] = useField({ name });
  const [selectedAdvisorUid, setSelectedAdvisorUid] = useState<string>(field.value);
  const { submitForm } = useFormikContext();

  const { activePage, rowsPerPage, data, handleChangePage, isLoading, isFetching, error, forceRefetch } =
    usePaginationQuery<AdvisorList, AdvisorQueryParams>({
      path: '/v1/user/advisor',
      cacheKey: 'advisorsModalAllocation',
      queryParams: { search },
    });

  const setAdvisorUid = (uid: string) => {
    setSelectedAdvisorUid(uid);
  };

  const close = () => {
    setSelectedAdvisorUid(field.value);
    closeModal();
  };

  const handleChangeAdvisors = () => {
    const advisor = data?.list.find((d) => d.uid === selectedAdvisorUid);
    field.onChange(name)(selectedAdvisorUid);
    if (advisor) {
      field.onChange('advisorFirstName')(advisor.firstName);
      field.onChange('advisorLastName')(advisor.lastName);
    }
    if (submit) {
      submitForm();
    } else {
      closeModal();
    }
  };

  const debounceRefetch = useRef(debounce(forceRefetch, 1000)).current;

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    debounceRefetch();
  };

  const renderTable = () => {
    if (error || !data) {
      return <div style={{ flex: 1 }}>Error!!!</div>;
    }
    return (
      <AdvisorsTable
        data={data}
        isFetching={isFetching}
        rowsPerPage={rowsPerPage}
        page={activePage + 1}
        handlePageChange={handleChangePage}
        setAdvisorUid={setAdvisorUid}
        selectedAdvisorUid={selectedAdvisorUid}
      />
    );
  };

  title = customer ? `Allocate ${customer.firstName} ${customer.lastName} to an advisor` : title || '';

  return (
    <Modal open={open} handleClose={close} closeX title={title}>
      <TextInput
        placeholder="Search for name or email"
        value={search}
        onChange={handleSearch}
        style={{ width: 'calc(100% - 48px)', margin: '24px' }}
        endAdornment={<Search style={{ fontSize: '24px' }} />}
      />
      {isLoading ? (
        <div style={{ display: 'flex', width: '100%', height: '120px' }}>
          {/* TODO: fix style for spinner */}
          <Loading />
        </div>
      ) : (
        renderTable()
      )}
      <div style={{ width: 'calc(100% - 48px)', margin: '24px' }}>
        <GroupButton
          primaryLabel="Confirm"
          onPrimaryClick={handleChangeAdvisors}
          loading={isLoadingSubmit}
          onSecondaryClick={close}
          secondaryLabel="Cancel"
          primaryDisabled={!selectedAdvisorUid}
        />
      </div>
    </Modal>
  );
};
