// import { Paper, PaperProps } from '@material-ui/core';
// import React from 'react';
import styled from 'styled-components';
import { Theme } from '../constants/theme';
import { BaseFlexProps } from './PageContainer';

export const PaperFlex = styled.div<BaseFlexProps>`
  display: flex;
  background-color: ${Theme.colors.white};
  ${({ alignCenter }) => (alignCenter ? 'align-items: center;' : '')}
  ${({ justifyCenter }) => (justifyCenter ? 'justify-content: center;' : '')}
  ${({ column }) => (column ? 'flex-direction: column;' : '')}
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
`;
