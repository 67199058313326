import config from '../config';
import useWebSocket from 'react-use-websocket';
import { logoutHandler, sessionExpCheck } from '../features/main/mainPage/mainPage.component';

export const useSocketClient = <ResponseType, RequestType>() => {
  const token = localStorage.getItem('token');
  const {
    sendJsonMessage,
    sendMessage,
    lastJsonMessage: lastMessage,
    ...rest
  } = useWebSocket(config.domains.ws as string, {
    share: true,
    queryParams: { token } as { token: string },
    retryOnError: !!token,
    shouldReconnect: sessionExpCheck,
    onError: sessionExpCheck,
  });

  const sendJsonMessageWithCheck = (data: RequestType) => {
    if (!sessionExpCheck()) {
      console.log('Token expired');
      return;
    }
    sendJsonMessage(data);
  };

  const lastJsonMessage = lastMessage as ResponseType;

  if (lastMessage && lastMessage.action && lastMessage.action === 'logout') {
    logoutHandler();
  }

  return { ...rest, lastJsonMessage, sendJsonMessage: sendJsonMessageWithCheck };
};
