import React from 'react';

function ArrowForwardBase(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" {...props}>
      <path d="M8 0L6.59 1.41 12.17 7H0v2h12.17l-5.58 5.59L8 16l8-8-8-8z" fill="currentColor" />
    </svg>
  );
}

export const ArrowForward = React.memo(ArrowForwardBase);
