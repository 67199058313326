import { CircularProgress, styled } from '@material-ui/core';
import React from 'react';

const FlexCol = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

export const Loading = (props: any) => (
  <FlexCol>
    <CircularProgress {...props} />
  </FlexCol>
);

// export default Loading;
