import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { isArray } from 'lodash';
import Qs from 'qs';
import { logoutHandler } from '../features/main/mainPage/mainPage.component';

export interface CustomError extends Error {
  status: number;
  name: string;
}

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}
export class ApiService {
  private static _instance = axios.create({
    baseURL: process.env['REACT_APP_API_URL'],
    paramsSerializer: (params) => {
      return Qs.stringify(params, { indices: false });
    },
  });
  private static async _fetchApi<T extends any>(path: string, options: AxiosRequestConfig): Promise<T> {
    const { headers, ...rest } = options;
    try {
      const response = await this._instance.request<any>({
        url: path,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          ...(headers ? headers : {}),
        },
        ...rest,
      });
      const refreshToken = response.headers['x-refresh-token'];
      if (refreshToken) ApiService.refreshAuthToken(refreshToken);
      const body = response.data;
      const { status } = response;
      if (!status || status < 200 || status >= 300) {
        const error = new Error(body.error || response.statusText) as CustomError;
        error.status = status;
        error.name = body.code;
        throw error;
      }

      if (!('data' in body)) {
        return body;
      }
      return body.data;
    } catch (e: any) {
      const error: AxiosError<any> = e;
      if (error.response) {
        const { status, data, statusText } = error.response;
        let customError: CustomError = new Error(statusText || 'Unhandled Error') as CustomError;
        customError.status = status;
        customError.name = 'unhandled_name';
        if (status < 200 || status >= 300) {
          if (isArray(data.errors) && data.errors[0]) {
            customError = new Error(data.errors[0].message) as CustomError;
            customError.name = data.errors[0].name;
            customError.status = data.errors[0].status;
          }
        }

        if (customError.name === 'UnauthorizedError' && customError.status === 401) {
          logoutHandler();
          return undefined as T;
        }
        throw customError;
      }
      throw error;
    }
  }
  static refreshAuthToken(token: string): void {
    localStorage.setItem('token', token);
    if (this._instance.defaults.headers) {
      this._instance.defaults.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  static get(path: string, params?: any) {
    return <K extends any, T extends object>(overrideParams?: T) =>
      this._fetchApi<K>(path, { method: HTTPMethods.GET, params: overrideParams ? overrideParams : params });
  }

  static post(path?: string) {
    return <K extends any, T extends object>(options: {
      data: T;
      path?: string;
      headers?: Record<string, string>;
      onUploadProgress?: (progressEvent: any) => void;
    }) =>
      this._fetchApi<K>(path || (options.path as string), {
        method: HTTPMethods.POST,
        data: options.data,
        headers: options.headers,
        onUploadProgress: options.onUploadProgress,
      });
  }

  static put() {
    return <K extends any, T extends object>(options: { data: T; path: string }) =>
      this._fetchApi<K>(options.path, { method: HTTPMethods.PUT, data: options.data });
  }

  static patch(path?: string) {
    return <K extends any, T extends object>(options: { data: T; path?: string }) =>
      this._fetchApi<K>(path || (options.path as string), { method: HTTPMethods.PATCH, data: options.data });
  }

  static delete() {
    return <K extends any, T extends object>(options: { data?: T; path: string }) =>
      this._fetchApi<K>(options.path, { method: HTTPMethods.DELETE, data: options.data });
  }
  // static getErrorMessage(code: ErrorCode): string {
  //   return errorCodeMapper[code] || 'An error happened, please try again later!';
  // }
}
