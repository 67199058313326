import { Dialog as MUIDialog, DialogProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Close } from '../assets/icons/Close';
import { ColorKeys, Theme } from '../constants/theme';
import { useIsMobileView } from './MobileView';
import { Typography } from './Typography';

const DialogTitle = styled.div<{ hasChildren?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${({ hasChildren }) => (hasChildren ? `margin: 22px 24px 0;` : `margin: 22px 24px; min-width: 200px; `)}
`;

const Dialog = styled(MUIDialog)<{ position?: 'top' }>`
  & div.MuiDialog-paperScrollPaper {
    ${() => {
      const isMobile = useIsMobileView();
      return isMobile ? 'margin: 68px 0;' : 'margin: 0;';
    }}
    max-width: none;
  }
  ${({ position }) =>
    position === 'top'
      ? `& > .MuiDialog-container {
    height: 50%;
}`
      : ``}
`;

interface ModalProps extends Omit<DialogProps, 'onClose' | 'open'> {
  open: boolean;
  handleClose: () => void;
  title?: string;
  titleColor?: ColorKeys;
  closeX?: boolean;
  preventBackdropClose?: boolean;
  preventEscapeKeyClose?: boolean;
  position?: 'top';
  style?: any;
}
export const Modal: FunctionComponent<ModalProps> = ({
  open,
  handleClose,
  title,
  titleColor,
  closeX,
  preventBackdropClose,
  preventEscapeKeyClose,
  children,
  style,
  ...rest
}) => {
  const onClose = (_: object, reason: 'escapeKeyDown' | 'backdropClick') => {
    if (preventBackdropClose && reason === 'backdropClick') {
      return;
    }
    if (preventEscapeKeyClose && reason === 'escapeKeyDown') {
      return;
    }
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      <DialogTitle id="alert-dialog-title" hasChildren={!!children} style={style}>
        {title && (
          <Typography color={titleColor || 'darkerBlue'} type="b2Bold">
            {title}
          </Typography>
        )}
        {closeX && (
          <Close
            onClick={handleClose}
            style={{ cursor: 'pointer', color: Theme.colors.darkerBlue, marginLeft: '24px' }}
          />
        )}
      </DialogTitle>
      {children}
    </Dialog>
  );
};
