import { User } from '../types/user';

export const TimeFormatter = new Intl.DateTimeFormat('en-GB', {
  hourCycle: 'h23',
  hour: '2-digit',
  minute: '2-digit',
});

export const DateFormatter = new Intl.DateTimeFormat('en-GB');

export const RelativeDateTimeFormatter = (datetime: Date, useToday?: boolean): string => {
  const now = new Date();
  const startToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
  const startTodayMs = startToday.getTime();
  const dateTimeMs = datetime.getTime();
  const totalMs = startTodayMs - dateTimeMs;
  const oneDayMs = 24 * 60 * 60 * 1000;
  const weekMs = oneDayMs * 7;

  if (totalMs <= 0) {
    if (useToday) {
      return 'Today';
    }
    /** Today -> show only time 24h */
    return TimeFormatter.format(datetime);
  } else if (totalMs <= oneDayMs /** Yesterday */) {
    return 'Yesterday';
  } else if (totalMs > weekMs) {
    /** Everything past this week -> show date */
    return DateFormatter.format(datetime).split('/').join('.');
  }
  /** This week -> show day */
  return datetime.toLocaleString('en-us', { weekday: 'long' });
};

export const getInitialsFromUser = (user?: Pick<User, 'firstName' | 'lastName'>) =>
  user ? `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`.toUpperCase() : '';

export const getFullNameFromUser = (user?: Pick<User, 'firstName' | 'lastName'>) =>
  user ? `${user.firstName} ${user.lastName}` : '';
