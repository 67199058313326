import React from 'react';
import { useLocation } from 'react-router-dom';
import { CustomError } from '../../services/api';

export const GlobalErrorPage = () => {
  const location = useLocation<{ error: CustomError }>();
  const error = location.state.error;

  return <div>Error page: {error ? error.message : 'unknown error'}</div>;
};
