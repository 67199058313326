import React, { FunctionComponent } from 'react';
import { Button } from './Button';

interface GroupButtonProps {
  primaryLabel: string;
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
  secondaryLabel?: string;
  loading?: boolean;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  primaryType?: 'submit' | 'button' | 'reset';
  secondaryType?: 'submit' | 'button' | 'reset';
}

export const GroupButton: FunctionComponent<GroupButtonProps> = ({
  primaryLabel,
  secondaryLabel,
  primaryDisabled,
  secondaryDisabled,
  loading,
  primaryType,
  secondaryType,
  onPrimaryClick,
  onSecondaryClick,
}) => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Button
        style={{ marginRight: secondaryLabel ? '8px' : '' }}
        loading={loading}
        styleType="primary"
        type={primaryType}
        color={'dark'}
        disabled={primaryDisabled}
        onClick={onPrimaryClick}
      >
        {primaryLabel}
      </Button>
      {secondaryLabel && (
        <Button
          styleType="secondary"
          type={secondaryType}
          onClick={onSecondaryClick}
          disabled={secondaryDisabled || loading}
          style={{ marginLeft: '8px' }}
        >
          {secondaryLabel}
        </Button>
      )}
    </div>
  );
};
