import React, { useEffect, useRef, useState } from 'react';
import { Loading } from '../../components/Loading';
import { debounce } from 'lodash';
// import { TextButton } from '../../components/Button';
import { PaperFlex } from '../../components/PaperFlex';
import { PageContainer } from '../../components/PageContainer';
import { PageHeader } from '../../components/Table/PageHeader';
import { TextInput } from '../../components/TextInput';
import type { CustomerQueryParams, CustomerList, customersOrderBy } from '../../types/customer';
import { CustomersTable, Header } from './CustomersTable';
import { Search } from '../../assets/icons/Search';
import { SelectDropdown } from '../../components/Dropdown';
import { Typography } from '../../components/Typography';
import { Theme } from '../../constants/theme';
import { AdvisorModal } from './AdvisorModal';
import { usePaginationQuery } from '../../components/Table/usePaginationQuery';
import { Order, OrderBy } from '../../types/global';
import { ChevronDown } from '../../assets/icons/ChevronDown';
import { useQuery } from 'react-query';
import { ApiService, CustomError } from '../../services/api';
import { Button } from '../../components/Button';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
import csvDownload from 'json-to-csv-export';
import { useIsAdmin } from '../../hooks/useGetUserInfo';

const defaultOrderBy: OrderBy = {
  messages: Order.desc,
};

const header: Header[] = [
  { label: '#' },
  { label: 'NAME', orderByValue: 'name' },
  { label: 'EMAIL', orderByValue: 'email' },
  { label: 'ACCOUNT CREATED', orderByValue: 'account' },
  { label: 'ADVISOR', orderByValue: 'advisor' },
  { label: 'STATUS', orderByValue: 'status' },
  { label: 'MESSAGES', orderByValue: 'messages' },
  { label: 'SALESFORCE' },
  { label: 'ACTION' },
];

const orderMappings: { [k: string]: (o: Order) => string[] } = {
  name: (order: Order) => [`customer-firstName_${order}`],
  email: (order: Order) => [`customer-email_${order}`],
  account: (order: Order) => [`account-createdAt_${order}`],
  advisor: (order: Order) => [`advisor-firstName_${order}`],
  status: (order: Order) => [`account-closedAt_${order}`],
  messages: (order: Order) => [
    `account-advisorUnreadTotal_${order}`,
    `account-messagesTotal_${order}`,
    `account-lastMessageDate_${order}`,
  ],
};

const mapOrderBy = (orderBy: OrderBy): customersOrderBy[] => {
  return Object.entries(orderBy).reduce<string[]>((acc, [value, order]) => {
    const orders = orderMappings[value](order);
    return [...acc, ...orders];
  }, []) as customersOrderBy[];
};

export const CustomersPage = () => {
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState<OrderBy>(defaultOrderBy);
  const [allocateAccountUid, setAllocateAccountUid] = useState<string | null>(null);
  const isAdmin = useIsAdmin();

  const closeModal = () => {
    setAllocateAccountUid(null);
  };

  const openAllocateAdvisorModal = (uid: string) => {
    setAllocateAccountUid(uid);
  };

  const {
    activePage,
    rowsPerPage,
    data,
    handleChangePage,
    handleChangeRowsPerPage,
    isLoading: isLoadingAccounts,
    isFetching,
    error,
    forceRefetch,
  } = usePaginationQuery<CustomerList, CustomerQueryParams>({
    path: '/v1/customer',
    cacheKey: 'customers',
    holdOldDataOnForceRefetch: true,
    queryParams: {
      search,
      orderBy: mapOrderBy(orderBy),
    },
  });

  const { isLoading: isLoadingDownload, refetch } = useQuery<CustomerQueryParams, CustomError, CustomerList>(
    'customers-download',
    () =>
      ApiService.get('/v1/customer')({
        search,
        orderBy: mapOrderBy(orderBy),
        offset: 0,
        limit: 10000,
        allowBlocked: true,
      }),
    {
      enabled: false,
      onSuccess: (data) => {
        const list = data.list;
        const formatedData = list.map((d) => {
          return {
            NAME: `${d.customer.firstName} ${d.customer.lastName}`,
            EMAIL: `${d.customer.email}`,
            'ACCOUNT CREATED': `${moment(d.customer.createdAt).format('DD-MM-YY')}`,
            ADVISOR: d.advisor ? `${d.advisor.firstName} ${d.advisor.lastName}` : 'Unallocated',
            STATUS: d.account.blockedAt ? 'Blocked' : d.account.closedAt ? 'Inactive' : 'Active',
            SALESFORCE: d.account.salesforceURL ? `${d.account.salesforceURL}` : '',
            'MESSAGES TOTAL': `${d.account.messagesTotal}`,
            'UNREAD MESSAGES': `${d.account.advisorUnreadTotal}`,
            'LAST MESSAGE DATE': d.account.lastMessageDate
              ? `${moment(d.account.lastMessageDate).format('hh:mm DD-MM-YY')}`
              : '',
          };
        });

        csvDownload(formatedData, `CUSTOMERS Statistics ${new Date().toISOString()}.csv`);
      },
    }
  );

  const handleDownloadClick = () => {
    refetch();
  };

  useEffect(() => {
    forceRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy]);

  const handleChangeOrderBy = (value: string | number) => {
    const exists = orderBy[value];
    if (!exists || exists === Order.desc) {
      return setOrderBy({ [value]: Order.asc });
    }
    if (exists === Order.asc) {
      return setOrderBy({ [value]: Order.desc });
    }
  };

  const debounceRefetch = useRef(debounce(forceRefetch, 1000)).current;

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    debounceRefetch();
  };

  const renderTable = () => {
    if (error || !data) {
      return <div style={{ flex: 1 }}>Error!!!</div>;
    }
    return (
      <CustomersTable
        header={header}
        orderBy={orderBy}
        handleChangeOrderBy={handleChangeOrderBy}
        data={data}
        isFetching={isFetching || isLoadingAccounts}
        rowsPerPage={rowsPerPage}
        page={activePage + 1}
        handlePageChange={handleChangePage}
        allocateAdvisor={openAllocateAdvisorModal}
      />
    );
  };

  const getSelectedCustomer = () => {
    if (!allocateAccountUid) {
      return;
    }
    if (!data) {
      return;
    }
    const selected = data.list.find((i) => i.account.uid === allocateAccountUid);
    if (!selected) {
      return;
    }
    return selected.customer;
  };

  return (
    <PageContainer alignCenter column>
      <div style={{ border: `24px solid ${Theme.colors.grey3}`, width: '1260px', margin: '0 auto' }}>
        <PaperFlex column alignCenter>
          <PageHeader title="Customers">
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '12px' }}>
              {isAdmin && (
                <Button loading={isLoadingDownload} onClick={handleDownloadClick} style={{ height: '34px' }}>
                  {'Download'}
                </Button>
              )}
              {/* <TextButton color="darkBlue" type="smallBold">
                Add new customer
                </TextButton> */}
              <div style={{ margin: '0 24px 0 0' }} />
              <Typography type="smallNormal" color="darkerBlue" style={{ padding: '5px 5px' }}>
                Users per page:
              </Typography>
              <SelectDropdown
                color="darkerBlue"
                type="smallBold"
                value={rowsPerPage}
                items={[10, 15, 20, 25]}
                onChange={handleChangeRowsPerPage}
                IconComponent={ChevronDown}
              />
              <div style={{ margin: '0 24px 0 0' }} />
              <TextInput
                placeholder="Search for name or email"
                value={search}
                onChange={handleSearch}
                style={{ width: '285px' }}
                endAdornment={<Search style={{ fontSize: '24px' }} />}
              />
            </div>
          </PageHeader>

          {isLoadingAccounts ? (
            <div style={{ display: 'flex', width: '100%', height: '565px' }}>
              {/* TODO: fix style for spinner */}
              <Loading />
            </div>
          ) : (
            renderTable()
          )}
        </PaperFlex>
      </div>
      <AdvisorModal
        accountUid={allocateAccountUid}
        customer={getSelectedCustomer()}
        closeModal={closeModal}
        activeCustomersPage={activePage}
        cacheKey={rowsPerPage}
      ></AdvisorModal>
    </PageContainer>
  );
};
