import { withStyles, Switch as MUISwitch, Grid } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { Typography } from './Typography';

export const SwitchButton = withStyles((theme) => ({
  root: {
    width: 60,
    height: 30,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    paddingRight: 3,
    left: '5px',
    '&$checked': {
      transform: 'translateX(37px)',
      color: theme.palette.common.white,
      left: '-5px',
      '& + $track': {
        backgroundColor: '#BDBDBD',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#BDBDBD',
      border: 'none',
    },
  },
  thumb: {
    width: 20,
    height: 20,
    marginTop: 3,
  },
  track: {
    borderRadius: '100px',
    border: `none`,
    backgroundColor: '#00446D',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, value, ...props }: any) => {
  return (
    <MUISwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      checked={value}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const FormSwitch = ({ labelOn, labelOff, ...props }: any) => {
  const [field] = useField(props);

  return (
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>
        <Typography type="smallNormal" color="black">
          {labelOn}
        </Typography>
      </Grid>
      <Grid item>
        <SwitchButton {...field} />
      </Grid>
      <Grid item>
        <Typography type="smallNormal" color="black">
          {labelOff}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const SingleSwitch = ({ labelOn, labelOff, disabled, ...props }: any) => {
  return (
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>
        <Typography type="smallNormal" color={disabled ? 'grey1' : 'black'}>
          {labelOn}
        </Typography>
      </Grid>
      <Grid item>
        <SwitchButton {...props} disabled={disabled} />
      </Grid>
      <Grid item>
        <Typography type="smallNormal" color={disabled ? 'grey1' : 'black'}>
          {labelOff}
        </Typography>
      </Grid>
    </Grid>
  );
};
