export interface Pagination {
  limit: number;
  offset: number;
  total: number;
}

export interface ListResponse<T extends any> {
  pagination: Pagination;
  list: T[];
}

export enum Order {
  asc = 'asc',
  desc = 'desc',
}
export interface OrderBy {
  [k: string]: Order;
}

export enum SocketAction {
  NEW_MESSAGE = 'newMessage',
  SEND_MESSAGE = 'sendMessage',
  READ_MESSAGE = 'readMessage',
  EDIT_MESSAGE = 'editMessage',
  EDITED_MESSAGE = 'editedMessage',
  DELETE_MESSAGE = 'deleteMessage',
  DELETED_MESSAGE = 'deletedMessage',
}

export interface SocketData {
  action: SocketAction;
  data: any;
}
