import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserInterface } from '../common/interfaces/user.interface';

let _navigator: any;

function setTopLevelNavigator(navigatorRef: any) {
  _navigator = navigatorRef;
}

function navigate(route: string) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  _navigator.history.push(route);
}

function generateRoutesMain(routing: Array<any>, rootPath?: string, protect?: boolean, user?: UserInterface): any {
  const routes: Array<any> = [];

  // let rootCmp = null;

  routing.forEach((route) => {
    const path = [''];
    if (rootPath) path.push(rootPath);
    path.push(route.path);

    routes.push({
      path: path.join('/'),
      component: route.component,
    });
    // if (route.isRoot) rootCmp = route.component;
  });

  // routes.push({
  //   path: '',
  //   component: rootCmp,
  // });

  if (protect && !user) return <Redirect to={'/auth/login'} />;

  return routes.map((r, i) => <Route path={r.path} component={r.component} key={i} />);
}

export default {
  navigate,
  setTopLevelNavigator,
  generateRoutesMain,
};
