//@ts-nocheck
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import SimpleAlerts from '../../../components/Alert';
import OnboardingStep1 from '../onboardingComponents/OnboardingStep1';
import Progress from '../Stepper';
import OnboardingStep2 from '../onboardingComponents/OnboardingStep2';
import OnboardingStep3 from '../onboardingComponents/OnboardingStep3';
import OnboardingStep4 from '../onboardingComponents/OnboardingStep4';
import OnboardingStep5 from '../onboardingComponents/OnboardingStep5';
import OnboardingStep6 from '../onboardingComponents/OnboardingStep6';
import OnboardingHeader from '../onboardingComponents/OnboardingHeader';
import { useMutation } from 'react-query';
import { ApiService } from '../../../services/api';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

function OnboardingLayout() {
  const history = useHistory();

  const { mutate, isLoading, error } = useMutation('sign-up', ApiService.post('/v1/register'), {
    onSuccess: () => {
      return history.push('/auth/congrats');
    },
    onError: () => {
      setAlertState(true);
    },
  });

  //recaptcha
  const onChange = (value) => {
    console.log("Captcha value:", value);
  }

  //step to track which onboarding step it is
  const [step, setStep] = useState(1);

  // checkboxes for describing situation
  const [situation, setSituation] = useState({
    Checks: [
      {
        name: 'Situation',
        options: [
          { value: 'Disabled', name: "I'm disabled" },
          { value: 'Expecting baby', name: 'I’m expecting a baby' },
          { value: 'Caring for someone', name: 'I’m caring for someone' },
          { value: 'Low income', name: 'I have a low income' },
          { value: 'Disabled child', name: 'I have a disabled child' },
          { value: 'Unwell', name: 'I’m unwell' },
          { value: 'Pension age', name: 'I’m at pension age' },
          { value: 'Single parent', name: 'I’m a single parent' },
          { value: 'Partner passed away', name: 'My partner passed away' },
          { value: 'Working', name: "I'm working" },
        ],
      },
    ],
    selected: [],
  });

  const [addSituation, setAddSituation] = useState('');

  const handleSituationCheck = (value: any) => {
    setSituation((situation) => {
      const updated = situation.selected.find((obj: any) => obj === value);
      const selected = updated
        ? situation.selected.filter((obj: any) => obj !== value)
        : [...situation.selected, value];

      return {
        selected,
        Checks: [
          ...situation.Checks.map((filter: any) => {
            return {
              ...filter,
              options: filter.options.map((ele: any) => {
                return {
                  ...ele,
                  checked: selected.includes(ele.value),
                };
              }),
            };
          }),
        ],
      };
    });
  };

  const handleAddSituation = (value: any) => {
    situation.Checks[0].options.push({ name: value, value: value });
    setTimeout(() => setAddSituation(situation), 300);
  };

  // concerns
  const [concerns, setConcerns] = useState({
    Checks: [
      {
        name: 'Situation',
        options: [
          { value: 'Paying rent', name: 'Paying rent' },
          { value: 'Paying Council Tax', name: 'Paying Council Tax' },
          { value: 'Paying debts', name: 'Paying debts' },
          { value: 'My energy bill', name: 'My energy bill' },
          { value: 'My credit score', name: 'My credit score' },
          { value: 'My water bill', name: 'My water bill' },
          { value: 'Education or studies', name: 'Education or studies' },
          { value: 'My benefits', name: 'My benefits' },
          { value: 'Borrowing money', name: 'Borrowing money' },
          { value: 'Paying a mortgage', name: 'Paying a mortgage' },
        ],
      },
    ],
    selected: [],
  });

  const handleConcerns = (value: any) => {
    setConcerns((concerns: any) => {
      const updated = concerns.selected.find((obj: any) => obj === value);
      const selected = updated ? concerns.selected.filter((obj: any) => obj !== value) : [...concerns.selected, value];

      return {
        selected,
        Checks: [
          ...concerns.Checks.map((filter: any) => {
            return {
              ...filter,
              options: filter.options.map((ele: any) => {
                return {
                  ...ele,
                  checked: selected.includes(ele.value),
                };
              }),
            };
          }),
        ],
      };
    });
  };

  const handleAddConcerns = (value: any) => {
    concerns.Checks[0].options.push({ name: value, value: value });
    setTimeout(() => setAddSituation(concerns), 300);
  };
  // select energy and water supplier
  const [electricity, setElectricity] = useState('');
  const [electricityMan, setManElectricity] = useState('');
  const [gas, setgas] = useState('');
  const [gasManual, setManualGas] = useState('');
  const handleChangeElectricity = (event: any) => {
    setElectricity(event.target.value);
  };
  const handleChangeElectricityManual = (event: any) => {
    setManElectricity(event.target.value);
  };

  const handleChangeGas = (event: any) => {
    setgas(event.target.value);
  };
  const handleChangeGasMan = (event: any) => {
    setManualGas(event.target.value);
  };
  //onbaording step 1

  //disable next step button
  const [disable, setDisabled] = useState(true);
  const handleDisable = () => {
    if (date.day === 0 || date.month === 0 || date.year === 0) {
      setDisabled(true);
    } else setDisabled(false);
  };

  const [disableAddressBtn, setDisableAddressBtn] = useState(true);
  const handleDisableAddressBtn = () => {
    console.log("status: " + status)
    if (address === undefined || !(typeof status === 'string' || status instanceof String)) {
      setDisableAddressBtn(true);
    } else setDisableAddressBtn(false);
  };

  // textfields
  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    dob: '',
    email: '',
    phoneNumber: '',
    password: '',
    termsOptIn: false,
    privacyOptIn: false
  });

  ///onboarding step 2

  const [address, setAddress] = useState();
  const handleAddressChange = (event: any) => {
    setAddress(event.target.value);
  };

  const [status, setStatus] = useState({
    tenant: '',
    owner: '',
  });

  const handleStatus = (event: any) => {
    setStatus(event.target.value);
  };

  const [date, setDate] = useState({ day: 0, year: 0, month: 0 });

  const [progress, setProgress] = useState(0);
  //alert state
  const [alertState, setAlertState] = useState(false);

  const handleDate = (event: any) => {
    setDate({ ...date, [event.target.name]: event.target.value });
  };

  // every next/previous button except last submit button get us on next step or previous step
  const onAction = (event: any) => {
    setStep(step + 1);
  };

  const onActionBack = () => {
    setStep(step - 1);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const countProgress = () => {
    switch (step) {
      case 1:
        return setProgress(16.6);
      case 2:
        return setProgress(33.3);
      case 3:
        return setProgress(50);
      case 4:
        return setProgress(66.6);
      case 5:
        return setProgress(83.3);
      case 6:
        return setProgress(100);
      default:
        return setProgress(0);
    }
  };

  const closeAlert = () => {
    setAlertState(false);
  };

  useEffect(() => {
    handleDisable();
    handleDisableAddressBtn();
    countProgress();
  }, [
    step,
    date.day,
    date.month,
    date.year,
    address,
    alertState,
    handleDisable,
    handleDisableAddressBtn,
    countProgress,
    data,
  ]);

  // submit form with data

  const onSubmit = (values: any) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    console.log("rc: " + recaptchaValue);
    const d = date.day;
    const m = date.month;
    const y = date.year;
    const newD = `${y}-${m}-${d}`.split('-');
    const birthDate = new Date(newD[0], newD[1] - 1, newD[2]);
    const submitData = {
      email: values.email,
      password: values.password,
      phone: values.phoneNumber,
      firstName: data.firstname,
      lastName: data.lastname,
      birthDate: birthDate,
      street: address?.street,
      city: address?.posttown,
      postalCode: address?.postcode,
      county: address?.county,
      country: 'UK',
      placeStatus: status ? status : 'OWNER',
      situation: situation.selected,
      worryAbout: concerns.selected,
      electricitySupplier: !electricity ? electricityMan : electricity,
      gasSupplier: !gas ? gasManual : gas
    };
    mutate({ data: submitData });
  };

  const errorMsg = error ? error.message : '';

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      {step >= 1 && step <= 7 && <Progress progress={progress} />}
      <OnboardingHeader step={step} actionBack={onActionBack} cancel={() => history.push('/')} />

      <SimpleAlerts openAlert={alertState} closeAlert={closeAlert} message={errorMsg} />

      <OnboardingStep1
        formData={data}
        setFormData={setData}
        step={step}
        day={date.day}
        month={date.month}
        year={date.year}
        onAction={onAction}
        handleChange={handleDate}
        disabled={disable}
      />

      <OnboardingStep2
        step={step}
        onAction={onAction}
        identifier="react-address-lookup"
        apiKey="PCW45-12345-12345-1234X"
        country="UK"
        status={status}
        address={address}
        handleAddressChange={handleAddressChange}
        handleStatus={handleStatus}
        disabled={disableAddressBtn}
      />

      <OnboardingStep3
        step={step}
        onAction={onAction}
        checkValues={situation}
        handleChanges={handleSituationCheck}
        addMore={handleAddSituation}
      // checked={situation}
      />

      <OnboardingStep4
        step={step}
        onAction={onAction}
        financialPressure={concerns}
        addMore={handleAddConcerns}
        handleChange={handleConcerns}
      />
      <OnboardingStep5
        step={step}
        onAction={onAction}
        electricity={electricity}
        electricityNew={electricityMan}
        gas={gas}
        gasNew={gasManual}
        handleChangeGas={handleChangeGas}
        handleChangeGasManual={handleChangeGasMan}
        handleChangeElectricity={handleChangeElectricity}
        handleChangeElectricityManual={handleChangeElectricityManual}
      />
      <OnboardingStep6 formData={data} setFormData={setData} step={step} onboarding={isLoading} submit={onSubmit} />

      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LcAOWUeAAAAAM-5XKkKGXw6rGyGs-Q5QcLIoVqr"
        onChange={onChange}
      />
    </div>
  );
}

export default OnboardingLayout;
