import React from 'react';

function ChevronDownBase(props: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M6.115 7.998l-1.41 1.41L12 16.703l7.295-7.295-1.41-1.41L12 13.873 6.115 7.998z" fill="currentColor" />
    </svg>
  );
}

export const ChevronDown = React.memo(ChevronDownBase);
