import React, { FunctionComponent } from 'react';
import { useMutation } from 'react-query';
import { queryClient } from '../../../App';
import { useIsMobileView } from '../../../components/MobileView';
import { SingleSwitch } from '../../../components/SwitchButton';
import { Typography } from '../../../components/Typography';
import { ApiService, CustomError } from '../../../services/api';
import { NotificationPreference } from '../../../types/notificationPreferences';
import { User } from '../../../types/user';
import { PageContentBlock, Section, SectionHeader } from '../../Customer/PageStyleComponents';

interface NotificationSettingsProps {
  user: User;
}

export const NotificationSettings: FunctionComponent<NotificationSettingsProps> = ({ user }) => {
  const { mutate } = useMutation<NotificationPreference, CustomError, { data: Partial<NotificationPreference> }>(
    ApiService.patch(`/v1/notification/${user.notificationPreference?.uid}`),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<User | undefined>('userInfo', (oldData) =>
          oldData ? { ...oldData, notificationPreference: data } : undefined
        );
      },
    }
  );

  const handleChange = (e: any) => {
    const { name, checked } = e.target;
    let value: string | boolean = !checked;
    if (name === 'type') {
      value = value ? 'INSTANTLY' : 'DAILY';
    }
    const input = { [name]: value };
    mutate({ data: input });
  };
  const isMobile = useIsMobileView();
  return (
    <PageContentBlock fullWidth bRadius style={isMobile ? { marginTop: '16px' } : { margin: '12px' }}>
      <SectionHeader>
        <Typography type="b2Bold" color="darkerBlue">
          Notification Settings
        </Typography>
      </SectionHeader>
      <Section>
        <Typography type="b3Normal" color="darkerBlue">
          We will notify you by email when you have a message waiting.
        </Typography>
      </Section>
      <Section>
        <Typography type="b3Bold" color="darkerBlue">
          Allow notifications
        </Typography>
        <SingleSwitch
          labelOn="Yes"
          labelOff="No"
          value={!user.notificationPreference?.allow}
          name="allow"
          onChange={handleChange}
        />
      </Section>
      <Section>
        <Typography type="b3Bold" color="darkerBlue">
          I want to receive notifications:
        </Typography>
        <Typography type="b3Normal" color="darkerBlue">
          Choose to receive notifications when your get them ‘instantly’ or get them as a ‘daily’ round up.
        </Typography>
        <SingleSwitch
          labelOn="Instantly"
          labelOff="Daily"
          value={user.notificationPreference?.type === 'DAILY'}
          name="type"
          onChange={handleChange}
        />
      </Section>
      <Section>
        <Typography type="b3Bold" color="darkerBlue">
          Allow contact by phone:
        </Typography>
        <Typography type="b3Normal" color="darkerBlue">
          Allow us to contact you by phone, if we don’t hear back within 3 days
        </Typography>
        <SingleSwitch
          labelOn="Yes"
          labelOff="No"
          value={!user.notificationPreference?.phone}
          name="phone"
          onChange={handleChange}
        />
      </Section>
    </PageContentBlock>
  );
};
