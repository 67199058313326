import React from 'react';
import { Button } from '@material-ui/core';
import { ArrowBack,  Close } from '@material-ui/icons';

interface Props {
    step: number;
    actionBack: any;
    cancel: any;
}

const OnboardingHeader: React.FC<Props> = ({step, actionBack, cancel}) => {

    return (
        <div className="onboarding-header">
            <Button  onClick={actionBack} disabled={step <= 1 || step > 6 ? true : false }> 
                <ArrowBack />
            </Button>
            <p>{step} of 6</p>
            <Button onClick={cancel}>
                <Close />
            </Button>
        </div>
    )
}

export default OnboardingHeader;